import { createTranslation } from 'utils/translation.utils';
import { createLocalSelector, LocalSelector } from 'utils/store.utils';
import { createPDFProductItem } from 'utils/pdf/variables/product';
import { typed } from 'utils/global.utils';
import { createError } from 'utils/error.utils';
import { getDeliverySummary } from 'utils/delivery.utils';
import { createCartTotalAccumulator, calcTotalTaxes, groupCartProducts } from 'utils/cart.utils';
import { getDeliveryAddress, getProductTruckNumber } from 'utils';
import { IPointListItem } from 'types/shippingFees';
import { ProductReplacement } from 'types/product';
import {
  PDFVariables,
  PDFVariablesGroup,
  PDFVariablesGroupedCartProduct,
  PDFProductItem,
  PDFLegend,
  PDFOrder,
} from 'types/pdf';
import { IOrder, IOrderItem } from 'types/order';
import { ICustomer, ICustomerGroup, INormalizedList, INormalizedStringList, IShippingAddresses } from 'types';
import { RootState } from 'store/rootReducer';
import { ICategorizedShippingItems } from 'store/delivery/types';
import { selectShippingCategoriesById } from 'store/delivery';
import { INormalizedCustomersList, INormalizedGroupCustomersList } from 'store/customers/types';
import { ICartProductItem } from 'store/cart/types';
import { selectTruckCustomerInfo } from 'store/cart';
import moment from 'moment';
import env from 'config';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';

const trans = createTranslation('ExportPDF');

const isValidCart = (products: Array<ICartProductItem>) => {
  return products.length !== 0;
};

export const selectCustomer = (
  customers: INormalizedCustomersList,
  selectedCustomer: number | undefined,
): ICustomer => {
  if (!selectedCustomer) {
    throw createError(trans('No customer have been selected.'));
  }

  if (!Object.prototype.hasOwnProperty.call(customers, selectedCustomer)) {
    throw new Error('There must be a selected customer.');
  }

  return customers[selectedCustomer];
};

const selectGroupCustomers = (selectedGroupCustomers: INormalizedGroupCustomersList): PDFVariablesGroup => {
  const groupCustomers = Object.values(selectedGroupCustomers);
  const leader = groupCustomers.find((customer) => customer.leaderId === customer.userId);

  if (!leader) {
    throw new Error('The group must have a leader.');
  }

  return {
    customers: groupCustomers,
    leader,
  };
};

type PDFProductAssoc = {
  pdfProduct: PDFProductItem;
  product: ICartProductItem;
  orderItem: IOrderItem | undefined;
};

export const createExportPDFVariables = (state: RootState): PDFVariables => {
  const localSelector = createLocalSelector(state);

  const {
    cart,
    customers: { isGroup, customers, selectedCustomer, selectedGroupCustomers },
    delivery: { shippingAddress, shippingCategories, categoryPointList },
  } = state;

  const { comment, orders: ordersNormalized } = cart;
  const orders = Object.values(ordersNormalized);
  const orderItems = orders.flatMap(({ orderItems: item }) => item);

  const products = cart.products.filter((prod) => prod.enabled);

  if (!isValidCart(products)) {
    throw createError(trans('You must have at least one product in cart in order to export it as PDF.'));
  }

  let customer: ICustomer | ICustomerGroup | null = !isGroup ? selectCustomer(customers, selectedCustomer) : null;
  const group = isGroup ? selectGroupCustomers(selectedGroupCustomers) : null;

  if (group) {
    customer = group.leader;
  }

  const cartTotal = createCartTotalAccumulator(products);

  const totalTaxes = calcTotalTaxes(cartTotal);

  const summary = getDeliverySummary({ shippingCategories, products });
  const { totalProductsPrice, totalProductsPriceWithTaxes } = summary;

  const totalWithoutTaxes = totalProductsPrice;
  const totalWithTaxes = totalProductsPriceWithTaxes;
  const totalShippingFees = summary.totalShippingFees;
  const totalWithoutTaxesAndShippingFees = summary.totalProductsPriceWithFees;
  const totalWithTaxesAndShippingFees = summary.totalProductsPriceWithFeesAndTaxes;

  const pdfProductsAssoc: Array<PDFProductAssoc> = products.map((product) => {
    const orderItem = orderItems.find((item) => parseInt(item.offerId) === parseInt(String(product.offerId)));
    const shippingCategory = localSelector(selectShippingCategoriesById(parseInt(orderItem?.shippingMethodId || '-1')));

    if (!shippingCategory && !product.noStock && product.enabled && !product.isPlainProduct) {
      throw new Error(
        'No shipping category was found for product : ' +
          product.productId +
          ' and shipping method id : ' +
          parseInt(orderItem?.shippingMethodId || '-1'),
      );
    }

    const pdfProduct = createPDFProductItem(typed(product, 'ICartProductItem' as const), {
      orderItem,
      shippingCategory,
    });

    return {
      product,
      pdfProduct,
      orderItem,
    };
  });

  const pdfProducts = pdfProductsAssoc.map(({ pdfProduct }) => pdfProduct);

  const pdfOrders = getPDFOrders({
    localSelector,
    customer: customer as NonNullable<typeof customer>,
    group,
    shippingCategories,
    categoryPointList,
    cartOrders: cart.orders,
    pdfProductsAssoc,
    shippingAddress,
  });

  const withStock = groupCartProducts<PDFProductItem>(pdfProducts, cart.groups);

  return {
    env: {
      enablePdfExcludeRpd: env('enablePdfExcludeRpd'),
    },
    isGroup,
    customer: customer as NonNullable<typeof customer>,
    group,
    shippingAddress: shippingAddress || null,
    cart: {
      atcCartNumber: cart.atcCartNumber,
      currency: 'EUR',
      comment,
      categories: formatCartProducts(withStock),
      orders: pdfOrders,
      summary: {
        total: {
          withoutTaxes: totalWithoutTaxes,
          withTaxes: totalWithTaxes,
          shippingFees: totalShippingFees,
          withoutTaxesAndShippingFees: totalWithoutTaxesAndShippingFees,
          withTaxesAndShippingFees: totalWithTaxesAndShippingFees,
          taxes: totalTaxes,
        },
        legend: getLegend(withStock),
      },
    },
  };
};

const getDeliveryShippingItems = (
  shippingMethodId: number,
  shippingCategories: INormalizedList<ICategorizedShippingItems>,
  categoryPointList: INormalizedList<string>,
): IPointListItem | undefined => {
  const pointListId = categoryPointList[shippingMethodId];
  if (shippingCategories[shippingMethodId]) {
    const { pointList } = shippingCategories[shippingMethodId];

    if (pointListId && pointList?.length) {
      return pointList.find((pointListItem) => pointListItem.id === pointListId);
    }
  }

  return undefined;
};

type MappedPDFOrderItem = {
  product: ICartProductItem;
  pdfProduct: PDFProductItem;
  orderItem: IOrderItem;
};

const getProductsOrderItems = (
  orderItems: Array<IOrderItem>,
  pdfProductsAssoc: Array<PDFProductAssoc>,
): Array<MappedPDFOrderItem> => {
  return orderItems.reduce<Array<MappedPDFOrderItem>>((acc, orderItem) => {
    const findPDFProductAssoc = pdfProductsAssoc.find(
      ({ pdfProduct }) => parseInt(String(pdfProduct.offerId)) === parseInt(orderItem.offerId),
    );
    if (findPDFProductAssoc) {
      acc.push({
        product: findPDFProductAssoc.product,
        pdfProduct: findPDFProductAssoc.pdfProduct,
        orderItem,
      });
    }
    return acc;
  }, []);
};

type GetPDFOrderGroupProductOverrideProps = {
  localSelector: LocalSelector;
  pdfProduct: PDFProductItem;
  orderItem: IOrderItem;
  order: IOrder;
};

const getPDFOrderGroupProductOverride = ({
  localSelector,
  pdfProduct,
  orderItem,
  order,
}: GetPDFOrderGroupProductOverrideProps): Partial<PDFProductItem> => {
  const { customerId } = order;
  const { conditioningQuantity, offerId, quantity, quantityIncrement, unit, initialUnitPrice } = pdfProduct;

  const truckNumber = getProductTruckNumber(orderItem.offerId);
  const productInfo = localSelector(selectTruckCustomerInfo(Number(truckNumber), customerId, offerId));

  const getProductGroupQuantity = () => {
    if (!truckNumber && conditioningQuantity && productInfo?.selectedQuantity) {
      return productInfo?.selectedQuantity / conditioningQuantity;
    }
    return 0;
  };

  const getProductVolume = () => {
    if (productInfo) {
      return productInfo.selectedQuantity;
    }
    return 0;
  };

  const truckVolume = productInfo && conditioningQuantity ? productInfo.selectedQuantity * conditioningQuantity : 0;
  const productVolume = getProductVolume();
  const productGroupQuantity = getProductGroupQuantity();

  const getTruckVolume = () => {
    if (truckNumber) {
      return truckVolume;
    }
    return productVolume;
  };

  const getVolumeText = () => {
    if (quantityIncrement * quantity >= 2) {
      return unit.plural;
    }
    return unit.singular;
  };

  const getPrice = () => {
    if (truckNumber) {
      return initialUnitPrice * Number(truckVolume);
    }
    return initialUnitPrice * Number(productVolume);
  };

  const volume = getTruckVolume();
  const volumeText = getVolumeText();
  const totalWithoutTax = getPrice();

  return {
    quantity: !truckNumber ? productGroupQuantity : 1,
    volume,
    volumeText,
    totalWithoutTax,
  };
};

type GetPDFOrdersProps = {
  localSelector: LocalSelector;
  customer: ICustomer | ICustomerGroup;
  group: PDFVariablesGroup | null;
  shippingCategories: INormalizedList<ICategorizedShippingItems>;
  categoryPointList: INormalizedList<string>;
  cartOrders: INormalizedStringList<IOrder>;
  pdfProductsAssoc: Array<PDFProductAssoc>;
  shippingAddress: IShippingAddresses | undefined;
};

const getPDFOrders = ({
  localSelector,
  customer,
  group,
  shippingCategories,
  categoryPointList,
  cartOrders,
  pdfProductsAssoc,
  shippingAddress,
}: GetPDFOrdersProps) => {
  return Object.entries(cartOrders).reduce<INormalizedStringList<PDFOrder>>((accumulator, [orderId, order]) => {
    const { shippingMethodId, orderItems } = order;

    const orderCustomer: ICustomer | ICustomerGroup =
      group?.customers.find((item) => item.customerIdentifier === order.customerId) || customer;

    // TODO: refactor this, the order item is already associated
    const productsOrderItems = getProductsOrderItems(orderItems, pdfProductsAssoc).map(
      (pdfProductsAssocItem): PDFProductItem => {
        const { pdfProduct: product, orderItem } = pdfProductsAssocItem;
        const { offerId } = product;

        const findQuantityDisplayed = orderItems.find((item) => Number(item.offerId) === offerId);

        const totalWithoutTax =
          findQuantityDisplayed?.quantity === product.quantity
            ? product.totalWithoutTax
            : product.quantityIncrement * product.unitPrice;

        const quantity = findQuantityDisplayed ? findQuantityDisplayed?.quantity : product.quantity;

        const pdfProduct: PDFProductItem = {
          ...product,
          quantity,
          totalWithoutTax,
        };

        let override: Partial<PDFProductItem> = {};

        if (group) {
          override = getPDFOrderGroupProductOverride({ pdfProduct, orderItem, localSelector, order });
        }

        return {
          ...pdfProduct,
          ...override,
        };
      },
    );

    const groupedProducts = groupCartProducts(productsOrderItems);
    const categories = formatCartProducts(groupedProducts);

    const pointListItem = getDeliveryShippingItems(Number(shippingMethodId), shippingCategories, categoryPointList);
    const deliveryAddress = getDeliveryAddress(pointListItem, shippingAddress);

    const pdfOrder: PDFOrder = {
      ...order,
      customer: orderCustomer,
      categories,
      pointListItem,
      deliveryAddress,
    };

    accumulator[orderId] = pdfOrder;

    return accumulator;
  }, {});
};

const formatCartProducts = (
  pdfProducts: INormalizedStringList<Array<PDFProductItem>>,
): Array<PDFVariablesGroupedCartProduct> => {
  const categories = Object.entries(pdfProducts).reduce<Array<PDFVariablesGroupedCartProduct>>((acc, [bu, prod]) => {
    return [
      ...acc,
      {
        bu,
        products: prod,
      },
    ];
  }, []);

  categories.forEach(({ products }) => {
    let position = 0;
    products.forEach((product) => {
      product.position = ++position;
    });
  });

  return categories;
};

const getLegend = (withStock: INormalizedStringList<Array<PDFProductItem>>): PDFLegend => {
  const legendReducer = createLegendReducer();
  return legendReducer(withStock);
};

const createLegendItem = (product: PDFProductItem) => {
  const { name, inStock, replacement } = product;
  return {
    name,
    inStock,
    replacement: {
      name: replacement?.name || '',
    },
  };
};

const createLegendReducer = () => {
  const target: PDFLegend = {
    equivalent: [],
    substitute: [],
  };

  return (normalizedProducts: INormalizedStringList<Array<PDFProductItem>>) => {
    return Object.values(normalizedProducts).reduce<PDFLegend>((acc, prods) => {
      prods.forEach((product) => {
        if (!product.replacement) {
          return;
        }
        const item = createLegendItem(product);
        if (product.replacement.type === ProductReplacement.EQUIVALENT && product.inStock) {
          if (!acc.equivalent.find((current) => current.name === item.name)) {
            acc.equivalent.push(item);
          }
        } else if (product.replacement.type === ProductReplacement.SUBSTITUTE && product.inStock) {
          if (!acc.substitute.find((current) => current.name === item.name)) {
            acc.substitute.push(item);
          }
        }
      });
      return acc;
    }, target);
  };
};

export type IPDFProps = {
  cartId: string;
  pdfId: number;
  customer: string;
  createdAt: string;
  body?: string;
};

export const downloadPdf = (props: IPDFProps): void => {
  const { cartId, customer, createdAt, body } = props;
  const blob = URL.createObjectURL(body);
  const link = document.createElement('a');
  const createdDate = moment(createdAt).format(CUSTOM_DATE_FORMAT.timestamp);
  const customerName = customer.replace(' ', '-');
  link.href = blob;
  link.download = `${createdDate}-${customerName}-${cartId}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
