import { filterColumns, tableWidthBuilder } from 'utils/table.utils';
import { useAppSelector } from 'store/store';
import {
  generateList,
  selectExcludeNoStockOffers,
  selectIsLoading,
  selectMultiSearchQueries,
  selectMultiSearchResults,
} from 'store/products';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useMemo, useState } from 'react';
import { Button } from 'components/shared';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ProductSearch from '../ProductPageHeader/components/ProductSearch';
import { getParsedProductsForTable } from '../../../utils';
import { MultiSearchHeader } from '../../../types/product';
import { IParsedProductItem, ProductSearchType } from '../../../store/products/types';
import styles from './ProductTableWiuz.module.scss';
import {
  MultiSearchQtyColumn,
  ProductDeliveryColumn,
  ProductInfoColumn,
  ProductMultiSearchQuantityColumn,
  ProductNullColumn,
  ProductPriceColumn,
  ProductReferenceColumn,
  ProductStockColumn,
} from './ProductTableColumns';

const ProductTableMulti: FC = () => {
  const isLoading = useSelector(selectIsLoading);
  const excludeNoStockOffers = useSelector(selectExcludeNoStockOffers);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);

  const queries = useAppSelector(selectMultiSearchQueries);
  const searchResults = useAppSelector(selectMultiSearchResults);

  const handleToggleExpandAllRows = useCallback(() => {
    setExpanded((value) => !value);
    const expandKeys = Object.keys(queries).map((key) => Number(key));
    setExpandedKeys(expanded ? [] : expandKeys);
  }, [expanded, queries]);

  const hasSearchResults = useCallback(
    (key: number) => {
      return searchResults[key] && Object.values(searchResults[key].products).length > 0;
    },
    [searchResults],
  );

  const handleToggleExpandRow = useCallback((isExpanded: boolean, key: number) => {
    setExpandedKeys((state) => {
      if (isExpanded) {
        return state.filter((item) => item !== key);
      } else {
        if (!state.includes(key)) {
          return [...state, key];
        }
      }
      return state;
    });
  }, []);

  const { width } = useMemo(
    () =>
      tableWidthBuilder([
        { width: 8, column: 'ProductName' },
        { width: 2, column: 'Reference' },
        { width: 1, column: 'Offline' },
        { width: 2, column: 'Stock' },
        { width: 4, column: 'Delivery' },
        { width: 2, column: 'Price' },
        { width: 3, column: 'Quantity' },
        { width: 1, column: 'Actions', skip: true },
      ]),
    [],
  );

  const tableColumns = filterColumns<
    TableColumnDefinition<MultiSearchHeader> | TableColumnDefinition<unknown> | typeof Table.EXPAND_COLUMN
  >([
    {
      title: 'Product info',
      dataIndex: undefined,
      render: (record: MultiSearchHeader) => {
        const { query } = record;
        return (
          <ProductSearch
            value={query}
            type={ProductSearchType.MULTIPLE_SEARCH}
            preloadSearch={false}
            rowKey={getRowKey(record)}
            onTypeSearch={false}
            onSearchCompleted={() => {
              setExpandedKeys([...expandedKeys, Number(getRowKey(record))]);
            }}
          />
        );
      },
    },
    ProductNullColumn({
      title: t('common.code'),
      align: 'center',
      width: width('Reference'),
      onCell: () => ({ colSpan: 4 }),
    }),
    ProductNullColumn({
      title: t('common.stock'),
      align: 'center',
      width: width('Stock'),
      onCell: () => ({ colSpan: 0 }),
    }),
    ProductNullColumn({
      title: t('common.delivery'),
      align: 'center',
      width: width('Delivery'),
      onCell: () => ({ colSpan: 0 }),
    }),
    ProductNullColumn({
      title: t('common.netPrice'),
      align: 'right',
      width: width('Price'),
      onCell: () => ({ colSpan: 0 }),
    }),
    MultiSearchQtyColumn({
      title: t('common.quantity'),
      width: width('Quantity'),
    }),
    Table.EXPAND_COLUMN,
  ]);

  const getRowKey = useCallback((record: MultiSearchHeader) => queries.indexOf(record), [queries]);
  const getProductsForQuery = (record: MultiSearchHeader) => {
    const { products, offers, searchedVariantsIds } = searchResults[getRowKey(record)];
    const productsList = getParsedProductsForTable({ products, offers });

    return generateList(searchedVariantsIds, productsList, excludeNoStockOffers);
  };

  return (
    <div className={styles.container}>
      <Table
        columns={tableColumns as ColumnsType<MultiSearchHeader>}
        size="small"
        className={styles.container__table}
        rowClassName={styles.row}
        rowKey={getRowKey}
        loading={isLoading}
        pagination={false}
        dataSource={queries}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 0,
          expandedRowKeys: expandedKeys,
          expandedRowClassName: () => styles.expandableRow,
          columnTitle: () => {
            const Icon = expanded ? <UpOutlined /> : <DownOutlined />;
            return (
              <>
                <Button className={styles.expand__button} onClick={handleToggleExpandAllRows} icon={Icon} />
              </>
            );
          },
          expandRowByClick: true,
          expandedRowRender: (record, row) => {
            const childTableColumns = filterColumns([
              ProductInfoColumn({ width: width('ProductName'), title: t('common.productInfo') }),
              ProductReferenceColumn({ width: width('Reference'), title: t('common.code') }),
              ProductStockColumn({ width: width('Stock'), title: t('common.stock') }),
              ProductDeliveryColumn({ width: width('Delivery'), title: t('common.delivery') }),
              ProductPriceColumn({ width: width('Price'), title: t('common.netPrice') }),
              ProductMultiSearchQuantityColumn({ width: width('Quantity'), title: t('common.quantity') }, row),
            ]);

            return (
              <Table
                rowKey={(product, key) => `${row}_${key}`}
                showHeader={false}
                className={styles.container__child_table}
                rowClassName={styles.child_row}
                columns={childTableColumns as ColumnsType<IParsedProductItem>}
                pagination={false}
                dataSource={getProductsForQuery(record)}
              />
            );
          },
          rowExpandable: () => true,

          // eslint-disable-next-line react/prop-types
          expandIcon: ({ expanded: isExpanded, onExpand: _onExpand, record }) => {
            const Icon = isExpanded ? <UpOutlined /> : <DownOutlined />;
            return (
              <Button
                className={styles.expand__button}
                onClick={() => handleToggleExpandRow(isExpanded, getRowKey(record))}
                icon={Icon}
                disabled={!hasSearchResults(getRowKey(record))}
              />
            );
          },
        }}
      />
    </div>
  );
};

export default ProductTableMulti;
