import { useIsSmallTablet } from 'utils/styles.utils';
import { useAppSelector } from 'store/store';
import { ProductSearchType } from 'store/products/types';
import { selectIsGroup } from 'store/customers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useSettings } from 'hooks/useSettings';
import SaveCartButton from 'components/shared/SaveCartButton';
import { Text, BackButton, HideProductsButton } from 'components/shared';
import ProductTab from 'components/product/ProductPageHeader/components/ProductTab';
import ProductFileImportButton, {
  ProductFileImportEnum,
} from 'components/product/ProductPageHeader/components/ProductFileImportButton';
import { Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import CartButton from '../CartButton';
import ClearFileImportButton from '../../shared/ClearFileImportButton';
import { selectCartRequestDetail } from '../../../store/cartRequests';
import { config } from '../../../config';
import { ProductTab as PT } from './components/ProductTab/types';
import ProductSearch from './components/ProductSearch';
import styles from './ProductPageHeader.module.scss';

const ProductPageHeader: FC = () => {
  const isSmallTablet = useIsSmallTablet();
  const isGroup = useAppSelector(selectIsGroup);
  const [productPage] = useSettings('PRODUCT_PAGE');
  const cartRequestDetail = useSelector(selectCartRequestDetail);
  const { t } = useTranslation();

  const getProductPageType = () => {
    switch (productPage) {
      case 'multi':
        return (
          <>
            <Space>
              <ProductFileImportButton type={ProductFileImportEnum.MULTI} />
              <ClearFileImportButton />
            </Space>
          </>
        );
      case 'default':
        return <ProductSearch type={ProductSearchType.SINGLE_SEARCH} />;
      default:
        return <div />;
    }
  };
  const productTabs = () => {
    const tabs: PT[] = [
      { page: 'default', tab: t('common.searchProducts'), label: t('common.searchProducts'), key: 'default' },
    ];
    if (config.enableMultiSearch) {
      tabs.push({
        page: 'multi',
        tab: t('common.searchProductsMulti'),
        label: t('common.searchProductsMulti'),
        disabled: isGroup,
        key: 'multi',
      });
    }

    if (cartRequestDetail) {
      tabs.push({
        page: 'customer-quotation',
        tab: t('common.searchProductsCartRequest'),
        label: t('common.searchProductsCartRequest'),
        key: 'customer-quotation',
      });
    }

    return tabs;
  };

  return (
    <>
      <PageHeader className={styles.header}>
        <ProductTab activePage={productPage} tabs={productTabs()} />
        <div className={styles.container}>
          <Text type="secondary" className={styles.title}>
            {t('modals.searchProductMessage')}
          </Text>
          <div className={styles.content}>
            {isSmallTablet ? (
              <>
                <div className={styles.content__search}>{getProductPageType()}</div>
              </>
            ) : (
              <>
                <Space direction="horizontal" align="center" className={styles.content__left}>
                  {getProductPageType()}
                </Space>
                <Space className={styles.cartActions} direction="horizontal">
                  <BackButton step="1" />
                  <SaveCartButton />
                  <HideProductsButton />
                  <CartButton />
                </Space>
              </>
            )}
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default ProductPageHeader;
