import { createBrowserRouter, Route } from 'react-router-dom';
import { createRoutesFromElements, Navigate } from 'react-router';
import React from 'react';
import Validation from '../pages/Validation';
import Validated from '../pages/Validated';
import EditUser from '../pages/Users/EditUser';
import Users from '../pages/Users';
import UserGuide from '../pages/UserGuide';
import UnderConstruction from '../pages/UnderConstruction';
import SavedCart from '../pages/SavedCart';
import Profile from '../pages/Profile';
import Products from '../pages/Products';
import OrderHistory from '../pages/OrderHistory';
import Login from '../pages/Login';
import Deliveries from '../pages/Deliveries';
import Customers from '../pages/Customers';
import AbandonedCarts from '../pages/AbandonedCarts';
import { STEPPER } from '../constants/stepper';
import {
  getAbandonedCartsPath,
  getLoginPath,
  getOrderHistoryPath,
  getOrderRoute,
  getProfilePath,
  getSavedCartPath,
  getUnderConstructionPath,
  getUserGuidePath,
  getUsersPath, getValidatedOrderPath,
  getValidatedRoute,
  OrderRoute,
} from '../constants/routes';
import NotFound from '../components/layout/NotFound';
import NotAuthorized, { getNotAuthorizedPath } from '../components/layout/NotAuthorized';
import App from '../app/App';
import { Preloader } from './helpers';

export const AppRouter = (
  <>
    <Route path={getLoginPath()} element={<Login />} />
    <Route path={'/'} element={<App />}>
      <Route path={getOrderRoute(OrderRoute.ORDER)}>
        <Route index element={<Navigate to={getOrderRoute(OrderRoute.CUSTOMER)} />} />
        <Route
          id={getOrderRoute(OrderRoute.CUSTOMER)}
          path={getOrderRoute(OrderRoute.CUSTOMER)}
          element={<Customers />}
        />
        <Route
          id={getOrderRoute(OrderRoute.PRODUCT)}
          path={`:cartId?/${getOrderRoute(OrderRoute.PRODUCT)}`}
          element={
            <Preloader>
              <Products />
            </Preloader>
          }
        />
        <Route
          id={getOrderRoute(OrderRoute.DELIVERY)}
          path={`:cartId?/${getOrderRoute(OrderRoute.DELIVERY)}`}
          element={
            <Preloader redirectOnCartId>
              <Deliveries />
            </Preloader>
          }
        />
        <Route
          id={getOrderRoute(OrderRoute.VALIDATION)}
          path={`:cartId?/${getOrderRoute(OrderRoute.VALIDATION)}`}
          element={
            <Preloader redirectOnCartId>
              <Validation />
            </Preloader>
          }
        />
        <Route path={'*'} element={<NotFound redirectRoute={STEPPER.FIRST.route} />} />
      </Route>
      <Route path={getProfilePath()} element={<Profile />} />
      <Route path={getOrderHistoryPath()} element={<OrderHistory />} />
      <Route path={getAbandonedCartsPath()} element={<AbandonedCarts />} />
      <Route path={getSavedCartPath()} element={<SavedCart />} />
      <Route path={getUnderConstructionPath()} element={<UnderConstruction />} />
      <Route path={getUserGuidePath()} element={<UserGuide />} />
      <Route path={getUsersPath()}>
        <Route element={<Users />} index />
        <Route element={<EditUser />} path={`${getUsersPath()}/:username`} />
      </Route>
      <Route path={getValidatedRoute()} element={<Validated />} />
      <Route path={getValidatedOrderPath()} element={<Validated />} />
      <Route
        path={getNotAuthorizedPath()}
        element={() => <NotAuthorized redirectRoute={getOrderRoute(OrderRoute.CUSTOMER)} />}
      />
      <Route path={'*'} element={<NotFound redirectRoute={getOrderRoute(OrderRoute.CUSTOMER)} />} />
    </Route>
  </>
);

export const router = createBrowserRouter(createRoutesFromElements(AppRouter));
