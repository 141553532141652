import { priceParser } from 'utils';
import { ICategorizedShippingItems } from 'store/delivery/types';
import { selectCategoriesLabels } from 'store/delivery';
import { useSelector } from 'react-redux';
import { FC } from 'react';
import { Text } from 'components/shared';
import { CalendarOutlined } from '@ant-design/icons';
import ProductSwitch from './components/ProductSwitch';
import ProductInfo from './components/ProductInfo';
import styles from './DeliveryCategory.module.scss';

const DeliveryCategory: FC<ICategorizedShippingItems> = (category: ICategorizedShippingItems) => {
  const { label, products, price, stages } = category;

  const getDeliveryPriceRank = () => {
    const priceStage = stages?.find((stage) => stage.maxValue);
    if (priceStage) {
      return priceStage.maxValue;
    }
    return 0;
  };

  const priceDisplay = () => {
    return price ? `${priceParser(price)} (Offert à partir de ${priceParser(getDeliveryPriceRank())} )` : 'Offert';
  };

  const categoriesLabels = useSelector(selectCategoriesLabels);
  return products.length > 0 ? (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text className={styles.header__text} type="subtitle-gray8">
          <CalendarOutlined /> {' ' + label}
        </Text>
        <Text type="subtitle-gray8" className={styles.header__price}>
          {priceDisplay()}
        </Text>
      </div>
      <div className={styles.content}>
        {products.map((product, index) => (
          <div key={index} className={styles.product}>
            <ProductInfo product={product} category={category} />
            <ProductSwitch categoriesLabels={categoriesLabels} product={product} category={category} />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default DeliveryCategory;
