import { unwrap } from 'utils/api.utils';
import { handleCatchError, isFulfilledAction, isPendingAction, isRejectedAction } from 'utils';
import { ISavedCartStates, SaveSavedCartResponse } from 'store/savedCart/types';
import { saveSavedCartMatcher } from 'store/savedCart/matcher';
import { CART_REQUESTS_REDUCER_NAME } from 'store/common';
import { cartRequestStateNormalizer } from 'store/cartRequests/normalizers';
import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import {
  FetchCartRequestAndCreateStateResponse,
  ICartRequest,
  ICartRequestDetail,
  ICartRequestItemAttributes,
  ICartRequestItemPatchAttributes,
  ICartRequestParams,
} from '../../types/cartRequest';
import { ErrorType, PaginatedResponse } from '../../types';
import { CartService } from '../../services/cart.service';
import { ICartRequestsState } from './types';

export const fetchCartRequests: AsyncThunk<
  PaginatedResponse<ICartRequest> & {
  states: ISavedCartStates;
},
  { filters: ICartRequestParams; page: number; size: number },
  { rejectValue: ErrorType }
> = createAsyncThunk(`${CART_REQUESTS_REDUCER_NAME}/fetchCartRequests`, async (data, { rejectWithValue }) => {
  try {
    return unwrap(await CartService.getCartRequests(data.filters, data.page, data.size));
  } catch (err) {
    return handleCatchError(err, rejectWithValue, true);
  }
});

export const fetchCartRequest: AsyncThunk<ICartRequestDetail, ICartRequestDetail, { rejectValue: ErrorType }> =
  createAsyncThunk(`${CART_REQUESTS_REDUCER_NAME}/fetchCartRequest`, async (cartRequest) => {
    return cartRequest;
  });

export const fetchCartRequestAndCreateState: AsyncThunk<
  FetchCartRequestAndCreateStateResponse,
  { id: number; isAssigned?: boolean },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${CART_REQUESTS_REDUCER_NAME}/fetchCartRequestAndCreateState`,
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const cartRequestDetail = unwrap(await CartService.getCartRequest(data.id, data.isAssigned));
      const cartRequestState = await cartRequestStateNormalizer({
        cartRequestDetail,
        dispatch,
        tcTags: getState().user.tags,
      });

      return {
        cartRequestDetail,
        cartRequestState,
      };
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);
export const patchCartRequests: AsyncThunk<
  ICartRequest,
  { id: number; cartRequest: Partial<ICartRequest> },
  { rejectValue: ErrorType }
> = createAsyncThunk(`${CART_REQUESTS_REDUCER_NAME}/assignCartRequest`, async (data, { rejectWithValue }) => {
  try {
    return unwrap(await CartService.patchCartRequest(data.id, data.cartRequest));
  } catch (err) {
    return handleCatchError(err, rejectWithValue, true);
  }
});

export const patchCartRequestItem: AsyncThunk<
  ICartRequestItemAttributes,
  Pick<ICartRequestItemAttributes, 'id'> & Partial<ICartRequestItemPatchAttributes>,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CART_REQUESTS_REDUCER_NAME}/patchCartRequestItem`,
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      return unwrap(await CartService.patchCartRequestItem(id, data));
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

const initialState: ICartRequestsState = {
  isLoading: false,
  page: 1,
  total: 0,
  limit: 30,
  filters: {},
  error: null,
  cartRequestsList: [],
  states: {
    converted: 0,
    draft: 0,
    pending: 0,
    in_progress: 0,
    refused: 0,
    sent_to_customer: 0,
  },
  cartRequestDetail: null,
};

const cartRequestsSlice = createSlice({
  name: CART_REQUESTS_REDUCER_NAME,
  initialState,
  reducers: {
    clearCartRequestsState() {
      return { ...initialState };
    },
    setCartRequestItemName(state, { payload }: PayloadAction<{ id: number; name: string }>) {
      if (!state.cartRequestDetail || state.cartRequestDetail.items?.length < 1) {
        return;
      }
      const itemIndex = state.cartRequestDetail.items.findIndex((item) => item.id === payload.id);
      if (itemIndex > -1) {
        state.cartRequestDetail.items[itemIndex].name = payload.name;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(patchCartRequestItem.fulfilled, (state, { payload }) => {
        if (!state.cartRequestDetail) {
          return state;
        }

        const { id, processed } = payload;
        const itemIndex = state.cartRequestDetail.items.findIndex((item) => item.id === id);
        if (itemIndex === -1) return;
        state.cartRequestDetail.items[itemIndex].processed = Number(processed);
      })
      .addCase(fetchCartRequests.fulfilled, (state, { payload }) => {
        state.cartRequestsList = payload.data;
        state.total = payload.total;
        state.states = payload.states;
      })
      .addCase(fetchCartRequests.rejected, (state, { payload }: PayloadAction<any>) => {
        state.error = payload;
      })
      .addCase(patchCartRequests.fulfilled, (state, { payload }) => {
        const cartRequestIndex = state.cartRequestsList.findIndex((cartRequest) => cartRequest.id === payload.id);
        if (cartRequestIndex === -1) return;
        state.cartRequestsList[cartRequestIndex] = {
          ...state.cartRequestsList[cartRequestIndex],
          ...payload,
        };
      })
      .addCase(fetchCartRequest.fulfilled, (state, { payload }) => {
        state.cartRequestDetail = payload;
      })
      .addCase(fetchCartRequestAndCreateState.fulfilled, (state, { payload }) => {
        const { cartRequestDetail } = payload;
        state.cartRequestDetail = cartRequestDetail;
      })
      .addMatcher(isPendingAction(`${CART_REQUESTS_REDUCER_NAME}/`), (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${CART_REQUESTS_REDUCER_NAME}/`), (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addMatcher(isFulfilledAction(`${CART_REQUESTS_REDUCER_NAME}/`), (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(saveSavedCartMatcher(), (state, { payload }: PayloadAction<SaveSavedCartResponse>) => {
        if (state.cartRequestDetail) {
          state.cartRequestDetail.atcCheckoutCartId = payload.cart.id;
        }
      });
  },
});
export const { clearCartRequestsState, setCartRequestItemName } = cartRequestsSlice.actions;
export default cartRequestsSlice.reducer;
