import { filterColumns } from 'utils/table.utils';
import { ICartRequestItem } from 'types/cartRequest';
import { useAppSelector } from 'store/store';
import {
  generateList,
  selectCustomerQuotationSearchResults,
  selectExcludeNoStockOffers,
  selectIsLoading,
  setWarehouseStockModalSku,
  setWarehouseStockModalVisibility,
} from 'store/products';
import { patchCartRequestItem, selectCartRequestDetail, setCartRequestItemName } from 'store/cartRequests';
import { selectCartProductsRequestIds } from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTableWidthBuilder } from 'hooks/useTableWidthBuilder';
import { Button } from 'components/shared';
import classNames from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Table, Tooltip, Space } from 'antd';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import ProductSearch from '../ProductPageHeader/components/ProductSearch';
import { getParsedProductsForTable, nullStringToMinus } from '../../../utils';
import { IParsedProductItem, ProductSearchType } from '../../../store/products/types';
import styles from './ProductTableWiuz.module.scss';
import {
  CustomerQuotationQtyColumn,
  ProductCustomerQuotationSearchQuantityColumn,
  ProductDeliveryColumn,
  ProductInfoColumn,
  ProductNullColumn,
  ProductPriceColumn,
  ProductReferenceColumn,
  ProductStockColumn,
  ProductWarehouseStockColumn,
} from './ProductTableColumns';

const getRowKey = (record: ICartRequestItem) => record.id;

const ProductTableCustomerQuotation: FC = () => {
  const isLoading = useSelector(selectIsLoading);
  const excludeNoStockOffers = useSelector(selectExcludeNoStockOffers);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);
  const dispatch = useDispatch();

  const cartRequestDetail = useSelector(selectCartRequestDetail);
  const cartRequestItems = useMemo(() => cartRequestDetail?.items || [], [cartRequestDetail?.items]);

  const searchResults = useAppSelector(selectCustomerQuotationSearchResults);
  const cartProductsRequestsIds = useSelector(selectCartProductsRequestIds);

  const [previousCartProductsRequestsIds, setPreviousCartProductsRequestsIds] = useState<Array<number>>([]);

  useEffect(() => {
    // Automatically detects changes in cart and update the cart request item record.
    if (JSON.stringify(cartProductsRequestsIds) !== JSON.stringify(previousCartProductsRequestsIds)) {
      cartProductsRequestsIds.forEach((id) => {
        dispatch(patchCartRequestItem({ id, processed: Number(true) }));
      });

      previousCartProductsRequestsIds.forEach((id) => {
        dispatch(patchCartRequestItem({ id, processed: Number(false) }));
      });
    }
    setPreviousCartProductsRequestsIds(cartProductsRequestsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartProductsRequestsIds]);

  const isCartRequestItemChecked = (record: ICartRequestItem): boolean => {
    const isProccesed = isRecordProcesed(record);
    const isInProductCart = isRequestedCartItemInCart(record.id);

    const isChecked = isProccesed || isInProductCart;
    return isChecked;
  };

  const isRequestedCartItemInCart = useCallback(
    (cartRequestItemId: number): boolean => cartProductsRequestsIds.includes(cartRequestItemId),

    [cartProductsRequestsIds],
  );

  const handleToggleExpandAllRows = useCallback(() => {
    setExpanded((value) => !value);
    const expandKeys = cartRequestItems.map((item) => item.id);
    setExpandedKeys(expanded ? [] : expandKeys);
  }, [cartRequestItems, expanded]);

  const hasSearchResults = useCallback(
    (key: number) => searchResults[key] && Object.values(searchResults[key].products).length > 0,
    [searchResults],
  );

  const handleSearchCompleted = (inputValue: string, key: number) => {
    dispatch(setCartRequestItemName({ id: key, name: inputValue }));
    setExpandedKeys((state) => {
      if (!state.includes(key)) {
        return [...state, key];
      }
      return state;
    });
  };

  const handleToggleExpandRow = useCallback((isExpanded: boolean, key: number) => {
    setExpandedKeys((state) => {
      if (isExpanded) {
        return state.filter((item) => item !== key);
      } else {
        if (!state.includes(key)) {
          return [...state, key];
        }
      }
      return state;
    });
  }, []);

  const isRecordProcesed = (record: ICartRequestItem): boolean => Boolean(record.processed);

  const handleRecordPocessed = (record: ICartRequestItem) => {
    if (!isRequestedCartItemInCart(record.id)) {
      dispatch(patchCartRequestItem({ id: record.id, processed: Number(!record.processed) }));
    }
  };

  const { width } = useTableWidthBuilder([
    { width: 8, column: 'ProductName' },
    { width: 2, column: 'Reference' },
    { width: 1, column: 'Offline' },
    { width: 2, column: 'Stock' },
    { width: 2, column: 'WarehouseStock' },
    { width: 4, column: 'Delivery' },
    { width: 2, column: 'Price' },
    { width: 3, column: 'Quantity' },
    { width: 1, column: 'Actions', skip: true },
  ]);

  const tableColumns = filterColumns<
    TableColumnDefinition<ICartRequestItem> | TableColumnDefinition<unknown> | typeof Table.EXPAND_COLUMN
  >([
    {
      title: 'Product info',
      dataIndex: undefined,
      render: (record: ICartRequestItem) => {
        const { name } = record;
        return (
          <div className={styles.productSearch}>
            <Tooltip title={name}>
              <span>
                <InfoCircleOutlined />
              </span>
            </Tooltip>
            <ProductSearch
              onTypeSearch={false}
              value={name}
              type={ProductSearchType.CUSTOMER_QUOTATION_SEARCH}
              preloadSearch={false}
              rowKey={getRowKey(record)}
              onSearchCompleted={(inputValue) => handleSearchCompleted(inputValue, getRowKey(record))}
            />
          </div>
        );
      },
    },
    {
      title: null,
      dataIndex: undefined,
      render: (record: ICartRequestItem) => {
        return (
          <Space size="large">
            <div>
              <Checkbox checked={isCartRequestItemChecked(record)} onChange={() => handleRecordPocessed(record)}>
                {t('cartRequests.markAsQuoted')}
              </Checkbox>
            </div>
            <div>{nullStringToMinus(record.deliveryPeriod || null)}</div>
          </Space>
        );
      },
      onCell: () => ({ colSpan: 6 }),
    },
    ProductNullColumn({
      title: t('common.code'),
      align: 'center',
      width: width('Reference'),
      onCell: () => ({ colSpan: 0 }),
    }),
    ProductNullColumn({
      title: t('common.stock'),
      align: 'center',
      width: width('Stock'),
      onCell: () => ({ colSpan: 0 }),
    }),
    ProductNullColumn({
      title: t('common.stock'),
      align: 'center',
      width: width('Stock'),
      onCell: () => ({ colSpan: 0 }),
    }),
    ProductNullColumn({
      title: t('common.delivery'),
      align: 'center',
      width: width('Delivery'),
      onCell: () => ({ colSpan: 0 }),
    }),
    ProductNullColumn({
      title: t('common.netPrice'),
      align: 'right',
      width: width('Price'),
      onCell: () => ({ colSpan: 0 }),
    }),
    CustomerQuotationQtyColumn({
      title: t('common.quantity'),
      width: width('Quantity'),
    }),
    Table.EXPAND_COLUMN,
  ]);

  const getProductsForQuery = (record: ICartRequestItem) => {
    const searchResult = searchResults[getRowKey(record)];
    if (!searchResult) {
      return [];
    }

    const { products, offers, searchedVariantsIds } = searchResult;
    const productsList = getParsedProductsForTable({ products, offers });
    return generateList(searchedVariantsIds, productsList, excludeNoStockOffers);
  };

  const showStockModal = (sku: string) => {
    dispatch(setWarehouseStockModalSku(sku));
    dispatch(setWarehouseStockModalVisibility(true));
  };

  return (
    <>
      <div className={styles.container}>
        <Table
          columns={tableColumns as ColumnsType<ICartRequestItem>}
          size="small"
          className={styles.container__table}
          rowClassName={(record: ICartRequestItem) => {
            return classNames(styles.row, {
              [styles.row__checked]: isCartRequestItemChecked(record),
            });
          }}
          rowKey={getRowKey}
          loading={isLoading}
          pagination={false}
          dataSource={cartRequestItems}
          expandable={{
            defaultExpandAllRows: true,
            indentSize: 0,
            expandedRowKeys: expandedKeys,
            expandedRowClassName: () => styles.expandableRow,
            columnTitle: () => {
              const Icon = expanded ? <UpOutlined /> : <DownOutlined />;
              return (
                <>
                  <Button className={styles.expand__button} onClick={handleToggleExpandAllRows} icon={Icon} />
                </>
              );
            },
            expandRowByClick: true,
            expandedRowRender: (record, row) => {
              const childTableColumns = filterColumns([
                ProductInfoColumn({ width: width('ProductName'), title: t('common.productInfo') }),
                ProductReferenceColumn({ width: width('Reference'), title: t('common.code') }),
                ProductStockColumn({ width: width('Stock'), title: t('common.stock') }),
                ProductWarehouseStockColumn(showStockModal, {
                  width: width('WarehouseStock'),
                  title: t('common.stock'),
                }),
                ProductDeliveryColumn({ width: width('Delivery'), title: t('common.delivery') }),
                ProductPriceColumn({ width: width('Price'), title: t('common.netPrice') }),
                ProductCustomerQuotationSearchQuantityColumn(
                  { width: width('Quantity'), title: t('common.quantity') },
                  record,
                ),
              ]);

              return (
                <Table
                  rowKey={(product, key) => `${row}_${key}`}
                  showHeader={false}
                  className={styles.container__child_table}
                  rowClassName={styles.child_row}
                  columns={childTableColumns as ColumnsType<IParsedProductItem>}
                  pagination={false}
                  dataSource={getProductsForQuery(record)}
                />
              );
            },
            rowExpandable: () => true,

            // eslint-disable-next-line react/prop-types
            expandIcon: ({ expanded: isExpanded, onExpand: _onExpand, record }) => {
              const Icon = isExpanded ? <UpOutlined /> : <DownOutlined />;
              return (
                <Button
                  className={styles.expand__button}
                  onClick={() => handleToggleExpandRow(isExpanded, getRowKey(record))}
                  icon={Icon}
                  disabled={!hasSearchResults(getRowKey(record))}
                />
              );
            },
          }}
        />
        <span className={styles.comment}>
          {t('common.comment')} : <em>{cartRequestDetail?.comment || '-'}</em>
        </span>
      </div>
    </>
  );
};

export default ProductTableCustomerQuotation;
