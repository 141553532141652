import { fulfilledAction, isOneOfActions } from 'utils';
import { AnyAction } from '@reduxjs/toolkit';

export const SAVED_CART_REDUCER_NAME = 'savedCart';
export const CUSTOMERS_REDUCER_NAME = 'customers';
export const CART_REDUCER_NAME = 'cart';
export const CART_REQUESTS_REDUCER_NAME = 'cartRequests';
export const ABANDONED_CARTS_REDUCER_NAME = 'abandonedCarts';

export const isNewOrderMatcher = (): ((action: AnyAction) => action is AnyAction) => {
  return isOneOfActions([
    `${CUSTOMERS_REDUCER_NAME}/setSelectedSearchedCustomer`,
    `${CUSTOMERS_REDUCER_NAME}/setSelectedSearchedGroupCustomer`,
    `${CUSTOMERS_REDUCER_NAME}/setCustomersView`,
    `${CUSTOMERS_REDUCER_NAME}/setCustomersGroupsView`,
    fulfilledAction(`${CART_REDUCER_NAME}/orderValidation`),
  ]);
};
