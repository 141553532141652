import { castTaxes } from 'utils';
import { IAlgoliaProduct, IProductOfferShippingMethod } from 'types/product';
import { ICartProductItem } from 'store/cart/types';
import type { IDiscount, IOfferProduct, IStorageOffer } from 'types/offer';
import type { INormalizedList } from 'types';
import type {
  FetchDeparturesResponse,
  GroupedProductsWithoutSorting,
  IDeparture,
  IFetchProductsResponse,
  IOffersListByProductId,
  ProductWithOffer,
} from 'store/products/types';

const sortDiscountOffers = (a: IDiscount, b: IDiscount) => {
  const dateSA = new Date(a.StartDate).getTime();
  const dateSB = new Date(b.StartDate).getTime();
  const dateEA = new Date(a.EndDate).getTime();
  const dateEB = new Date(b.EndDate).getTime();
  return dateSA - dateSB || dateEA - dateEB || a.MinimumQuantity - b.MinimumQuantity;
};

function zipProductsWithOffers(products: IAlgoliaProduct[], offers: IOfferProduct[]): GroupedProductsWithoutSorting {
  const offersByProductId = offers.reduce(
    (acc, offer) => ({ ...acc, [offer.ProductId]: offer }),
    <{ [key: number]: IOfferProduct }>{},
  );

  const productsWithOffers: ProductWithOffer[] = products
    .filter((product) => offersByProductId[product.productId])
    .map((product) => {
      return Object.assign(product, {
        offer: offersByProductId[product.productId],
      });
    });

  const productsWithoutOffers = products.filter((product) => !offersByProductId[product.productId]);

  return {
    productsWithOffers,
    productsWithoutOffers,
  };
}

type NormalizedOffers = {
  offers: INormalizedList<IStorageOffer>;
  products: IOffersListByProductId;
};

type FetchProductsNormalizer = {
  searchedVariantsIds: number[];
  normalizedProducts: INormalizedList<IAlgoliaProduct>;
  normalizedOffers: NormalizedOffers;
};
export const fetchDeparturesNormalizer = (data: FetchDeparturesResponse): Record<number, IDeparture> => {
  return data.reduce((acc, departure) => {
    return {
      ...acc,
      [departure.id]: departure,
    };
  }, {});
};

export const fetchProductsNormalizer = ({
  products,
  offers: currentOffers,
  searchedVariantsIds,
  shippingMethods = [],
}: IFetchProductsResponse): FetchProductsNormalizer => {
  const offers = [...(currentOffers?.Product || [])];

  const productsByOffers = zipProductsWithOffers(products, offers);

  productsByOffers.productsWithOffers.forEach((product, index) => {
    product.sortingIndex = index;
  });

  productsByOffers.productsWithoutOffers.forEach((product, index) => {
    product.sortingIndex = index + productsByOffers.productsWithOffers.length;
  });

  /**
   * Normalized Products
   */
  const normalizedProducts = products.reduce((accumulator: INormalizedList<IAlgoliaProduct>, item) => {
    return Object.assign(accumulator, { [item.variantId]: item });
  }, {});

  const offersList = offers.flatMap(({ Variants, ProductId }) =>
    Variants.flatMap(({ OfferPrice }) =>
      OfferPrice.flatMap(({ Offer, DiscountedPrice, IsTruck, Tag, RawPrice, Discount }) => {
        const {
          OfferId,
          VariantId,
          Stock,
          Days,
          StartDate,
          EndDate,
          PurchasableOffline,
          SupplierUnitPrice,
          DepartureId,
          Online,
          IgnoreStock,
          Campaign,
        } = Offer;
        const product: IAlgoliaProduct | undefined = normalizedProducts[VariantId];

        const offerShippingMethods =
          shippingMethods
            ?.filter((sm) => sm.shippingMethods !== null)
            .find((shippingMethod) => shippingMethod.offerId === OfferId)?.shippingMethods ?? [];
        if (offerShippingMethods.length > 0) {
          product?.shippingMethods?.push({
            offerId: OfferId,
            methods: offerShippingMethods.map((shippingMethod) => {
              return {
                type: '',
                id: shippingMethod.shippingMethodId,
                label: shippingMethod.label,
                maxDelay: shippingMethod.maxDelay,
                minDelay: shippingMethod.minDelay,
              } as IProductOfferShippingMethod;
            }),
          });
        }

        const storageOffer = {
          offerId: OfferId,
          productId: ProductId,
          variantId: VariantId,
          price: {
            supplierUnitPrice: SupplierUnitPrice,
            initialPrice: RawPrice,
            price: DiscountedPrice,
          },
          stock: Stock,
          departureId: DepartureId,
          quantityIncrement: Offer.QuantityIncrement,
          minimumOrderable: Offer.MinimumOrderable,
          days: Days && Days > 0 ? Days : null,
          isTruck: IsTruck,
          startDate: StartDate,
          endDate: EndDate,
          tags: Tag,
          purchasableOffline: PurchasableOffline,
          online: Online,
          discountOffers: Discount?.sort(sortDiscountOffers),
          ignoreStock: IgnoreStock,
          campaign: Campaign,
        } as IStorageOffer;

        if (product) {
          const foundShippingMethods = product.shippingMethods?.find(
            (shippingMethod) => shippingMethod.offerId === OfferId,
          )?.methods;

          return Object.assign(storageOffer, {
            shippingMethods: foundShippingMethods || [],
            days: Days,
            taxes: castTaxes(product.taxes.FR),
            isTownRequired: product.isTownRequired,
          });
        }

        return storageOffer;
      }),
    ),
  );

  const normalizedOffers = offersList.reduce<NormalizedOffers>(
    (accumulator, offer) => {
      accumulator.offers[offer.offerId] = offer;

      if (accumulator.products[offer.variantId]) {
        accumulator.products[offer.variantId].push(offer.offerId);
      } else {
        accumulator.products[offer.variantId] = [offer.offerId];
      }

      return accumulator;
    },
    { offers: {}, products: {} },
  );

  return {
    normalizedProducts,
    searchedVariantsIds,
    normalizedOffers,
  };
};

type ITruckSplitInfo = ICartProductItem['truckSplitInfo'];

export const trackSplitInfoNormalizer = (
  selectedGroupCustomerIds: number[],
  selectedGroupsCustomerIdentifiers: Array<{ id: number; customerIdentifier: string }> | null,
  {
    quantity,
    quantityIncrement,
    conditioningQuantity,
  }: {
    quantity: number;
    quantityIncrement: number;
    productQuantity: number;
    conditioningQuantity: number;
  },
): ITruckSplitInfo => {
  const trucksplit: ITruckSplitInfo = {};

  if (Number.isInteger(quantity)) {
    [...Array(quantity)].forEach((_, idx) => {
      trucksplit[idx] = {
        truckNumber: idx + 1,
        totalTruckAvailable: quantityIncrement / conditioningQuantity,
        customerProductInfo: (selectedGroupsCustomerIdentifiers || []).reduce(
          (acc, customer) => ({
            ...acc,
            [customer.customerIdentifier]: {
              selectedQuantity: 0,
              availableCustomerQuantity: quantityIncrement / conditioningQuantity,
              customerIdentifier: customer.customerIdentifier,
            },
          }),
          {},
        ),
      };
    });

    return trucksplit;
  }
};
