import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Button, Modal } from 'components/shared';
import ProductFileImportConfirmation from 'components/product/ProductPageHeader/components/ProductFileImportConfirmation';
import ProductFileImport from 'components/product/ProductPageHeader/components/ProductFileImport';
import styles from './ProductFileImportButton.module.scss';

// eslint-disable-next-line no-shadow
export enum ProductFileImportEnum {
  MULTI = 'multi',
}

export type ProductFileImportButtonProps = {
  type: ProductFileImportEnum;
};

const ProductFileImportButton: FC<ProductFileImportButtonProps> = ({ type }: ProductFileImportButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setVisible(false);
    setTimeout(() => {
      setShowConfirmation(false);
    }, 200);
  };

  const title = () => {
    switch (type) {
      case ProductFileImportEnum.MULTI:
        return t('common.multiSearchImportButton');
      default:
        return '';
    }
  };

  return (
    <>
      <Button type="primary" className={styles.button} onClick={() => setVisible(true)}>
        {title()}
      </Button>
      <Modal className={styles.modal} open={visible} onCancel={handleCloseModal} width={showConfirmation ? 468 : 633}>
        <div className={styles.modal__form}>
          {showConfirmation ? (
            <ProductFileImportConfirmation closeModal={handleCloseModal} />
          ) : (
            <ProductFileImport type={type} showConfirmation={() => setShowConfirmation(true)} />
          )}
        </div>
      </Modal>
    </>
  );
};

export default ProductFileImportButton;
