import {
  calcTotalTaxes,
  calcTotalWithoutTaxes,
  calcTotalWithTaxes,
  createCartTotalAccumulator,
  groupCartProducts,
} from 'utils/cart.utils';
import { getParsedCartProduct, isEmpty } from 'utils';
import { ICreateOrderParam, IOrder, IOrderItem } from 'types/order';
import { Log } from 'types/logs';
import { ICartRequestItem } from 'types/cartRequest';
import { Selector, ErrorType, IUser } from 'types';
import { IParsedProductItem } from 'store/products/types';
import moment from 'moment';
import { DiscountError } from 'components/shared/Discount/Discount.types';
import { createSelector } from '@reduxjs/toolkit';
import { SavedCartStatusEnum } from '../savedCart/types';
import { RootState } from '../rootReducer';
import { ICategorizedShippingItems } from '../delivery/types';
import { addBusinessDays } from '../../utils/global.utils';
import { IHistoricalPrice } from '../../types/product';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import {
  CartAttachmentStatus,
  ICartProductItem,
  IGroupedCartProduct,
  IHistoricalPriceDataItem,
  ISelectAllCartTaxesResponse,
} from './types';

export const selectCartProductsCount = (state: RootState): number => state.cart.products.length;

export const selectIsEco = (state: RootState): boolean => state.cart.isEco;

export const selectCartStatus = (state: RootState): SavedCartStatusEnum | null => state.cart.status;

export const selectAreCartItemsExpanded = (state: RootState): boolean => state.cart.areItemsExpanded;

export const selectCartProductByIndex =
  (index: number) =>
  (state: RootState): ICartProductItem =>
    state.cart.products[index];

export const getHistoricalPriceSku = (state: RootState): string | null => state.cart.historicalPrices.sku;
export const getHistoricalPriceLoadingState = (state: RootState): boolean => state.cart.historicalPrices.isLoading;
export const getIsCartUpdated = (state: RootState): boolean => state.cart.isCartUpdated;
export const getHistoricalPricePerSku = (state: RootState): IHistoricalPriceDataItem | undefined => {
  const sku = state.cart.historicalPrices.sku;
  if (sku && Object.keys(state.cart.historicalPrices.data).includes(sku)) {
    return state.cart.historicalPrices.data[sku];
  }
};

export const selectCartComment = (state: RootState): string | null => state.cart.comment;
export const selectCartDeliveryComment = (state: RootState): string | null => state.cart.deliveryComment;
export const selectCartInvoiceComment = (state: RootState): string | null => state.cart.invoiceComment;

export const selectProductsByOfferIds =
  (offerIds: Array<number>) =>
  (state: RootState): Array<ICartProductItem> => {
    const foundProducts: Array<ICartProductItem> = [];
    offerIds.forEach((id) => {
      const findCartProduct = state.cart.products.find((product) => product.offerId === id);
      if (findCartProduct) foundProducts.push(findCartProduct);
    });
    return foundProducts;
  };

export const selectOrders = (state: RootState): Array<IOrder> => Object.values(state.cart.orders);

export const selectParsedOrdersItems = (state: RootState): Array<ICreateOrderParam> => {
  const isGroup = state.customers.isGroup;

  if (isGroup) {
    const groupOrders: Array<ICreateOrderParam> = [];
    Object.values(state.delivery.shippingCategories).forEach((shippingCategory) => {
      shippingCategory.products.forEach((product) => {
        let order = {} as ICreateOrderParam;
        const { startDate, endDate } = product;
        const { deliveryStartDate, deliveryEndDate } = calculateDeliveryDays(
          startDate!,
          endDate!,
          product.days,
          shippingCategory,
        );
        if (product.isTruck) {
          Object.entries(product.truckSplitInfo || {}).forEach(([key, truck]) => {
            const customerIds = Object.entries(truck.customerProductInfo)
              .map(([customerId, customerValue]) => {
                if ((customerValue as any).selectedQuantity > 0) return customerId.toString();
              })
              .filter((item) => !!item);

            order = {
              offerId: product.offerId + '-' + key,
              isTruck: true,
              deliveryStartDate: deliveryStartDate,
              deliveryEndDate: deliveryEndDate,
              deliveryDay: product.days,
              shippingMethodId: String(shippingCategory.shippingMethodId),
              shippingMethodType: shippingCategory.type,
              quantity: 1,
              customerIds: Object.values(customerIds),
            } as ICreateOrderParam;
            groupOrders.push(order);
          });
          return;
        }
        const customerSplit = Object.entries(product.customerProductInfo || [])
          .map(([customerId, customerValue]) => {
            if ((customerValue as any).selectedQuantity > 0) return customerId.toString();
          })
          .filter((item) => !!item);
        order = {
          offerId: String(product.offerId),
          isTruck: false,
          deliveryStartDate: deliveryStartDate,
          deliveryEndDate: deliveryEndDate,
          deliveryDay: product.days,
          shippingMethodId: String(shippingCategory.shippingMethodId),
          shippingMethodType: shippingCategory.type,
          quantity: product.quantity,
          customerIds: Object.values(customerSplit),
        } as ICreateOrderParam;
        groupOrders.push(order);
      });
    });
    return groupOrders;
  }
  const orders = Object.values(state.delivery.shippingCategories).flatMap((category) => {
    return category.products.map((product) => {
      const { startDate, endDate } = product;
      const { deliveryStartDate, deliveryEndDate } = calculateDeliveryDays(
        startDate!,
        endDate!,
        product.days,
        category,
      );
      const selectedCustomer = state.customers.selectedCustomer;
      const customer = selectedCustomer ? state.customers.customers[selectedCustomer] : null;
      return {
        offerId: product.offerId + '',
        isTruck: !!product.isTruck,
        deliveryStartDate: deliveryStartDate,
        deliveryEndDate: deliveryEndDate,
        deliveryDay: product.days,
        shippingMethodId: category.shippingMethodId + '',
        shippingMethodType: category.type,
        quantity: product.quantity,
        customerIds: [customer?.customerIdentifier + ''],
      } as ICreateOrderParam;
    });
  });

  const response = orders.filter(Boolean);

  return response as Array<ICreateOrderParam>;
};

export const calculateDeliveryDays = (
  startDate: string,
  endDate: string,
  days: Nullable<number>,
  shippingCategory: ICategorizedShippingItems,
): { deliveryStartDate: string; deliveryEndDate: string } => {
  let deliveryStartDate = moment();
  let deliveryEndDate = moment();

  switch (shippingCategory.type) {
    case 'period': {
      if (days) {
        deliveryEndDate = addBusinessDays(deliveryEndDate, Number(days));
        break;
      }
      deliveryEndDate = moment(endDate);
      deliveryStartDate = moment(startDate);
      break;
    }
    default: {
      if (shippingCategory.maxDelay && shippingCategory.minDelay) {
        deliveryEndDate = addBusinessDays(deliveryEndDate, Number(shippingCategory.maxDelay));
        deliveryStartDate = addBusinessDays(deliveryStartDate, Number(shippingCategory.minDelay));
      } else {
        deliveryEndDate = moment(endDate);
        deliveryStartDate = moment(startDate);
      }
    }
  }

  return {
    deliveryStartDate: deliveryStartDate.format(CUSTOM_DATE_FORMAT.mysql.short),
    deliveryEndDate: deliveryEndDate.format(CUSTOM_DATE_FORMAT.mysql.short),
  };
};

export const selectIsAnyProductSelected = (state: RootState): boolean =>
  !isEmpty(state.cart.products.filter((prod) => !prod.noStock && prod.enabled));

export const selectIsAnyProductSearched = (state: RootState): boolean => !isEmpty(state.products.products);

export const selectCartProducts = (state: RootState): ICartProductItem[] => {
  return state.cart.products;
};
export const selectCartProductsWithStock = (state: RootState): ICartProductItem[] => {
  return state.cart.products.filter((product) => !product.noStock && product.enabled);
};

export const selectCartProductsWithNoStock = (state: RootState): ICartProductItem[] => {
  return state.cart.products.filter((product) => product.noStock);
};

export const selectCartGroups = (state: RootState): Array<string> => {
  if (!state.cart.groups) return [];

  if (typeof state.cart.groups === 'string') {
    return (state?.cart?.groups as string).split(',');
  }

  return state.cart.groups;
};

export const selectGroupedCartProducts = createSelector(
  [selectCartProducts, selectCartGroups],
  (cartProducts, groups): IGroupedCartProduct => {
    return groupCartProducts<ICartProductItem>(cartProducts, groups);
  },
);

export const selectParsedCartProduct = (
  offerId: number,
  quantity: number,
  parsedProduct: IParsedProductItem,
): Selector<ICartProductItem | null> =>
  createSelector(
    (state: RootState) => state.products,
    (productsState) => {
      const { products, offers } = productsState;
      return getParsedCartProduct({ products, offers }, offerId, quantity, parsedProduct);
    },
  );

export const selectParsedReplacementProduct = (
  offerId: number,
  quantity: number,
  parsedProduct: IParsedProductItem,
): Selector<ICartProductItem | null> =>
  createSelector(
    (state: RootState) => state.products,
    (productsState) => {
      const { products, offers } = productsState.replacementModal;
      return getParsedCartProduct({ products, offers }, offerId, quantity, parsedProduct);
    },
  );

export const selectParsedCartMultiProduct = (
  offerId: number,
  quantity: number,
  parsedProduct: IParsedProductItem,
  rowKey: number,
): Selector<ICartProductItem | null> =>
  createSelector(
    (state: RootState) => state.products,
    (productsState) => {
      const multiResults = productsState.multiSearch.searchResults[rowKey];
      const { products, offers } = multiResults;

      return getParsedCartProduct({ products, offers }, offerId, quantity, parsedProduct);
    },
  );

export const selectParsedCartCustomerQuotationProduct = (
  offerId: number,
  quantity: number,
  parsedProduct: IParsedProductItem,
  rowKey: number,
  cartRequestItem: ICartRequestItem,
): Selector<ICartProductItem | null> =>
  createSelector(
    (state: RootState) => state.products,
    (productsState) => {
      const multiResults = productsState.customerQuotation.searchResults[rowKey];
      const { products, offers } = multiResults;

      return getParsedCartProduct({ products, offers }, offerId, quantity, parsedProduct, undefined, cartRequestItem);
    },
  );

export const selectCartPricesTotal = createSelector([selectCartProductsWithStock], (products): number =>
  calcTotalWithoutTaxes(createCartTotalAccumulator(products)),
);

export const selectCartPricesTotalWithTaxes = createSelector([selectCartProductsWithStock], (products): number =>
  calcTotalWithTaxes(createCartTotalAccumulator(products)),
);

export const selectAllCartTaxes = createSelector(
  [selectCartProductsWithStock],
  (products): ISelectAllCartTaxesResponse => calcTotalTaxes(createCartTotalAccumulator(products)),
);

export const selectCartError = (state: RootState): ErrorType | null => state.cart.error;
export const selectCartProductError = (state: RootState): boolean =>
  state.cart.products.some((product) => product?.discountError && product.discountError !== DiscountError.FALSE);

export const selectIsLoading = (state: RootState): boolean => state.cart.isLoading;

export const selectOrderByOfferId =
  (_offerId: string) =>
  (state: RootState): IOrderItem | undefined =>
    Object.values(state.cart.orders)
      .flatMap(({ orderItems }) => orderItems)
      .find((order) => order.offerId === _offerId);

export const selectTruckCustomerInfo =
  (truckId: number, customerIdentifier: string | undefined, offerId: number) =>
  (state: RootState): { availableCustomerQuantity: number; selectedQuantity: number } | undefined => {
    const productOffer = state.cart.products.find((product) => product.offerId === offerId);
    if (!isNaN(truckId) && customerIdentifier && offerId) {
      if (productOffer && productOffer.truckSplitInfo) {
        const truckInfo = productOffer.truckSplitInfo[truckId];
        if (truckInfo && truckInfo.customerProductInfo) {
          let identifier: string | null = null;
          Object.keys(truckInfo.customerProductInfo).forEach((key) => {
            if (key === customerIdentifier) {
              identifier = key;
            }
          });

          if (!identifier) return;
          return truckInfo.customerProductInfo[identifier];
        }
      }
    }
    if (!truckId && customerIdentifier && offerId) {
      if (productOffer && productOffer.customerProductInfo) {
        let identifier: string | null = null;
        Object.keys(productOffer.customerProductInfo).forEach((key) => {
          if (key === customerIdentifier) {
            identifier = key;
          }
        });
        if (!identifier) return;
        return productOffer.customerProductInfo[identifier];
      }
    }
  };

export const selectTruckInfo =
  (truckId: number, offerId: number) =>
  (state: RootState): { truckNumber: number } | undefined => {
    if (!isNaN(truckId) && typeof truckId === 'number' && offerId) {
      const productOffer = state.cart.products.find((product) => product.offerId === offerId);
      if (productOffer && productOffer.truckSplitInfo) {
        return productOffer.truckSplitInfo[truckId];
      }
    }
  };

export const selectCartId = (state: RootState): string | null => state.cart.cartId;
export const selectRestoredUserTags = (state: RootState): string[] => state.cart.restoredUserTags ?? [];
export const selectAtcCartNumber = (state: RootState): Nullable<string> => state.cart.atcCartNumber;

export const selectIsSavedCart = (state: RootState): boolean => state.cart.isSavedCart;

export const selectAllCartAttachmentsIds = (state: RootState): Array<string> => {
  return state.cart.products.reduce<Array<string>>((acc, item) => {
    item.attachments.forEach((current) => {
      acc.push(current.id);
    });
    return acc;
  }, []);
};

export const selectCartHasAttachments = createSelector(selectAllCartAttachmentsIds, (ids) => ids.length > 0);

export const selectCartProductsRequestIds = createSelector(selectCartProducts, (products) => {
  return products.reduce<Array<number>>((acc, item) => {
    if (item.cartRequestItemId && !acc.includes(item.cartRequestItemId)) {
      acc.push(item.cartRequestItemId);
    }
    return acc;
  }, []);
});

export const selectSendQuoteByEmail = (state: RootState): boolean => {
  return state.cart.sendQuoteByEmail;
};

export const selectLastInvoicedUnitPrice =
  (sku: string) =>
  (state: RootState): IHistoricalPrice | undefined => {
    return state.cart.lastUnitPrices.lastInvoicedUnitPrice[sku];
  };

export const selectLastQuotedUnitPrice =
  (sku: string) =>
  (state: RootState): IHistoricalPrice | undefined => {
    return state.cart.lastUnitPrices.lastQuotedUnitPrice[sku];
  };

export const selectCartLogs = (state: RootState): Log[] => {
  return state.cart.logs;
};
