import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import env from 'config';
import classNames from 'classnames';
import { Button, InputNumber, Space, Tooltip } from 'antd';
import { EditOutlined, InfoCircleOutlined, SwapOutlined } from '@ant-design/icons';
import ProductWarning from '../../../ProductTable/components/ProductWarning';
import { DiscountError, DiscountSource, DiscountType } from '../../../../shared/Discount/Discount.types';
import { Popover, ProductPrice, Quantity, SupplierPrice, Text } from '../../../../shared';
import { useIsMobile, useIsTabletSizes } from '../../../../../utils/styles.utils';
import { getSupplierUnitPrice, renderUnitLabel } from '../../../../../utils/cart.utils';
import {
  isPriceEditPreventedByOfferType,
  isValidNumber,
  percentageParser,
  priceParser,
  quantityParser,
  priceToCent,
  centToPrice,
} from '../../../../../utils';
import {
  selectIsCustomerMode,
  setReplacementModalProductIndex,
  setReplacementModalQuantity,
  setReplacementModalVisible,
  setReplacementModalSku,
} from '../../../../../store/products';
import { sendMessage } from '../../../../../store/messages';
import { selectSelectedGroupsCustomerIdentifiers } from '../../../../../store/customers';
import { ICustomerProductInfo } from '../../../../../store/cart/types';
import {
  addNewTruckToProduct,
  changeCustomerProductAvailableQuantity,
  changeCustomerProductSelectedQuantity,
  changeProductAvailableQuantity,
  changeProductPrice,
  decreaseQuantity,
  increaseQuantity,
  recalculateProductPrices,
  selectLastInvoicedUnitPrice,
  selectLastQuotedUnitPrice,
  setQuantity,
  updateProductField,
  updateProductFields,
} from '../../../../../store/cart';
import { BILLING_TYPE, ICartRowInfoProps } from './types';
import { LastPrice } from './components/LastPrice';
import { SupplierPriceModal } from './components';
import styles from './CartRowInfo.module.scss';

const CartRowInfo: FC<ICartRowInfoProps> = (props: ICartRowInfoProps) => {
  const { product, productIndex, displayHistoricalPriceModal, billingType = BILLING_TYPE.SINGLE } = props;
  const {
    availableSelectedQuantity,
    sku,
    quantity,
    price,
    offerId,
    productId,
    variantId,
    offerType,
    quantityIncrement,
    unit,
    offerPrice,
    totalPrice,
    rpd,
    cvo = 0,
    minimumOrderable,
    priceTotalWithTaxes,
    stock,
    taxes,
    enabled,
    discountType,
    discountValue,
    discountSource,
    supplierPrice,
    flags,
    customerProductInfo,
    truckSplitInfo,
    enforcedPrice,
    ignoreStock,
  } = product;

  const { supplierUnitPrice: initialSupplierUnitPrice, initialPrice } = price;
  const offerPriceValue = Number((offerPrice / 100).toFixed(3));
  const [prevValidOfferPriceValue] = useState(offerPriceValue);
  const [inputPrice, setInputPrice] = useState(offerPriceValue.toString());
  const [marginAmount, setMarginAmount] = useState(0);
  const [marginPercentage, setMarginPercentage] = useState(0);
  const lastInvoicedPrice = useSelector(selectLastInvoicedUnitPrice(sku));
  const lastQuotedPrice = useSelector(selectLastQuotedUnitPrice(sku));
  const [originalPrice] = useState(initialPrice ?? price.price);
  /**
   * If price is enforced, it means it was entered manually and should not be overwritten by discount
   */
  const [isPriceEnforced, setPriceEnforced] = useState(enforcedPrice ?? false);
  const [forcedPriceErrorMessage, setForcedPriceErrorMessage] = useState('');
  const [isWarningMessageVisible, setIsWarningMessageVisible] = useState(false);
  const isCustomerMode = useSelector(selectIsCustomerMode);
  const selectedGroupsCustomerIdentifiers = useSelector(selectSelectedGroupsCustomerIdentifiers);
  const [isVisibleEditSupplierPriceModal, setIsVisibleEditSupplierPriceModal] = useState(false);
  const supplierUnitPrice = getSupplierUnitPrice(product);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isTabletSizes = useIsTabletSizes();
  const [minimumCartOrderable, setMinimumCartOrderable] = useState(
    minimumOrderable > 1 ? minimumOrderable / quantityIncrement : 1,
  );
  const [shouldValidate, setShouldValidate] = useState(false);

  const updatePriceEnforced = (value: boolean) => {
    const payload = {
      identifier: productIndex,
      values: {
        enforcedPrice: value,
        ...(value && {
          discountValue: 0,
          discountType: undefined,
          discountSource: undefined,
        }),
      },
    };
    dispatch(updateProductFields(payload));
    setPriceEnforced(value);
  };

  const totalPriceDisplay = useMemo(() => {
    return <ProductPrice price={totalPrice - cvo} isProduct={false} />;
  }, [totalPrice, cvo]);

  /**
   * Variable is used to determine if the product has a discount
   */

  const hasDiscount = useMemo(() => {
    return discountValue !== 0 && discountValue !== undefined;
  }, [discountValue]);

  /**
   * Calculate margin amount and margin percentage based on input price
   */

  useEffect(() => {
    const inputPriceCent = priceToCent(inputPrice);
    const margin = (inputPriceCent - supplierUnitPrice) * quantity * quantityIncrement;
    const marginPercent = margin / (inputPriceCent * quantity * quantityIncrement);
    setMarginAmount(margin);
    setMarginPercentage(marginPercent * 100);
  }, [inputPrice, quantity, quantityIncrement, supplierUnitPrice]);

  useEffect(() => {
    if (typeof availableSelectedQuantity !== 'undefined' && availableSelectedQuantity === 0) {
      setMinimumCartOrderable(minimumOrderable / quantityIncrement);
    } else {
      setMinimumCartOrderable(minimumOrderable > 1 ? minimumOrderable / quantityIncrement : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSelectedQuantity]);

  const isInputPriceValid = useMemo(() => {
    const inputPriceNbr = priceToCent(inputPrice);
    if (isValidNumber(inputPriceNbr) && inputPriceNbr >= supplierUnitPrice) {
      if (hasDiscount) {
        dispatch(updateProductField({ identifier: productIndex, field: 'discountError', value: DiscountError.FALSE }));
      }
      setForcedPriceErrorMessage('');
      return true;
    }

    if (hasDiscount) {
      dispatch(updateProductField({ identifier: productIndex, field: 'discountError', value: DiscountError.HIGH }));
    }
    setForcedPriceErrorMessage(
      t('common.supplierPricePriceValidationMax', { price: priceParser(supplierUnitPrice) }).toString(),
    );

    return false;
  }, [inputPrice, supplierUnitPrice, hasDiscount]);

  /**
   * Whenever the inputPrice is changed we want to save it to redux and recalculate totals
   */
  useEffect(() => {
    dispatch(
      changeProductPrice({
        id: offerId,
        price: discountType === DiscountType.AMOUNT_ONE_SHOT ? originalPrice : priceToCent(inputPrice),
      }),
    );

    dispatch(recalculateProductPrices(offerId));
  }, [inputPrice]);

  const renderUnit = useMemo(
    () => renderUnitLabel({ quantity, quantityIncrement, unit }),
    [quantity, quantityIncrement, unit],
  );

  const onChangeOfferPrice = (value: number) => {
    setShouldValidate(false);
    updatePriceEnforced(true);
    setInputPrice(value);
  };

  const validateOfferPrice = (value: string) => {
    const newOfferPriceCent = priceToCent(Number(value.replace(',', '.')));

    if (!isValidNumber(newOfferPriceCent) || newOfferPriceCent < supplierUnitPrice) {
      setInputPrice(prevValidOfferPriceValue);
      setShouldValidate(true);
      return;
    }
    setInputPrice(centToPrice(newOfferPriceCent));
    setShouldValidate(true);
  };

  const getMargin = () => {
    if (!supplierUnitPrice || supplierUnitPrice === 0) {
      return t('common.notAvailable');
    }

    return `${priceParser(marginAmount)} (${percentageParser(marginPercentage)})`;
  };

  useEffect(() => {
    if (!isInputPriceValid && shouldValidate) {
      dispatch(sendMessage({ message: t('common.inputPriceInvalid'), type: 'error' }));
    }
  }, [ isInputPriceValid, shouldValidate]);

  const addTruck = () => {
    if (product.truckSplitInfo && product.conditioningQuantity) {
      const lastAdded = Number(Object.keys(product.truckSplitInfo).slice(-1));
      const lastTruckNumber = Object.values(product.truckSplitInfo).slice(-1)[0];
      const trucksplit: any = {};
      trucksplit[lastAdded + 1] = {
        truckNumber: lastTruckNumber ? lastTruckNumber.truckNumber + 1 : 0,
        totalTruckAvailable: quantityIncrement / product.conditioningQuantity,
        customerProductInfo: selectedGroupsCustomerIdentifiers.reduce(
          (acc, customer) => ({
            ...acc,
            [customer.customerIdentifier]: {
              selectedQuantity: 0,
              availableCustomerQuantity: quantityIncrement / product.conditioningQuantity!,
              customerIdentifier: customer.customerIdentifier,
            },
          }),
          {},
        ),
      };

      const newTruckAmount = Object.assign(trucksplit, product.truckSplitInfo);
      dispatch(
        addNewTruckToProduct({
          id: offerId,
          newTruckValue: newTruckAmount,
          availableSelectedQt: product.availableSelectedQuantity + quantityIncrement / product.conditioningQuantity,
        }),
      );
      dispatch(increaseQuantity(offerId));
      dispatch(recalculateProductPrices(offerId));
    }
  };

  const calculateProductAvailableQuantity = (isIncrease?: boolean, resetTotalQuantity?: boolean, qt?: number) => {
    if (qt) {
      return qt * quantityIncrement;
    }
    if (isIncrease) {
      return (resetTotalQuantity ? quantity * quantityIncrement : availableSelectedQuantity) + quantityIncrement;
    }
    if (!isIncrease) {
      return (resetTotalQuantity ? quantity * quantityIncrement : availableSelectedQuantity) - quantityIncrement;
    }
    return 0;
  };

  const calculateCustomerAvailableQuantity = (
    cCustomerInfo: ICustomerProductInfo,
    isIncrease?: boolean,
    qt?: number,
  ) => {
    if (qt) {
      return qt * quantityIncrement;
    }
    if (isIncrease) {
      return cCustomerInfo.availableCustomerQuantity + quantityIncrement;
    }
    if (!isIncrease) {
      return cCustomerInfo.availableCustomerQuantity - quantityIncrement;
    }
    return 0;
  };

  const recalculateAvailableProductQuantity = (
    isIncrease?: boolean,
    resetCustomerSelectedQuantity = false,
    qt?: number,
  ) => {
    if (customerProductInfo) {
      Object.entries(customerProductInfo).forEach(([_key, value]) => {
        const cAvailableQuantity = calculateCustomerAvailableQuantity(value, isIncrease, qt);
        dispatch(
          changeCustomerProductAvailableQuantity({
            productId: offerId,
            customerAvailableQuantity: cAvailableQuantity,
            customerIdentifier: value.customerIdentifier,
            ...(!!qt && { productSelectedQuantity: 0 }),
          }),
        );

        if (resetCustomerSelectedQuantity) {
          dispatch(
            changeCustomerProductSelectedQuantity({
              productId: offerId,
              customerIdentifier: value.customerIdentifier,
              productQuantity: 0,
            }),
          );
        }
      });
    }

    const productAvailableVal = calculateProductAvailableQuantity(isIncrease, resetCustomerSelectedQuantity, qt);

    dispatch(
      changeProductAvailableQuantity({
        offerId: offerId,
        quantity: productAvailableVal,
      }),
    );
  };

  /**
   * Function is used to trigger recalculate product prices on quantity change
   */
  function refreshPrice() {
    /**
     * If discount is set manually we will need to overwrite it with the offer discount
     */
    if (discountSource === DiscountSource.MANUAL) {
      dispatch(updateProductField({ identifier: productIndex, field: 'discountSource', value: DiscountSource.OFFER }));
    }
    dispatch(recalculateProductPrices(offerId));
  }

  const onChangeProductQuantity = (value: number) => {
    if (ignoreStock || stock / quantityIncrement >= value) {
      if (billingType === BILLING_TYPE.MULTIPLE) {
        recalculateAvailableProductQuantity(false, false, value);
      }
      dispatch(setQuantity({ id: offerId, quantity: value }));
      refreshPrice();
    }
  };

  /**
   * updated local state of price enforced when the value is changed in redux
   */

  useEffect(() => {
    setPriceEnforced(enforcedPrice ?? false);
  }, [enforcedPrice]);

  /**
   * Effect used for discounts
   * If any discount value is changed in redux, we need to update the input price
   */
  useEffect(() => {
     // If the price is enforced, we do not want to update the price or apply discount
    if (isPriceEnforced) {
      return;
    }

    // If the discount value is not set, we want to display the original price
    if (!discountValue) {
    //  setInputPrice((inputPrice / 100).toFixed(2));
      return;
    }

     // If the discount value is set, we want to apply the discount and reset PriceEnforced
    updatePriceEnforced(false);
    switch (discountType) {
      case DiscountType.PERCENTAGE:
        setInputPrice(((originalPrice * (1 - discountValue / 100 / 100)) / 100).toFixed(2));
        break;
      default:
        setInputPrice(((originalPrice - discountValue) / 100).toFixed(2));
        break;
    }

  }, [discountValue, discountType]);

  const priceEditPrevent = () => {
    return isPriceEditPreventedByOfferType(offerType, flags) ? (
      <Text type="subtitle-gray3">{priceParser(offerPriceValue * 100)}&nbsp;</Text>
    ) : (
      <InputNumber
        precision={2}
        decimalSeparator=","
        controls={false}
        min={0}
        onChange={onChangeOfferPrice}
        onBlur={(e) => validateOfferPrice(e.target.value)}
        status={!isInputPriceValid ? 'error' : undefined}
        disabled={!!discountValue}
        value={inputPrice}
        className={classNames(styles.input, styles.priceInput)}
      />
    );
  };

  const onIncreaseProductQuantity = () => {
    if (billingType === BILLING_TYPE.MULTIPLE) {
      recalculateAvailableProductQuantity(true, true);
    }
    dispatch(increaseQuantity(offerId));
    refreshPrice();
  };

  const onDecreaseProductQuantity = () => {
    if (billingType === BILLING_TYPE.MULTIPLE) {
      recalculateAvailableProductQuantity(false, true);
    }
    dispatch(decreaseQuantity(offerId));
    refreshPrice();
  };

  const handleEditSupplierPrice = () => {
    setIsVisibleEditSupplierPriceModal(true);
  };

  const handleShowModal = (value: boolean) => {
    setIsVisibleEditSupplierPriceModal(value);
  };

  const vatValue = taxes.vat * 100;

  const cvoValue = priceParser(cvo);
  const rpdValue = priceParser(rpd);
  const priceTotalWithTaxesValue = priceParser(priceTotalWithTaxes);
  const parsedQuantity = quantityParser(quantity, quantityIncrement);
  const formattedQuantity =
    typeof parsedQuantity === 'number' ? parsedQuantity.toLocaleString('fr-FR') : parsedQuantity.replace('.', ',');
  const quantityAndUnit = `${formattedQuantity} ${renderUnit}`;
  const maximum = ignoreStock ? 1e6 : Math.floor(stock / quantityIncrement);

  const handleVisibleChange = () => {
    setIsWarningMessageVisible(!isWarningMessageVisible);
  };

  const showWarningMessage = () => {
    setIsWarningMessageVisible(true);
  };

  const handleOpenOffersModal = () => {
    dispatch(setReplacementModalSku(sku));
    dispatch(setReplacementModalVisible(true));
    dispatch(setReplacementModalQuantity(quantity ?? 0));
    dispatch(setReplacementModalProductIndex(productIndex));
  };

  const quantityInput = (onClick: () => void) => (
    <Quantity
      maximum={maximum}
      value={quantity}
      disabled={!enabled}
      onCLick={onClick}
      minimum={minimumCartOrderable}
      subTitle={quantityAndUnit}
      onChange={onChangeProductQuantity}
      onIncrease={onIncreaseProductQuantity}
      onDecrease={onDecreaseProductQuantity}
      emptyValue={1}
      ignoreStock={ignoreStock}
    />
  );

  const quantityColumn = () => {
    if (billingType === BILLING_TYPE.MULTIPLE) {
      if (!truckSplitInfo) {
        return (
          <Popover
            className={styles.popover}
            title="Ce changement remettra à zéro la quantité de produit de chaque client"
            trigger="click"
            open={isWarningMessageVisible}
            placement="bottomLeft"
            onOpenChange={handleVisibleChange}
          >
            {quantityInput(showWarningMessage)}
          </Popover>
        );
      }
      return <Button onClick={addTruck}>Ajouter un camion</Button>;
    }
    if (billingType === BILLING_TYPE.SINGLE) {
      return quantityInput(() => null);
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.columnLeft}>
        <div className={styles.column__element}>
          {isCustomerMode && !!env('enablePriceMargin') && (
            <Text type="subtitle-gray1">
              {t('common.margin')}: {getMargin()}
            </Text>
          )}
          {!!env('enableLastPrice') && (
            <LastPrice
              sku={sku}
              lastQuotedPrice={lastQuotedPrice}
              lastInvoicedPrice={lastInvoicedPrice}
              displayHistoricalPriceModal={displayHistoricalPriceModal}
            />
          )}
        </div>
      </div>

      <div className={styles.columnRight}>
        <div className={styles.column}>
          <div className={styles.column__element}>{quantityColumn()}</div>
        </div>
        <div className={styles.column}>
          <div className={styles.column__element}>
            <Text type="subtitle-gray1" className={styles.column__element__prix_initial}>
              {t('common.initialNetPrice')}
            </Text>
            <ProductPrice
              initialPrice={price.initialPrice}
              discountType={discountType}
              discountValue={discountValue}
              /** If the price is enforced, we display the initial price to not show any discount */
              price={!isPriceEnforced ? Number((Number(inputPrice) * 100).toFixed(2)) : price.initialPrice!}
              unit={unit}
              isProduct={false}
            />
          </div>
          {isMobile ? (
            <div className={`${styles.column__element} ${styles.column__element_group}`}>
              <Text type="subtitle-gray1">
                {!!discountValue && (
                  <Tooltip title={t('common.blockedPrice')}>
                    <span className={styles.blockedPriceTip}>
                      <InfoCircleOutlined color={'black'} />
                    </span>
                  </Tooltip>
                )}
                {forcedPriceErrorMessage && (
                  <Tooltip title={forcedPriceErrorMessage}>
                    <span className={styles.incorrectForcedPrice}>
                      <InfoCircleOutlined color={'red'} />
                    </span>
                  </Tooltip>
                )}
                {t('common.forcedNetPrice')}
              </Text>
              <InputNumber
                precision={2}
                decimalSeparator=","
                controls={false}
                min={0}
                onChange={onChangeOfferPrice}
                onBlur={(e) => validateOfferPrice(e.target.value)}
                status={!isInputPriceValid ? 'error' : undefined}
                value={inputPrice}
                className={styles.input}
              />
            </div>
          ) : (
            !!cvo && (
              <div className={styles.column__element}>
                <Text type="subtitle-gray5">{t('common.ofWhichCvo')}</Text>
                <Text type="subtitle-gray3">{priceParser(taxes.cvo)}</Text>
              </div>
            )
          )}
        </div>

        {isCustomerMode && !!env('enablePriceMargin') && (
          <div className={styles.column}>
            <div className={styles.column__element}>
              <Text type="subtitle-gray1">{t('common.buyingPrice')}</Text>
              <Space direction="horizontal" size={0} align="start">
                <SupplierPrice supplierUnitPrice={initialSupplierUnitPrice} supplierPrice={supplierPrice} />
                <Button
                  ghost
                  onClick={handleEditSupplierPrice}
                  icon={<EditOutlined />}
                  className={`${styles.iconButton}`}
                />
                <Text type="subtitle-gray3" className={styles.conditioning}>
                  / {unit.singular}
                </Text>
              </Space>
            </div>
          </div>
        )}

        <Space align="start">
          <div className={styles.column}>
            {isMobile ? (
              !!cvo && (
                <div className={styles.column__element}>
                  <Text type="subtitle-gray1">{t('common.ofWhichCvo')}</Text>
                  <Text type="subtitle-gray1">{cvoValue}</Text>
                </div>
              )
            ) : (
              <div className={`${styles.column__element} ${styles.column__element_group}`}>
                <Text type="subtitle-gray1">
                  {!!discountValue && (
                    <Tooltip title={t('common.blockedPrice')}>
                      <span className={styles.blockedPriceTip}>
                        <InfoCircleOutlined color={'black'} />
                      </span>
                    </Tooltip>
                  )}
                  {forcedPriceErrorMessage && (
                    <Tooltip title={forcedPriceErrorMessage}>
                      <span className={styles.incorrectForcedPrice}>
                        <InfoCircleOutlined color={'red'} />
                      </span>
                    </Tooltip>
                  )}
                  {t('common.forcedNetPrice')}
                </Text>
                <div className={styles.forcedPriceContainer}>
                  {priceEditPrevent()}
                  <Text type="subtitle-gray3" className={styles.conditioning}>
                    / {unit.singular}
                  </Text>
                </div>
              </div>
            )}
            {!!rpd && (
              <div className={styles.column__element}>
                <Text type="subtitle-gray5">{t('common.rpdTax')}</Text>
                <Text type="subtitle-gray3">{priceParser(taxes.rpd)}</Text>
              </div>
            )}
          </div>
          <Tooltip title={t('cart.replaceOffer')}>
            <Button
              className={styles.replaceOfferButton}
              type="text"
              shape="circle"
              onClick={handleOpenOffersModal}
              icon={<SwapOutlined />}
            />
          </Tooltip>
        </Space>
        <div className={styles.column}>
          <div className={styles.column__element}>
            <Text type="subtitle-gray1">{t('common.netTotal')}</Text>
            <Text type="subtitle-gray3">{totalPriceDisplay}</Text>
          </div>
          {!!rpd && (
            <div className={styles.column__element}>
              <Text type="subtitle-gray5">{t('common.totalRpdTax')}</Text>
              <Text type="subtitle-gray3">{rpdValue}</Text>
            </div>
          )}
          {!!cvo && (
            <div className={styles.column__element}>
              <Text type="subtitle-gray5">{t('common.ofWhichCvo')}</Text>
              <Text type="subtitle-gray3">{cvoValue}</Text>
            </div>
          )}
          {isTabletSizes && (
            <div className={styles.column__element}>
              <Text type="subtitle-gray5" className={styles.centered}>
                {t('common.totalPrice')}
              </Text>
              <Text type="subtitle-gray3">
                <span className={styles.block}>{priceTotalWithTaxesValue}</span>
                <span className={styles.notBold}>
                  {vatValue}% {t('common.vat')}
                </span>
              </Text>
            </div>
          )}
        </div>
        {!isTabletSizes && (
          <div className={styles.column}>
            <div className={`${styles.column__element} ${styles.column__element_group}`}>
              <Text type="subtitle-gray1" className={styles.centered}>
                {t('common.totalPrice')}
              </Text>
              <Text type="subtitle-gray3">
                <span className={styles.block}>{priceTotalWithTaxesValue}</span>
                <span className={styles.notBold}>
                  {vatValue}% {t('common.vat')}
                </span>
              </Text>
            </div>
          </div>
        )}
        <ProductWarning productId={productId} variantId={variantId} offerId={offerId} />
      </div>
      <SupplierPriceModal visible={isVisibleEditSupplierPriceModal} showModal={handleShowModal} product={product} />
    </div>
  );
};
export default CartRowInfo;
