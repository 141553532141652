import { useAppSelector } from 'store/store';
import { selectCartProductByIndex, updateProductFields } from 'store/cart';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ADButton from 'antd/lib/button';
import { Badge, Popover, Tooltip } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';
import { DiscountSource, typeToDiscountMap } from '../Discount/Discount.types';
import Discount from '../Discount';

interface ProductDiscountProps {
  productIndex: number;
}
export const ProductDiscount: FunctionComponent<ProductDiscountProps> = ({ productIndex }) => {
  const [discountFormVisible, setDiscountFormVisible] = useState(false);
  const product = useAppSelector(selectCartProductByIndex(productIndex));
  const {
    keepPrice,
    discountOffers,
    quantity,
    price,
    discountValue,
    discountType,
    discountError,
    discountSource,
    enforcedPrice,
  } = product;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const badgeContent = discountValue ? `${discountValue / 100}${discountType === 'PERCENTAGE' ? '%' : '€'}` : '';
  const discountContent = useMemo(() => {
    return (
      <Discount
        price={price}
        productIndex={productIndex}
        discountValue={discountValue}
        discountType={discountType}
        discountError={discountError}
        discountSource={discountSource}
        enforcedPrice={enforcedPrice}
      />
    );
  }, [price, productIndex, discountValue, discountType, discountError, discountSource, enforcedPrice]);

  const discountOfferFound = useMemo(() => {
    return discountOffers?.find((discountOffer) => quantity >= discountOffer.MinimumQuantity);
  }, [discountOffers, quantity]);

  /**
   * When changing the quantity we should check if the product has a discount offer and update the discount value
   * and type matched with the offer
   */

  useEffect(() => {
    /**
     * If priced is enforced we do not check for discount
     */
    if (enforcedPrice) return;
    if (discountSource === DiscountSource.MANUAL) return;
    /**
     * if price is kept by variable we do not check for discount or update the discount based on quantity
     */
    if (keepPrice) return;
    if (discountOfferFound) {
      const discountTypeEnum = typeToDiscountMap[discountOfferFound.Type];
      /**
       * Updating new discountValue, discountType and discountSource based on found offer discount
       */
      dispatch(
        updateProductFields({
          identifier: productIndex,
          values: {
            discountValue: discountOfferFound.Value,
            discountType: discountTypeEnum,
            discountSource: DiscountSource.OFFER,
          },
        }),
      );
    } else {
      dispatch(
        updateProductFields({
          identifier: productIndex,
          values: {
            discountValue: undefined,
            discountType: undefined,
            discountSource: undefined,
          },
        }),
      );
    }
  }, [quantity, discountOfferFound, discountSource, dispatch, enforcedPrice, keepPrice, productIndex]);

  /**
   * Find discount offer based on quantity
   */

  return (
    <Tooltip title={t('cart.discountTooltip')}>
      <Popover content={discountContent} popupVisible={discountFormVisible} trigger={'click'} placement="left">
        <Badge count={badgeContent}>
          <ADButton
            icon={<PercentageOutlined />}
            type="text"
            shape="circle"
            onClick={() => setDiscountFormVisible(true)}
          />
        </Badge>
      </Popover>
    </Tooltip>
  );
};
export default ProductDiscount;
