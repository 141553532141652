import { useAppDispatch, useAppSelector } from 'store/store';
import { selectCartProductByIndex, toggleCartProductEnabled, updateProductField } from 'store/cart';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import env from 'config';
import ADButton from 'antd/lib/button';
import { Badge, Checkbox, Popover, Tooltip } from 'antd';
import { EllipsisOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styles from './ProductEdit.module.scss';

interface ProductEditProps {
  productIndex: number;
}

export const ProductEdit: FunctionComponent<ProductEditProps> = ({ productIndex }) => {
  const [formVisible, setFormVisible] = useState(false);
  const product = useAppSelector(selectCartProductByIndex(productIndex));
  const { offerId, enabled, qtyEditable, removable } = product;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onChangeToggleEnabledProduct = useCallback(() => {
    dispatch(toggleCartProductEnabled(offerId));
  }, [dispatch, offerId]);

  const toggleQtyEditableCheckbox = useCallback(() => {
    dispatch(
      updateProductField({
        field: 'qtyEditable',
        identifier: productIndex,
        value: !qtyEditable,
      }),
    );
  }, [dispatch, productIndex, qtyEditable]);
  const toggleRemovableCheckbox = useCallback(() => {
    dispatch(
      updateProductField({
        field: 'removable',
        identifier: productIndex,
        value: !removable,
      }),
    );
  }, [dispatch, productIndex, removable]);

  const qtyEditableCheckbox = useMemo(() => {
    return (
      <div className={styles.cartHeaderRightSide__qtyEditable}>
        <Checkbox
          checked={qtyEditable}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            toggleQtyEditableCheckbox();
            e.stopPropagation();
          }}
        >
          {t('cart.qtyEditableCheckboxLabel')}
          <Tooltip title={t('cart.qtyEditableCheckboxTooltip')}>
            <InfoCircleOutlined className={styles.infoPoint} />
          </Tooltip>
        </Checkbox>
      </div>
    );
  }, [qtyEditable, t, toggleQtyEditableCheckbox]);

  const removableCheckbox = useMemo(() => {
    return (
      <div className={styles.cartHeaderRightSide__removable}>
        <Checkbox
          checked={removable}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            toggleRemovableCheckbox();
            e.stopPropagation();
          }}
        >
          {t('cart.removableCheckboxLabel')}
          <Tooltip title={t('cart.removableCheckboxTooltip')}>
            <InfoCircleOutlined className={styles.infoPoint} />
          </Tooltip>
        </Checkbox>
      </div>
    );
  }, [removable, t, toggleRemovableCheckbox]);
  const inclusiveLabel = `${enabled ? t('common.includedArticle') : t('common.excludedArticle')}`;

  const content = useMemo(() => {
    return (
      <>
        <div className={styles.cartHeaderRightSide__article}>
          <Checkbox
            checked={enabled}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              onChangeToggleEnabledProduct();
              e.stopPropagation();
            }}
          >
            {inclusiveLabel}
            <Tooltip title={t('cart.articleInclusive')}>
              <InfoCircleOutlined className={styles.infoPoint} />
            </Tooltip>
          </Checkbox>
        </div>
        {env('enableCustomerQuotationUpdate') && removableCheckbox}
        {env('enableCustomerQuotationUpdate') && qtyEditableCheckbox}
      </>
    );
  }, [enabled, inclusiveLabel, onChangeToggleEnabledProduct, qtyEditableCheckbox, removableCheckbox, t]);

  return (
    <Popover content={content} popupVisible={formVisible} trigger={'click'} placement="left">
      <Badge count={0}>
        <ADButton icon={<EllipsisOutlined />} type="text" shape="circle" onClick={() => setFormVisible(true)} />
      </Badge>
    </Popover>
  );
};

export default ProductEdit;
