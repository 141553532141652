import { isSavedCartGroupCustomer } from 'utils/savedCart.utils';
import { SavedCartTable } from 'types/savedCart';
import { SavedCartRestoredState, SavedCartState, ISavedCartFilters, ISavedCartStates } from 'store/savedCart/types';
import { RootState } from 'store/rootReducer';
import { createSelector } from '@reduxjs/toolkit';

export const selectSavedCartStates = (state: RootState): ISavedCartStates => state.savedCart.states;

export const selectIsLoadingSavedCart = (state: RootState): boolean => state.savedCart.isLoading;

export const selectTotalSavedCart = (state: RootState): number => state.savedCart.total;

export const selectLimitSavedCart = (state: RootState): number => state.savedCart.limit;

export const selectPageSavedCart = (state: RootState): number => state.savedCart.page;

export const selectSavedCartsData = (state: RootState): SavedCartRestoredState[] => state.savedCart.data;

export const selectShowModal = (state: RootState): boolean => state.savedCart.restore.showModal;

export const selectProductErrors = (state: RootState): SavedCartState['restore']['productErrors'] =>
  state.savedCart.restore.productErrors;

export const selectDeliveryErrors = (state: RootState): SavedCartState['restore']['deliveryErrors'] =>
  state.savedCart.restore.deliveryErrors;

export const selectCurrentPage = (state: RootState): number => (state.savedCart.page - 1) * state.savedCart.limit;

export const selectRestoreErrors = createSelector(
  selectProductErrors,
  selectDeliveryErrors,
  (productErrors, deliveryErrors): Pick<SavedCartState['restore'], 'productErrors' | 'deliveryErrors'> => {
    return {
      productErrors,
      deliveryErrors,
    };
  },
);

export const selectSavedCarts = createSelector(selectSavedCartsData, (savedCarts): Array<SavedCartTable> => {
  return savedCarts.map<SavedCartTable>((item: SavedCartRestoredState) => {
    const {
      id,
      customers,
      cart: { products, atcCartNumber, cartRequestId },
      status,
      stepNumber,
      createdAt,
      submittedAt,
      convertedAt,
      refusedAt,
      sentToCustomerAt,
      updatedAt,
      expiredAt,
      expired,
      deletedAt,
      masterOrderId = null,
      saveCartPdfId = null,
      assignedTcName,
      assignedTcCode,
      archivedAt,
      isCartRequest,
      userId,
      addressId,
    } = item;
    let customerName = '';
    let customerId = '';
    let companyName = '';
    let customerEmail = '';

    if (isSavedCartGroupCustomer(customers)) {
      const { selectedGroupCustomers } = customers;
      const leader = selectedGroupCustomers.find((customer) => customer.leaderId === customer.userId);
      customerName = leader ? `${leader.firstName} ${leader.lastName}` : 'unknown';
      customerId = leader?.customerIdentifier ?? '';
      companyName = leader?.company ?? '';
    } else {
      const {
        selectedSearchedCustomer: { firstName, lastName, customerIdentifier, company, email },
      } = customers;
      customerName = `${firstName} ${lastName}`;
      customerId = customerIdentifier ?? '';
      companyName = company ?? '';
      customerEmail = email ?? '';
    }

    const totalAmount = products.reduce((acc, product) => {
      const total = (product.totalPrice || 0) - (product.cvo || 0);

      if (typeof product.enabled !== 'undefined' && !product.enabled) {
        return acc;
      }
      return acc + total;
    }, 0);

    return {
      id,
      customerName,
      customerIdentifier: customerId,
      company: companyName,
      email: customerEmail,
      stepNumber,
      status,
      createdAt,
      submittedAt,
      updatedAt,
      expiredAt,
      convertedAt,
      refusedAt,
      sentToCustomerAt,
      totalAmount,
      expired,
      deletedAt,
      archivedAt,
      saveCartPdfId,
      masterOrderId,
      atcCartNumber,
      assignedTcCode,
      assignedTcName,
      cartRequestId,
      isCartRequest,
      userId,
      addressId,
    };
  });
});

export const selectCartDetails =
  (cartId: string) =>
  (state: RootState): SavedCartRestoredState | undefined => {
    const {
      savedCart: { data },
    } = state;

    return data.find(({ id }) => id === cartId);
  };

export const selectFilters = (state: RootState): ISavedCartFilters => state.savedCart.filters;

export const selectExpiredAt = (state: RootState): string | null => state.savedCart.restore.expiredAt;

export const selectExpirationDays = (state: RootState): number | null => state.savedCart.restore.expirationDays;

export const selectExpirationHours = (state: RootState): number | null => state.savedCart.restore.expirationHours;

export const selectExpiration = createSelector(
  [selectExpiredAt, selectExpirationDays, selectExpirationHours],
  (expiredAt, expirationDays, expirationHours) => {
    return {
      expiredAt,
      expirationDays,
      expirationHours,
    };
  },
);

export const selectIsLoadingCartStatusCount = (state: RootState): boolean => state.savedCart.isLoadingCount;
