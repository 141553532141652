import { isFullMonth } from 'utils/date.utils';
import { createObjectId } from 'utils/common.utils';
import { isContainableObjectEmpty, isEmpty } from 'utils';
import { IPointListItem } from 'types/shippingFees';
import {
  IAlgoliaEquivalentProduct,
  IAlgoliaProduct,
  IAlgoliaSubstitutionProduct,
  IProductReplacement,
  IProductTaxes,
  Option,
  ParsedProductsForTable,
  ProductReplacement,
} from 'types/product';
import { IDiscount, IOfferPrice, IStorageOffer } from 'types/offer';
import { ProductDeliveryFormat } from 'types/format';
import { ICartRequestItem } from 'types/cartRequest';
import { INormalizedList, IShippingAddresses } from 'types';
import {
  IFetchProductsCustomerQuotesResponse,
  IFetchProductsMultiResponse,
  IFetchProductsResponseWithSearchType,
  IFetchProductsSingleResponse,
  IParsedProductItem,
  IProductsOffer,
  IProductsState,
  ProductSearchType,
} from 'store/products/types';
import { trackSplitInfoNormalizer } from 'store/products/normalizers';
import { MessageType } from 'store/messages/type';
import { ICartProductItem } from 'store/cart/types';
import { makeTaggedUnion, MemberType } from 'safety-match';
import moment, { Moment } from 'moment';
import i18next from 'i18next';
import {
  IProductAmountCustomerQuotationProps,
  IProductAmountProps,
} from 'components/product/ProductTable/components/ProductAmount/types';
import { config } from '../config';
import { DiscountSource, DiscountType, typeToDiscountMap } from '../components/shared/Discount/Discount.types';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import { PRODUCT_DELIVERY_FORMAT } from 'constants/format/delivery';

type CartProductPriceFields = Pick<
  ICartProductItem,
  'taxes' | 'quantity' | 'quantityIncrement' | 'offerPrice' | 'price' | 'discountType' | 'discountValue'
>;

export type IGetDeliveryDateInfo = {
  days?: number;
  startDate?: string;
  endDate?: string;
  delay?: {
    min?: number;
    max?: number;
  };
};

export const calculateProductUnitCVONoQtyIncrement = (product: CartProductPriceFields): number => {
  const { taxes } = product;
  return taxes.cvo || 0;
};

export const calculateProductUnitCVO = (product: CartProductPriceFields): number => {
  const { quantityIncrement } = product;

  return calculateProductUnitCVONoQtyIncrement(product) * quantityIncrement;
};

export const calculateProductCVO = (product: CartProductPriceFields): number => {
  const { quantity } = product;
  return calculateProductUnitCVO(product) * quantity;
};

export const calculateProductUnitRPDNoQtyIncrement = (product: CartProductPriceFields): number => {
  const { taxes } = product;
  return taxes.rpd || 0;
};

export const calculateProductUnitRPD = (product: CartProductPriceFields): number => {
  const { quantityIncrement } = product;

  return calculateProductUnitRPDNoQtyIncrement(product) * quantityIncrement;
};

export const calculateProductRPD = (product: CartProductPriceFields): number => {
  const { quantity } = product;
  return calculateProductUnitRPD(product) * quantity;
};

export const calculateProductPriceTotal = (product: CartProductPriceFields): number => {
  const { taxes, quantity, quantityIncrement, offerPrice, price, discountValue, discountType } = product;
  const productPrice = offerPrice || price.price;
  const rpd = taxes.rpd || 0;
  /**
   * Special case: if the discount is a one shot amount, we need to add the discount value to the total price
   * DiscountValue represents the discount value of 1 unit price
   */
  if (discountValue && discountType === DiscountType.AMOUNT_ONE_SHOT) {
    return (productPrice + rpd) * (quantity * quantityIncrement - 1) + (productPrice - discountValue + rpd);
  }
  /**
   * productPrice is already discounted
   */
  return (productPrice + rpd) * quantity * quantityIncrement;
};

export const calculateProductPriceTotalWithTaxes = (product: CartProductPriceFields): number => {
  const { taxes } = product;
  const total = calculateProductPriceTotal(product);
  const cvo = calculateProductCVO(product);

  return total * (taxes.vat + 1) - cvo;
};

export const getDeliveryAddress = (
  pointListItem: IPointListItem | undefined,
  shippingAddress: IShippingAddresses | undefined,
): string => {
  if (pointListItem) {
    return `${pointListItem.name} ( ${!!pointListItem.address.city && pointListItem.address.city}-
      ${!!pointListItem.address.zipCode && pointListItem.address.zipCode} )`;
  }
  if (shippingAddress) {
    return `${shippingAddress.townLabel} (${shippingAddress.postcodeLabel}) - ${shippingAddress.street}`;
  }
  return '';
};

export const getDeliveryDate = (
  deliveryInfo: IGetDeliveryDateInfo,
  format: ProductDeliveryFormat = PRODUCT_DELIVERY_FORMAT.DEFAULT,
): string => {
  const formatted = getDeliveryDateFormatted(deliveryInfo, format);

  if (typeof formatted === 'string') {
    return formatted;
  }

  switch (formatted.case) {
    case 'day':
      return formatted.day;
    case 'date':
      return formatted.date;
    case 'shipping':
      return formatted.day;
  }
};

export const getDeliveryDateFormatted = (
  deliveryInfo: IGetDeliveryDateInfo,
  format: ProductDeliveryFormat,
  fromDate?: Moment,
): { case: 'day' | 'date' | 'shipping'; day: string; date: string } | string => {
  const { days, delay, startDate, endDate } = deliveryInfo;

  if (!fromDate) {
    fromDate = moment().utc(true);
  }

  const datesFormat = CUSTOM_DATE_FORMAT.fullMonthWithDay;
  const fullMonthFormat = CUSTOM_DATE_FORMAT.fullMonth;

  // The utc is set to false to get the current date, otherwise the endDate 01-01-2022 59:59:59 will become 01-02-2022 02:00:00
  const momentStartDate = moment(startDate).utc(true).startOf('day');
  const momentEndDate = moment(endDate).utc(true).startOf('day');

  const startDateString = momentStartDate.format(datesFormat);
  const endDateString = momentEndDate.format(datesFormat);

  if (days) {
    const date = moment().utc(true).add({ days: days }).format(datesFormat);

    return {
      case: 'day',
      day: days > 1 ? format.days.plural.replace(/%s/, String(days)) : format.days.singular.replace(/%s/, String(days)),
      date: format.beforeDate.replace(/%s/, date),
    };
  }

  if (startDate && endDate) {
    // If the utc is set to true, must verify this method, otherwise will not calculate the correct fullMonth. It is possible to substract the utcOffset for the correct response
    const dateFormat = isFullMonth(momentStartDate, momentEndDate)
      ? format.betweenDatesFullMonth.replace(/%s/, momentStartDate.format(fullMonthFormat))
      : format.betweenDates.replace(/%s/, startDateString).replace(/%s/, endDateString);

    return {
      case: 'date',
      day: format.betweenDays
        .replace(/%s/, String(momentStartDate.diff(fromDate, 'days')))
        .replace(/%s/, String(momentEndDate.diff(fromDate, 'days'))),
      date: dateFormat,
    };
  }

  if (delay?.max && delay.min) {
    const minDelayDate = fromDate.add({ days: delay?.min }).format(datesFormat);
    const maxDelayDate = fromDate.add({ days: delay?.max }).format(datesFormat);

    let day: string;
    if (delay.min === delay.max) {
      day =
        delay.min > 1
          ? format.days.plural.replace(/%s/, String(delay.min))
          : format.days.singular.replace(/%s/, String(delay.min));
    } else {
      day = format.betweenDays.replace(/%s/, String(delay.min)).replace(/%s/, String(delay.max));
    }

    return {
      case: 'shipping',
      day,
      date: format.betweenDates.replace(/%s/, String(minDelayDate)).replace(/%s/, String(maxDelayDate)),
    };
  }

  return format.none;
};

export const getProductOfferByQuantity = (
  quantity: number,
  productOffer: Partial<IParsedProductItem> | undefined,
): IDiscount | undefined => {
  if (productOffer?.offer) {
    const offerPrices: { [key: number]: IOfferPrice } = {};

    productOffer.offer.Variants.forEach((offerDiscount) =>
      offerDiscount.OfferPrice.forEach((offerP) => {
        offerPrices[offerP.OfferId] = offerP;
      }),
    );
    let newDiscount = undefined;

    if (productOffer.offerId && offerPrices[productOffer.offerId].Discount?.length) {
      const offerList = offerPrices[productOffer.offerId].Discount.filter((offer) => quantity >= offer.MinimumQuantity);
      newDiscount = offerList.reduce(
        (prev, current) => {
          return prev.MinimumQuantity > current.MinimumQuantity ? prev : current;
        },
        {
          DiscountedPrice: 0,
          EndDate: '',
          Id: 0,
          MinimumQuantity: -1,
          ReservedForClub: false,
          StartDate: '',
          Type: 0,
          Value: 0,
        },
      );
    }
    if (newDiscount?.MinimumQuantity === -1) {
      return undefined;
    }
    return newDiscount;
  }
};

export const quantityParser = (quantity: number, quantityIncrement: number): string | number => {
  const totalQuantity = quantity * quantityIncrement;
  return totalQuantity % 1 !== 0 ? totalQuantity.toFixed(3) : totalQuantity;
};

export const isPriceEditPreventedByOfferType = (offerType: number | undefined, flags: string[] = []): boolean => {
  const preventedOfferTypes: Array<number> = (config?.noPriceEditOfferTypes ?? '')
    .split(',')
    .map((item) => Number(item))
    .filter((item) => !isNaN(item));

  const configFlag = (config?.noPriceEditFlag ?? '').toLowerCase().trim();

  if (!isNaN(Number(offerType)) && preventedOfferTypes.includes(Number(offerType))) {
    return true;
  }

  const lowerFlags = flags.map((item) => item.toLowerCase().trim()).filter((item) => item.length > 0);

  if (lowerFlags.includes(configFlag)) {
    return true;
  }

  return false;
};

export const renderOptions = (options: Option | undefined): string => {
  if (options) {
    const optionKeys = Object.keys(options);
    return optionKeys.length > 0 ? optionKeys.map((key) => `${key} : ${options[key]}`).join(', ') : '';
  } else return '';
};

export const toFixedTrunc = (priceValue: string): string => {
  const NUMERIC_REGEXP = /[-]?[\d]?[\d]+/g;
  const priceWithoutChars = priceValue.match(NUMERIC_REGEXP);

  if (priceWithoutChars && priceValue.length > priceWithoutChars[0].length) {
    if (priceWithoutChars.length > 1) {
      return `${priceWithoutChars[0]}.${priceWithoutChars[1]}`;
    }
    return `${priceWithoutChars[0]}.`;
  } else return priceValue;
};

export const editFloatNumber = (amount: number): string | number => {
  const decimalSplit = amount.toString().split('.');
  if (decimalSplit.length > 1) {
    return amount.toFixed(1);
  }
  return amount;
};

export const getParsedCartProductWithOfferId = (
  { products, offers }: { products: INormalizedList<IAlgoliaProduct>; offers: IProductsOffer },
  offerId: number,
  quantity: number,
  replacement: IProductReplacement | undefined,
  name: string,
  label: string | null = null,
  discountValue: number | null = null,
  discountType: DiscountType = DiscountType.PERCENTAGE,
  discountSource: DiscountSource | undefined = undefined,
  cartRequestItemId: number | null = null,
): ICartProductItem => {
  const offer = offers.list[offerId];
  const product = products[offer.variantId];
  const taxes = castTaxes(product.taxes.FR);
  const quantityIncrement = offer.quantityIncrement || 1;
  const taxesRpd = taxes.rpd || 0;
  const taxesCvo = taxes.cvo || 0;

  const rpd = taxesRpd * quantity * quantityIncrement;
  const cvo = taxesCvo * quantity * quantityIncrement;

  const totalPrice = (offer.price.price + taxesRpd) * quantity * quantityIncrement;
  const priceTotalWithTaxes = totalPrice * (taxes.vat + 1) - cvo;

  return {
    ...product,
    ...offer,
    name,
    label: label || product.name,
    cartRequestItemId,
    discountValue,
    discountType,
    discountSource,
    replacement,
    availableQuantity: 0,
    minimumOrderable: offer.minimumOrderable || 0,
    quantityIncrement,
    unit: {
      singular: product.unitSingular,
      plural: product.unitPlural,
    },
    offerPrice: offer.price.price,
    priceTotalWithTaxes,
    totalPrice,
    rpd,
    cvo,
    quantity,
    availableSelectedQuantity: 0,
    enabled: true,
    supplierPrice: null,
    attachments: [],
    cartProductId: createObjectId(),
  } as ICartProductItem;
};

export const getParsedCartProduct = (
  { products, offers }: { products: INormalizedList<IAlgoliaProduct>; offers: IProductsState['offers'] },
  offerId: number,
  quantity: number,
  parsedProduct: IParsedProductItem,
  cartRequestItem?: ICartRequestItem,
): ICartProductItem | null => {
  if (!offerId) {
    return null;
  }

  const label = cartRequestItem?.name;

  return getParsedCartProductWithOfferId(
    {
      products,
      offers,
    },
    offerId,
    quantity,
    parsedProduct.replacement,
    parsedProduct.name,
    label,
    null,
    DiscountType.PERCENTAGE,
    undefined,
    cartRequestItem?.id || null,
  );
};

export const getParsedProductsForTable = ({
  products,
  offers: offersParam,
}: ParsedProductsForTable): Array<IParsedProductItem> => {
  const { list, byProduct } = offersParam;

  const response = Object.values(products)
    .flatMap<IParsedProductItem>((product) => {
      const offers = byProduct[product.variantId]?.map((offerId) => list[offerId]);
      const substitutesIds = (product.substitutionProducts ?? []).map((sub) => sub.productId) ?? [];
      const equivalentsIds = (product.equivalentProducts ?? []).map((eq) => eq.productId) ?? [];
      const { substitutionProducts = [], equivalentProducts = [] } = product;

      if (offers?.length) {
        return offers.flatMap<IParsedProductItem>((offer: IStorageOffer) => {
          return {
            ...product,
            ...offer,
            metadata: product.meta,
            shippingMethods: offer.shippingMethods,
            disabled: (!offer.stock && !offer.ignoreStock) || !offer.shippingMethods?.length || isEmpty(offer.price),
            taxes: castTaxes(product.taxes.FR),
            unit: {
              singular: product.unitSingular,
              plural: product.unitPlural,
            },
            substitutionProducts,
            equivalentProducts,
            substitutionProductIds: substitutesIds,
            equivalentProductIds: equivalentsIds,
            discountOffers: offer.discountOffers,
            productTags: product.tags,
            campaign: offer.campaign,
          };
        });
      } else {
        return {
          bu: product.bu,
          amm: product.amm,
          sortingIndex: product.sortingIndex,
          variantId: product.variantId,
          productId: product.productId,
          name: product.name,
          conditioning: product.conditioning,
          conditioningQuantity: product.conditioningQuantity,
          sku: product.sku,
          metadata: product.meta,
          disabled: true,
          taxes: castTaxes(product.taxes.FR),
          unit: {
            singular: product.unitSingular,
            plural: product.unitPlural,
          },
          substitutionProducts,
          equivalentProducts,
          substitutionProductIds: substitutesIds,
          equivalentProductIds: equivalentsIds,
          purchasableOffline: false,
          online: false,
          flags: product.flags,
          productTags: product.tags,
        };
      }
    })
    .reduce(
      (productsGroupedByStock, product) => {
        product.shippingMethods = isContainableObjectEmpty(product.shippingMethods) ? [] : product.shippingMethods;
        product.disabled = product.disabled || isContainableObjectEmpty(product.shippingMethods);
        if (product.disabled) {
          productsGroupedByStock.productsWithoutStock.push(product);
        } else {
          productsGroupedByStock.productsWithStock.push(product);
        }

        return productsGroupedByStock;
      },
      { productsWithStock: <Array<IParsedProductItem>>[], productsWithoutStock: <Array<IParsedProductItem>>[] },
    );

  response.productsWithStock.sort(
    (a, b) => a.sortingIndex - b.sortingIndex || a.variantId - b.variantId || sortProductsByDelivery(a, b),
  );

  response.productsWithoutStock.sort((a, b) => a.sortingIndex - b.sortingIndex || a.variantId - b.variantId);

  return [...response.productsWithStock, ...response.productsWithoutStock];
};

export const sortProductsByDelivery = (productA: IParsedProductItem, productB: IParsedProductItem): number => {
  if (
    productA.startDate &&
    productA.endDate &&
    productB.startDate &&
    productB.endDate &&
    !productA.days &&
    !productB.days
  ) {
    const productAStartDate = moment(productA.startDate);
    const productAEndDate = moment(productB.endDate);
    const productBStartDate = moment(productB.startDate);
    const productBEndDate = moment(productB.endDate);
    if (productAStartDate.isSame(productBStartDate, 'date')) {
      return productAEndDate.isBefore(productBEndDate, 'date') ? -1 : 1;
    } else {
      return productAStartDate.isBefore(productBStartDate, 'date') ? -1 : 1;
    }
  }
  if (productA.days && productB.days) {
    return productA.days - productB.days;
  }
  if (productA.shippingMethods![0].minDelay && productB.shippingMethods![0].minDelay) {
    return productA.shippingMethods![0].minDelay === productB.shippingMethods![0].minDelay
      ? productA.shippingMethods![0].maxDelay! - productB.shippingMethods![0].maxDelay!
      : 0;
  }
  return 0;
};

type FindSupplierIdAndOfferTypeByOfferReturn = {
  supplierId: number;
  offerType: number;
  departureId: number;
};

export const findSupplierIdAndOfferTypeByOffer = (
  parsedProduct: ICartProductItem | null,
): FindSupplierIdAndOfferTypeByOfferReturn | undefined => {
  const variantByOfferId = parsedProduct?.offer?.Variants.map((variant) =>
    variant.OfferPrice.find((offerPrice) => offerPrice.OfferId === parsedProduct.offerId),
  );
  if (variantByOfferId) {
    const offerPrice = variantByOfferId.find((offer) => offer?.Offer.OfferId === parsedProduct?.offerId);
    if (offerPrice) {
      return {
        supplierId: offerPrice.Offer.SupplierId,
        offerType: offerPrice.Offer.Type,
        departureId: offerPrice.Offer.DepartureId,
      };
    }
  }
  return undefined;
};

export const getDefaultParsedProdcutItem = (
  product: IParsedProductItem,
): RequiredPartial<IParsedProductItem, 'quantityIncrement' | 'minimumOrderable' | 'stock'> => {
  const { quantityIncrement = 1, minimumOrderable = 0, stock = 0 } = product;

  return {
    ...product,
    quantityIncrement,
    minimumOrderable,
    stock,
  };
};

export const CartProductPayload = makeTaggedUnion({
  Some: (cartProduct: ICartProductItem, isGroup: boolean, isTruck: boolean) => ({
    cartProduct,
    isGroup,
    isTruck,
  }),
  Error: (err: MessageType) => err,
});

export const createNoStockCartProductPayload = (
  payload: IParsedProductItem,
  publicComment: string | null = null,
): ICartProductItem => {
  return {
    ...payload,
    /**
     * VariantID is unique just like offerID
     */
    // TODO: check this for no stock products
    cartRequestItemId: null,
    // TODO: check if I need to set offerId with variantId
    offerId: payload.variantId,
    quantity: 0,
    quantityIncrement: 0,
    offerPrice: 0,
    priceTotalWithTaxes: 0,
    totalPrice: 0,
    cvo: 0,
    rpd: 0,
    weight: 0,
    isTownRequired: false,
    availableSelectedQuantity: 0,
    availableQuantity: 0,
    noStock: true,
    enabled: true,
    publicComment: publicComment,
    label: null,
    discountType: DiscountType.PERCENTAGE,
    discountValue: 0,
    supplierPrice: null,
    attachments: [],
    cartProductId: createObjectId(),
  } as ICartProductItem;
};

export const createNoStockCartProduct = (
  isGroup: boolean,
  parsedProduct: IParsedProductItem,
  publicComment: string | undefined,
): MemberType<typeof CartProductPayload> => {
  const product = createNoStockCartProductPayload(parsedProduct, publicComment);
  return CartProductPayload.Some(product, isGroup, false);
};

export const showDiscount = (
  discountValue: number | string | undefined,
  discountType = DiscountType.PERCENTAGE,
): string => {
  if (discountValue) {
    return i18next.t('common.discount', {
      value: Number(discountValue) / 100,
      type: discountType === DiscountType.PERCENTAGE ? '%' : '€',
    });
  }
  return '';
};

export const createCartProduct = (
  isGroup: boolean,
  selectedGroupCustomerIds: number[] | null,
  selectedGroupsCustomerIdentifiers: Array<{ id: number; customerIdentifier: string }> | null,
  cartProduct: ICartProductItem,
  parsedProduct: IParsedProductItem,
  productQuantity: number,
  publicComment: string | null = null,
  override: Partial<ICartProductItem> = {},
): MemberType<typeof CartProductPayload> => {
  const { quantityIncrement, stock, price, options } = getDefaultParsedProdcutItem(parsedProduct);

  // TODO: check the offer mapping, verity it make sure the offers are mapped correctly
  const { supplierId, offerType, departureId } = findSupplierIdAndOfferTypeByOffer(cartProduct) ?? {};
  const initialPrice = price;
  const { conditioningQuantity, discountOffers, productTags } = parsedProduct;
  const { meta, ...rest } = cartProduct;
  /**
   * Attach the discount offers form best-offers to the product
   */
  const offerDiscounts = Object.values(discountOffers ?? []).sort((a, b) => b.MinimumQuantity - a.MinimumQuantity);
  const findDiscount = offerDiscounts.find((item) => productQuantity >= item.MinimumQuantity);
  const product: ICartProductItem = {
    ...rest,
    ...(meta && { metadata: meta }),
    discountOffers: offerDiscounts,
    availableQuantity: stock / quantityIncrement,
    initialPrice,
    supplierId,
    offerType,
    departureId,
    productOptions: renderOptions(options),
    enabled: true,
    publicComment: publicComment || '',
    qtyEditable: false,
    removable: false,
    productTags,
    ...(findDiscount && {
      discountType: typeToDiscountMap[findDiscount.Type],
      discountValue: findDiscount.Value,
      discountSource: DiscountSource.OFFER,
    }),
  };

  if (!isGroup) {
    return CartProductPayload.Some(Object.assign(product, override), isGroup, false);
  } else if (isGroup && !cartProduct?.isTruck && selectedGroupCustomerIds && selectedGroupsCustomerIdentifiers) {
    Object.assign(product, {
      availableSelectedQuantity: quantityIncrement * productQuantity,
      customerProductInfo: (selectedGroupsCustomerIdentifiers || []).reduce(
        (acc, customer) => ({
          ...acc,
          [customer.customerIdentifier]: {
            selectedQuantity: 0,
            availableCustomerQuantity: quantityIncrement * productQuantity,
            customerIdentifier: customer.customerIdentifier,
          },
        }),
        {},
      ),
    });

    return CartProductPayload.Some(Object.assign(product, override), isGroup, !!cartProduct?.isTruck);
  } else if (isGroup && cartProduct?.isTruck && selectedGroupCustomerIds && selectedGroupsCustomerIdentifiers) {
    const truckSplitInfo = trackSplitInfoNormalizer(selectedGroupCustomerIds, selectedGroupsCustomerIdentifiers, {
      quantity: cartProduct.quantity,
      quantityIncrement,
      productQuantity,
      conditioningQuantity,
    });

    if (!isValidTruckSplitInfo(truckSplitInfo)) {
      return CartProductPayload.Error({
        message: 'La quantité de produit est incorrecte',
      });
    }

    Object.assign(product, {
      availableSelectedQuantity: (quantityIncrement / conditioningQuantity) * productQuantity,
      truckSplitInfo,
    });

    return CartProductPayload.Some(Object.assign(product, override), isGroup, cartProduct?.isTruck);
  }

  return CartProductPayload.Some(Object.assign(product, override), false, false);
};

export const isValidTruckSplitInfo = (truckSplitInfo: ICartProductItem['truckSplitInfo']): boolean => {
  return typeof truckSplitInfo !== 'undefined';
};

export const createUrl = (productId: number): string => {
  return `${config.shopUrl}/_/p${productId}`;
};

export const getProductReplacements = (product: {
  equivalentProducts?: Array<IAlgoliaEquivalentProduct>;
  substitutionProducts?: Array<IAlgoliaSubstitutionProduct>;
}): INormalizedList<{
  type: ProductReplacement;
  replacement: IAlgoliaEquivalentProduct | IAlgoliaSubstitutionProduct;
}> => {
  const replacements: INormalizedList<{
    type: ProductReplacement;
    replacement: IAlgoliaEquivalentProduct | IAlgoliaSubstitutionProduct;
  }> = {};

  product.equivalentProducts?.forEach((item) => {
    replacements[item.productId] = {
      type: ProductReplacement.EQUIVALENT,
      replacement: item,
    };
  });

  product.substitutionProducts?.forEach((item) => {
    replacements[item.productId] = {
      type: ProductReplacement.SUBSTITUTE,
      replacement: item,
    };
  });

  return replacements;
};

export const findCartProductIndex = (id: number, products: Array<ICartProductItem>): number => {
  const stockIndex = products.findIndex(({ offerId }) => offerId === id);
  const noStockIndex = products.findIndex(({ variantId }) => variantId === id);
  return noStockIndex === -1 ? stockIndex : noStockIndex;
};

export const priceToCent = (price: number): number => Number((price * 100).toFixed(0));
export const centToPrice = (price: number): number => price / 100;
export const isValidNumber = (nbr: number): boolean => typeof nbr === 'number' && !isNaN(nbr);

export const castTaxes = (taxes: IProductTaxes | undefined): IProductTaxes => {
  if (!taxes) {
    return { vat: 0 };
  }

  const { cvo, ecoContribution, rpd, vat } = taxes;

  return {
    ...(cvo && { cvo: Number(cvo) }),
    ...(ecoContribution && { ecoContribution: Number(ecoContribution) }),
    ...(rpd && { rpd: Number(rpd) }),
    vat: Number(vat),
  };
};

export const isFetchProductSearchSingleResponse = (
  response: IFetchProductsResponseWithSearchType,
): response is IFetchProductsSingleResponse => response.searchType === ProductSearchType.SINGLE_SEARCH;

export const isFetchProductSearchMultiResponse = (
  response: IFetchProductsResponseWithSearchType,
): response is IFetchProductsMultiResponse => response.searchType === ProductSearchType.MULTIPLE_SEARCH;

export const isFetchProductSearchCustomerQuotationResponse = (
  response: IFetchProductsResponseWithSearchType,
): response is IFetchProductsCustomerQuotesResponse =>
  response.searchType === ProductSearchType.CUSTOMER_QUOTATION_SEARCH;

export const isProductAmountCustomerQuotation = (
  props: IProductAmountProps,
): props is IProductAmountCustomerQuotationProps => props.productType === 'customer-quotation';

export const getProductTruckNumber = (offerId: string | number): string | undefined => String(offerId).split('-')[1];
