import { createExportPDFVariables, downloadPdf, IPDFProps } from 'utils/pdf/utils';
import { useAppDispatch } from 'store/store';
import { exportPDF } from 'store/cart';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'hooks';
import env from 'config';
import { Button } from 'components/shared';
import { isFulfilled } from '@reduxjs/toolkit';
import { FilePdfOutlined } from '@ant-design/icons';
import { RootState } from '../../../store/rootReducer';
import styles from './ExportPDFButton.module.scss';

const ExportPDFButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const trans = useTranslation('ExportPDF');
  const [loading, setLoading] = useState(false);
  const state = useSelector((rootState: RootState) => rootState);

  const handeExportPDF = async () => {
    setLoading(true);

    const returnResponse = await dispatch(exportPDF());
    const variables = createExportPDFVariables(state);
    if (isFulfilled(returnResponse)) {
      downloadPdf({
        body: returnResponse.payload,
        customer: `${variables.customer.firstName} ${variables.customer.lastName}`,
        createdAt: moment().toISOString(),
        cartId: 'generated',
        pdfId: 1,
      } as IPDFProps);
    }
    setLoading(false);
  };

  if (!env('enableExportPdf')) {
    return null;
  }

  return (
    <>
      <Button
        onClick={handeExportPDF}
        className={styles.button}
        type="primary"
        icon={<FilePdfOutlined />}
        loading={loading ? { delay: 200 } : false}
      >
        {trans('Export PDF')}
      </Button>
    </>
  );
};

export default ExportPDFButton;
