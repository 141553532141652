import { createTranslation } from 'utils/translation.utils';
import { ICartRequestItem } from 'types/cartRequest';
import { IParsedProductItem } from 'store/products/types';
import { ProductPrice, Text } from 'components/shared';
import Stock from 'components/product/ProductTable/components/Stock';
import ShippingMethods from 'components/product/ProductTable/components/ShippingMethods';
import ProductInfo from 'components/product/ProductTable/components/ProductInfo';
import ProductAmount from 'components/product/ProductTable/components/ProductAmount';
import { BankOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { MultiSearchHeader } from '../../../types/product';
import styles from './ProductTable.module.scss';

const trans = createTranslation('Products');

export const ProductInfoColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    title: 'Produit',
    dataIndex: 'name',
    render: (_, product) => <ProductInfo {...product} />,
    ...override,
  };
};

export const ProductReferenceColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    align: 'center',
    title: 'Code',
    dataIndex: 'sku',
    render: (sku) => <Text type="subtitle-thin">{sku}</Text>,
    ...override,
  };
};
export const ProductOnlineColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    align: 'center',
    title: 'Online',
    dataIndex: 'purchasableOffline',
    render: (purchasableOffline) => {
      return (
        <Text type="subtitle-thin">
          <ShoppingCartOutlined
            size={24}
            className={purchasableOffline ? styles.shoppingCartGreen : styles.shoppingCartGrey}
          />
        </Text>
      );
    },
    ...override,
  };
};

export const ProductStockColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    align: 'center',
    title: <span className={styles.title}>Stock</span>,
    dataIndex: 'stock',
    render: (stock, { offerId, unit, quantityIncrement, conditioning }) => (
      <Stock
        stock={stock}
        unit={unit}
        conditioning={conditioning}
        quantityIncrement={quantityIncrement}
        offerId={offerId}
      />
    ),
    ...override,
  };
};
export const ProductWarehouseStockColumn = (
  showStockModal: (sku: string) => void,
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    align: 'center',
    title: <span className={styles.title}>Stock</span>,
    dataIndex: 'sku',
    render: (sku) => <BankOutlined size={24} className={styles.bankStyle} onClick={(_e) => showStockModal(sku)} />,
    ...override,
  };
};

export const ProductDeliveryColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    align: 'center',
    title: 'Livraison',
    dataIndex: 'shippingMethods',
    render: (_, product) => <ShippingMethods product={product} />,
    ...override,
  };
};

export const ProductPriceColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    title: <span className={styles.title}>Prix HT</span>,
    dataIndex: undefined,
    render: ({
      price,
      discountOffers,
      conditioning,
      conditioningQuantity,
      unit,
      quantityIncrement,
      taxes,
    }: IParsedProductItem) => (
      <ProductPrice
        {...price!}
        discountOffers={discountOffers}
        isProduct={true}
        conditioning={conditioning}
        conditioningQuantity={conditioningQuantity}
        unit={unit}
        quantityIncrement={quantityIncrement}
        taxes={taxes}
      />
    ),
    ...override,
  };
};

export const ProductQuantityColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    title: <span className={styles.title}>Quantité</span>,
    dataIndex: 'offerId',
    render: (offerId, product) => <ProductAmount offerId={offerId} product={product} productType="default" />,
    ...override,
  };
};

export const ProductMultiSearchQuantityColumn = (
  override: TableColumnDeinitionOverride = {},
  rowKey = -1,
): TableColumnDefinition<IParsedProductItem> => {
  return {
    title: <span className={styles.title}>Quantité</span>,
    dataIndex: 'offerId',
    render: (offerId, product) => (
      <ProductAmount offerId={offerId} product={product} productType="multi" rowKey={rowKey} />
    ),
    ...override,
  };
};

export const ProductCustomerQuotationSearchQuantityColumn = (
  override: TableColumnDeinitionOverride = {},
  cartRequestItem: ICartRequestItem,
): TableColumnDefinition<IParsedProductItem> => {
  const rowKey = cartRequestItem.id;

  return {
    title: <span className={styles.title}>Quantité</span>,
    dataIndex: 'offerId',
    render: (offerId, product) => (
      <ProductAmount
        offerId={offerId}
        product={product}
        productType="customer-quotation"
        cartRequestItem={cartRequestItem}
        rowKey={rowKey}
      />
    ),
    ...override,
  };
};

export const ProductNullColumn = (override: TableColumnDeinitionOverride = {}): TableColumnDefinition<unknown> => {
  return {
    title: '',
    dataIndex: undefined,
    render: () => null,
    ...override,
  };
};

export const MultiSearchQtyColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<MultiSearchHeader> => {
  return {
    dataIndex: undefined,
    title: <span className={styles.title}>{trans('Quantity')}</span>,
    render: ({ qty, unit }: MultiSearchHeader) => {
      return (
        <span>
          {qty} {unit}
        </span>
      );
    },
    ...override,
  };
};

export const CustomerQuotationQtyColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<ICartRequestItem> => {
  return {
    dataIndex: undefined,
    title: <span className={styles.title}>{trans('Quantity')}</span>,
    render: ({ quantity, unit }: ICartRequestItem) => {
      return (
        <span>
          {quantity} {unit?.name || null}
        </span>
      );
    },
    ...override,
  };
};
