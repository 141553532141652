import { Space, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { Text } from '../../../../shared';
import { useAppSelector } from '../../../../../store/store';
import { selectRestoreErrors } from '../../../../../store/savedCart';

type ProductWarningProps = {
  productId: number;
  variantId: number;
  offerId: number;
};
const ProductWarning = ({ productId, variantId, offerId }: ProductWarningProps): null | Element => {
  const { productErrors } = useAppSelector(selectRestoreErrors);

  const errors = productErrors.priceChanged
    .concat(
      ...productErrors.partialStock,
      ...productErrors.outOfStock,
      ...productErrors.noProductFound,
      ...productErrors.noOfferFound,
    )
    .filter(({ product: p }) => p.productId === productId && p.variantId === variantId && p.offerId === offerId);

  if (errors.length <= 0) {
    return null;
  }

  return (
    <Tooltip
      overlay={
        <Space direction="vertical">
          {errors.map((e) => (
            <Text key={e.message}>{e.message}</Text>
          ))}
        </Space>
      }
    >
      <span style={{ marginTop: '6px' }}>
        <WarningOutlined style={{ color: 'var(--dark-pink)', fontSize: '24px' }} />
      </span>
    </Tooltip>
  );
};

export default ProductWarning;
