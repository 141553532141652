import {
  GetBestOffersParams,
  IAlgoliaProduct,
  IAlgoliaProductResponse,
  PostUploadFileResponse,
  Unit,
} from 'types/product';
import { IOfferResponse } from 'types/offer';
import moment from 'moment';
import { getToken } from 'cookies';
import env, { config } from 'config';
import { ApiResponse } from 'apisauce';
import algoliasearch, { SearchIndex } from 'algoliasearch';
import { SearchResponse } from '@algolia/client-search';
import { IWarehouseStockData } from '../store/products/types';
import { BEST_OFFERS } from 'constants/routes/apiRoutes';
import { RestServiceApi } from './api.service';

const offersApi = new RestServiceApi(config.productsBackendUrl || '');
const msAtcBackendApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

type IGetOffersPayload = {
  products: Array<number>;
  country: string;
  purchasableOffline?: boolean;
  club?: boolean;
  postcode?: string;
  tags?: Array<string>;
  clubMemberExpDate?: Date;
  ignoreStockZero?: boolean;
};

type GetWarehouseStockProps = {
  sku: string;
  warehouseName: string;
  hideOutOfStock?: boolean;
};

export class ProductsServiceApi {
  private algoliaIndex!: SearchIndex;

  constructor() {
    const { algoliaSearchAppId, algoliaSearchApiKey, algoliaSearchIndex } = config;

    if (algoliaSearchAppId && algoliaSearchApiKey && algoliaSearchIndex) {
      const client = algoliasearch(algoliaSearchAppId, algoliaSearchApiKey);
      this.algoliaIndex = client.initIndex(algoliaSearchIndex);
    }
  }

  public async search(
    searchParam: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filter: string | false | null = null,
    page = 0,
    itemsPerPage = 600,
  ): Promise<SearchResponse<IAlgoliaProduct>> {
    const filters: Array<string> = ['publish_atc_checkout=1'];

    if (filter) {
      filters.push(filter);
    }

    const response: SearchResponse<IAlgoliaProductResponse> = await ProductsService.algoliaIndex.search(searchParam, {
      ...(!!filters.length && { filters: filters.join(' AND ') }),
      hitsPerPage: itemsPerPage,
      page,
    });

    return {
      ...response,
      hits: response.hits.map((product) => ({ ...product, shippingMethods: product.shippingMethods || [] })),
    } as SearchResponse<IAlgoliaProduct>;
  }

  public async filterByProductId(
    productIds: number[],
    variantIds?: number[] | null,
    page = 0,
    itemsPerPage = env('algoliaProductsLimit'),
  ): Promise<SearchResponse<IAlgoliaProduct>> {
    let filters;

    if (productIds.length > 0) {
      filters = `(${productIds.map((item) => `productId=${item}`).join(' OR ')})`;

      if (variantIds) {
        const filtersVariantsIds = `(${variantIds.map((item) => `variantId=${item}`).join(' OR ')})`;
        filters = `${filters} AND ${filtersVariantsIds}`;
      }
    }

    const response: SearchResponse<IAlgoliaProductResponse> = await ProductsService.algoliaIndex.search('', {
      filters,
      hitsPerPage: itemsPerPage,
      page,
    });

    return {
      ...response,
      hits: response.hits.map((product) => ({ ...product, shippingMethods: product.shippingMethods || [] })),
    } as SearchResponse<IAlgoliaProduct>;
  }

  public async findBySku(sku: string, page = 0, hitsPerPage = env('algoliaProductsLimit')): Promise<IAlgoliaProduct[]> {
    const result: SearchResponse<IAlgoliaProductResponse> = await ProductsService.algoliaIndex.search('', {
      filters: `sku:${sku} AND publish_atc_checkout=1`,
      hitsPerPage,
      page,
    });

    return result.hits.map((product) => ({
      ...product,
      shippingMethods: product.shippingMethods || [],
    })) as IAlgoliaProduct[];
  }

  public async getBestOffers({
    products,
    postcode,
    tags,
    allOffers,
    clubMemberExpDate,
    excludeNoStockOffers = false,
  }: GetBestOffersParams): Promise<ApiResponse<IOfferResponse>> {
    const club = clubMemberExpDate ? moment(clubMemberExpDate).isSameOrAfter(moment()) : false;
    return offersApi.post<IGetOffersPayload, IOfferResponse>(BEST_OFFERS, {
      products,
      postcode,
      country: env('countryCode'),
      club,
      purchasableOffline: true,
      ...(tags && {
        tags: Array.from(new Set(tags.map((tag) => tag.toLowerCase()))),
      }),
      ...(allOffers && { allOffer: allOffers }),
      ignoreStockZero: excludeNoStockOffers,
    });
  }

  public async importMultiSearchFile(file: File): Promise<ApiResponse<Array<PostUploadFileResponse>>> {
    const fd = new FormData();
    fd.append('files', file);
    return msAtcBackendApi.post<FormData, Array<PostUploadFileResponse>>(
      '/upload',
      fd,
      msAtcBackendApi.getAuthenticateHeader(getToken()),
    );
  }

  public async getWarehouseStockBySku(sku: string): Promise<ApiResponse<IWarehouseStockData>> {
    return msAtcBackendApi.get(
      `/warehouse-stock`,
      {
        sku: sku,
        '$sort[warehouseName]': 1,
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async getWarehouseStock({
    sku,
    warehouseName,
    hideOutOfStock,
  }: GetWarehouseStockProps): Promise<ApiResponse<IWarehouseStockData>> {
    return msAtcBackendApi.get(
      `/warehouse-stock`,
      {
        sku,
        ...(!!warehouseName && {
          warehouseName: `${warehouseName}`,
        }),
        ...(typeof hideOutOfStock !== 'undefined' &&
          hideOutOfStock && {
            quantity: true,
          }),
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async getUnits(locale: string): Promise<ApiResponse<Unit[]>> {
    return msAtcBackendApi.get(`/units?locale=${locale}`, {}, msAtcBackendApi.getAuthenticateHeader(getToken()));
  }
}

export const ProductsService = new ProductsServiceApi();
