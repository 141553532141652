import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Text } from 'components/shared';
import ValidatedSubmit from 'assets/images/validatedSubmit.svg';
import { Image } from 'antd';
import { ValidatedProps } from './types';
import styles from './ValidatedOrder.module.scss';

type ValidatedOrderProps = ValidatedProps;

const ValidatedOrder: React.FC<ValidatedOrderProps> = (props: ValidatedOrderProps) => {
  const { onCreateNewOrder } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Text className={styles.content__title} type="title-gray8">
          {t('common.orderValidated')}!
        </Text>
        <div className={styles.content__buttons}>
          <Button onClick={onCreateNewOrder} className={`${styles.button} ${styles.button_full}`}>
            {t('common.createNewOrder')}
          </Button>
        </div>
      </div>
      <Image preview={false} src={ValidatedSubmit} className={styles.image} />
    </div>
  );
};

export default ValidatedOrder;
