import { sendMessage } from 'store/messages';
import { useDispatch } from 'react-redux';
import { FC, useState } from 'react';
import { useTheme } from 'hooks/useTheme';
import { useAuth } from 'hooks/useAuth';
import { FormInput, Button, Text } from 'components/shared';
import { IResponseError } from 'components/login/LoginForm/LoginForm.types';
import { Form } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { emailRule } from './validators';
import { IForgotPasswordFormProps, IFormValues } from './types';
import styles from './styles.module.scss';

const ForgotPasswordForm: FC<IForgotPasswordFormProps> = (props: IForgotPasswordFormProps) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const { forgotPassword } = useAuth();
  const style = useTheme(styles);

  const onFinish = async (values: IFormValues) => {
    try {
      await forgotPassword(values.email);
      setEmail(values.email);
    } catch (error) {
      const { message } = error as IResponseError;
      dispatch(sendMessage({ message, type: 'error' }));
    }
  };

  const onGoToResetPage = () => {
    setEmail('');
    onClose();
  };

  return !email ? (
    <>
      <Text type="title">Réinitialiser votre mot de passe</Text>
      <Text type="subtitle" className={styles.text_subtitle}>
        Entrez l’adresse e-mail, un e-mail vous sera envoyé à cette adresse pour vous permettre de changer votre mot de
        passe oublié.
      </Text>
      <Form layout="vertical" name="forgot-password" onFinish={onFinish} className={styles.form}>
        <FormInput
          name="email"
          rules={emailRule}
          inputProps={{
            placeholder: 'E-mail',
            prefix: <UserOutlined />,
          }}
        />
        <div className={styles.buttons}>
          <Button className={style('button')} type="primary" htmlType="submit">
            Envoyer
          </Button>
        </div>
      </Form>
    </>
  ) : (
    <>
      <Text type="title">E-mail envoyé !</Text>
      <Text type="subtitle">
        Un email a été envoyé à l’adresse {email}.
        <br />
        Il contient un code que vous pouvez utiliser afin de réinitialiser votre mot de passe.
      </Text>
      <Button onClick={onGoToResetPage} className={styles.resetButton} type="dashed">
        Introduire votre code
      </Button>
    </>
  );
};

export default ForgotPasswordForm;
