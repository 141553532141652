import { sendMessage } from 'store/messages';
import { useDispatch } from 'react-redux';
import { FC } from 'react';
import { useAuth } from 'hooks/useAuth';
import { FormInput, Button, Text } from 'components/shared';
import { IResponseError } from 'components/login/LoginForm/LoginForm.types';
import { Form } from 'antd';
import { emailRule, verificationCodeRule, passwordRule } from './validators';
import { IResetPasswordFormProps, IForgotPasswordVerification } from './types';
import styles from './styles.module.scss';

const ResetPasswordForm: FC<IResetPasswordFormProps> = (props: IResetPasswordFormProps) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { confirmForgotPassword } = useAuth();

  const onFinish = async (values: IForgotPasswordVerification) => {
    try {
      await confirmForgotPassword(values.username, values.newPassword, values.verificationCode);
      dispatch(sendMessage({ message: 'Votre mot de passe a été réinitialisé !', type: 'success' }));
      onClose();
    } catch (error) {
      const { message } = error as IResponseError;
      dispatch(sendMessage({ message, type: 'error' }));
    }
  };

  return (
    <>
      <Text className={styles.title} type="title">
        Set new password
      </Text>
      <Form layout="vertical" name="reset-password" onFinish={onFinish} className={styles.form}>
        <FormInput
          name="username"
          rules={emailRule}
          inputProps={{
            placeholder: 'E-maill',
          }}
        />
        <FormInput
          name="verificationCode"
          rules={verificationCodeRule}
          inputProps={{
            placeholder: 'Code de vérification',
          }}
        />
        <FormInput
          name="newPassword"
          rules={passwordRule}
          inputProps={{
            placeholder: 'Nouveau mot de passe',
            type: 'password',
          }}
        />
        <Button type="primary" htmlType="submit">
          Réinitialisez votre mot de passe
        </Button>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
