import { unwrap } from 'utils/api.utils';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setMultiSearchQueries } from 'store/products';
import { sendMessage } from 'store/messages';
import { selectSelectedCustomer } from 'store/customers';
import { ProductsService } from 'services/products.service';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useState } from 'react';
import { useLoading } from 'hooks/useLoading';
import { Button, Text } from 'components/shared';
import { UploadChangeParam, UploadFileStatus, UploadProps } from 'antd/lib/upload/interface';
import { Spin, Upload } from 'antd';
import { ProductFileImportEnum } from '../ProductFileImportButton';
import styles from './styles.module.scss';

interface ProductFileImportProps {
  showConfirmation: () => void;
  type: ProductFileImportEnum;
}

export type RcCustomRequestOptions<T = unknown> = Parameters<Exclude<UploadProps<T>['customRequest'], undefined>>[0];
export type RcCustomRequestOptionsSanitize = Omit<RcCustomRequestOptions, 'file'> & {
  file: File;
  onSuccess: () => void;
  onError: () => void;
};

const ProductFileImport: FC<ProductFileImportProps> = ({ showConfirmation, type }: ProductFileImportProps) => {
  const [uploading, setUploading] = useState(false);
  const dispatch = useAppDispatch();
  const [delay, setLoading, clearLoading] = useLoading(300);
  const customerId = useAppSelector(selectSelectedCustomer);
  const { t } = useTranslation();

  const uploadRequest = useCallback(
    async (options: RcCustomRequestOptions) => {
      const { onSuccess, onError, file } = options as RcCustomRequestOptionsSanitize;
      try {
        if (type === ProductFileImportEnum.MULTI) {
          const data = unwrap(await ProductsService.importMultiSearchFile(file));
          dispatch(setMultiSearchQueries(data.map((item) => item.rows).flat()));
        }
        onSuccess?.();
      } catch (err) {
        dispatch(
          sendMessage({
            message: t(err?.code ? `errors.${err.code}` : 'errors.invalidFileUpload'),
            code: err?.code,
            type: 'error',
          }),
        );
        onError?.();
      }
    },
    [dispatch, customerId, type, t],
  );

  const handleUploadChange = (info: UploadChangeParam) => {
    const { file } = info;
    if (file.status && (['uploading'] as UploadFileStatus[]).includes(file.status)) {
      setLoading(() => setUploading(true));
    } else {
      clearLoading(() => setUploading(false));
    }

    if (file.status === 'done') {
      showConfirmation();
    }
  };

  const description = () => {
    if (type === ProductFileImportEnum.MULTI) {
      return (
        <>
          <Text type="title" className={styles.title}>
            {t('common.multiSearchText')}
          </Text>
        </>
      );
    }
  };

  return (
    <div className={styles.container}>
      {description()}
      <Upload
        className={styles.upload}
        name="importProducts"
        customRequest={uploadRequest}
        accept=".xls,.csv,.tab,.xlsx"
        showUploadList={false}
        multiple={false}
        maxCount={1}
        onChange={handleUploadChange}
      >
        <Spin delay={delay} spinning={uploading}>
          <Button size="large" className={styles.button} disabled={uploading} type="primary">
            {t('modals.findFile')}
          </Button>
        </Spin>
      </Upload>
    </div>
  );
};

export default ProductFileImport;
