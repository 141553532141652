import { useIsMobile } from 'utils/styles.utils';
import { ErrorType, ICustomerGroup } from 'types';
import { useAppDispatch } from 'store/store';
import { SavedCartStatusEnum } from 'store/savedCart/types';
import { sendMessage } from 'store/messages';
import { selectIsGroup, selectSelectedGroupCustomers } from 'store/customers';
import {
  selectOrders,
  selectCartError,
  selectCartProductsWithNoStock,
  selectIsLoading,
  selectSendQuoteByEmail,
  setSendQuoteByEmail,
  selectIsSavedCart,
  selectCartStatus,
} from 'store/cart';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useMemo } from 'react';
import env from 'config';
import { CartRequestExpiration } from 'components/validation/CartRequestExpiration';
import { ValidationContainer, ValidationHeader, ValidationSummary } from 'components/validation';
import { CartComments, Text } from 'components/shared';
import Stepper from 'components/layout/Stepper';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Layout, Checkbox } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { capitalizeFirstLetter, getProductTruckNumber } from '../../utils';
import { IOrder } from '../../types/order';
import { clearProductError, createMarketplace, saveSavedCart, updateSavedCart } from '../../store/savedCart';
import { usePageBlocker } from '../../hooks/usePageBlocker';
import { useTranslation as useTrans } from '../../hooks';
import { getValidatedPath } from '../../constants/routes';
import ValidationNoStockContainer from '../../components/validation/ValidationNoStockContainer';
import ConfirmationModal from '../../components/shared/ConfirmationModal';
import { Button } from '../../components/shared';
import styles from './Validation.module.scss';

const Validation: FC = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const orders = useSelector(selectOrders);
  const isError = useSelector(selectCartError);
  const isLoading = useSelector(selectIsLoading);
  const isGroup = useSelector(selectIsGroup);
  const customersGroup = useSelector(selectSelectedGroupCustomers);
  const noStockCart = useSelector(selectCartProductsWithNoStock);
  const sendQuoteByEmail = useSelector(selectSendQuoteByEmail);
  const trans = useTrans('Violations');
  const { t: translations } = useTranslation();
  const isSavedCart = useSelector(selectIsSavedCart);
  const savedCartStatus = useSelector(selectCartStatus);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const t = useCallback(trans, []);
  const { blocker, confirmationModal } = usePageBlocker();

  useEffect(() => {
    if (isError) {
      if (isError.code === 24) {
        dispatch(sendMessage({ message: (isError as ErrorType).message, type: 'warning' }));
      } else {
        const messageText =
          (isError as ErrorType)?.details?.violations?.map((violation) => {
            const [_main, property, key] = violation.propertyPath.split('.');
            if (property && key) {
              return `${t(property)} (${capitalizeFirstLetter(key)}): ${violation.message}`;
            }
            return `${violation?.message}`;
          }) || [];

        if (messageText.length > 0) {
          dispatch(sendMessage({ message: messageText.join('.'), type: 'error' }));
        } else {
          dispatch(sendMessage({ message: isError.message, type: 'error' }));
        }
      }
    }
  }, [dispatch, isError, t]);

  const blockedActionStatus = useMemo(() => {
    if (!isSavedCart || !savedCartStatus) return false;
    if ([SavedCartStatusEnum.CONVERTED, SavedCartStatusEnum.REFUSED].includes(savedCartStatus)) return true;
  }, [savedCartStatus, isSavedCart]);

  const sendCustomerQuote = async () => {
    const response = await dispatch(saveSavedCart({ stepNumber: 4, savePdfVariables: true, attachPayload: true }));

    dispatch(clearProductError());
    if (isFulfilled(response)) {
      const {
        cart: { id: cartId },
        marketplacePayload,
      } = response.payload;

      if (marketplacePayload && marketplacePayload.metadata) {
        marketplacePayload.metadata['send_quote_to_customer_by_email'] = sendQuoteByEmail;
      }

      const marketPlaceResponse = marketplacePayload && (await dispatch(createMarketplace({ marketplacePayload })));

      if (cartId) {
        await dispatch(
          updateSavedCart({
            id: cartId,
            data: {
              status: SavedCartStatusEnum.SENT_TO_CUSTOMER,
              sentToCustomerAt: new Date().toISOString(),
            },
          }),
        );
      }

      if (env('enableCartRequests') && isFulfilled(marketPlaceResponse)) {
        navigate(getValidatedPath('quote', cartId, marketPlaceResponse?.payload?.marketplace?.uuid, marketPlaceResponse?.payload?.marketplace?.id));
      }
    }
  };

  const isCustomerInOrders = (customerId: string): boolean => {
    return !!orders.find((order) => {
      if (!order.isTruck) return order.customerId === customerId;
      return order.orderItems.find((item) => !!item.customerIds.find((id) => id === customerId));
    });
  };

  const getValidationContainer = (order: IOrder, customer: ICustomerGroup) => {
    if (order.customerId === customer.customerIdentifier)
      return (
        <div key={order.id}>
          <ValidationContainer order={order} customerIdentifier={customer.customerIdentifier} />
        </div>
      );
    return null;
  };

  const OutOfStockProducts = () => {
    if (noStockCart.length === 0) return null;

    return (
      <>
        {noStockCart.map((product) => {
          return <ValidationNoStockContainer key={product.variantId} product={product} />;
        })}
      </>
    );
  };

  const updateSendQuoteByEmail = (e: CheckboxChangeEvent) => {
    dispatch(setSendQuoteByEmail(e.target.checked));
  };

  return (
    <div>
      <ValidationHeader />
      <Layout.Content className={styles.container}>
        <div className={styles.left}>
          {isGroup
            ? customersGroup.map((customer) => {
                return (
                  <>
                    {isCustomerInOrders(String(customer.customerIdentifier)) && (
                      <Text type="title-gray3">
                        {customer.firstName} {customer.lastName}
                      </Text>
                    )}
                    {orders.map((order) => {
                      return !order.isTruck ? (
                        getValidationContainer(order, customer)
                      ) : (
                        <>
                          {order.orderItems.map((orderItem) => {
                            const truckId = getProductTruckNumber(orderItem.offerId);

                            return (
                              <div key={orderItem.offerId}>
                                {order.customerId === customer.customerIdentifier ? (
                                  <div>
                                    <ValidationContainer
                                      order={order}
                                      truckId={truckId}
                                      customerIdentifier={order.customerId}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                );
              })
            : orders.map((order) => (
                <div key={order.id}>
                  <ValidationContainer order={order} />
                </div>
              ))}
          <OutOfStockProducts />

          {!!env('enableCartComments') && (
            <div className={styles.comment}>
              <CartComments />
            </div>
          )}
        </div>

        <div className={styles.right}>
          {!isGroup && <CartRequestExpiration />}
          <ValidationSummary />
          {env('enableSendQuoteToCustomer') && !blockedActionStatus && (
            <div className={styles.sendQuoteContainer}>
              <ConfirmationModal
                onClose={() => undefined}
                onConfirm={sendCustomerQuote}
                title={translations('modals.confirmationModalSendQuoteTitle')}
              >
                {(show) => (
                  <Button type="primary" className={styles.button} onClick={() => show()} disabled={isLoading}>
                    {translations('common.sendCustomerQuote')}
                  </Button>
                )}
              </ConfirmationModal>
              <Checkbox className={styles.checkbox} onChange={updateSendQuoteByEmail} checked={sendQuoteByEmail}>
                {translations('common.notifyCustomerQuestion')}
              </Checkbox>
            </div>
          )}
        </div>
      </Layout.Content>
      {blocker ? confirmationModal : null}
      {!isMobile && <Stepper />}
    </div>
  );
};

export default Validation;
