import { createTranslation } from 'utils/translation.utils';
import { isEmpty } from 'utils/object.utils';
import { createError } from 'utils/error.utils';
import {
  createCartProduct,
  createNoStockCartProduct,
  calculateProductPriceTotal,
  calculateProductPriceTotalWithTaxes,
  priceParser,
  CartProductPayload,
  getParsedCartProductWithOfferId,
} from 'utils';
import { CreateProductItemMessage, IParsedProductItemSavedCart, SavedCartProductAssoc } from 'types/savedCart';
import { IProductOfferShippingMethod, ParsedProductsForTable } from 'types/product';
import store from 'store/store';
import {
  SavedCartRestoredProductItem,
  SavedCartSateProductItem,
  SavedCartSateShippingItem,
  SavedCartSavedCustomersGroupState,
  SavedCartSavedCustomerSingleState,
  SavedCartSavedCustomerState,
  SavedCartSavedState,
  SavedCartStateProductItemKey,
  RestoreSavedCartStateResponse,
} from 'store/savedCart/types';
import { addProductError } from 'store/savedCart/reducer';
import { RootState } from 'store/rootReducer';
import { IParsedProductItem } from 'store/products/types';
import { ICartProductItem, SupplierPriceType } from 'store/cart/types';
import { makeTaggedUnion, MemberType } from 'safety-match';
import moment from 'moment';
import env from 'config';
import { DiscountType } from 'components/shared/Discount/Discount.types';
import { Supplier } from '../types/suppliers';
import { ActionType } from '../types/logs';
import { INormalizedList } from '../types';
import { ICategorizedShippingItems } from '../store/delivery/types';
import { DEFAULT_DAYS, DEFAULT_HOURS } from '../constants/routes';
import { PRODUCT_OFFER_TYPE } from 'constants/products/products';
import { selectCustomer } from './pdf/utils';

const trans = createTranslation('SavedCart');

export const createSavedCartState = (
  state: RootState,
  { stepNumber, allowEmptySave }: { stepNumber: number; allowEmptySave?: boolean },
): SavedCartSavedState => {
  const customers = createCustomersState(state);
  const town = createTownState(state);
  const cart = createCartState(state, { allowEmptySave });
  const delivery = createDeliveryState(state);
  const pendingLogs = createPendingLogsState(state);

  const expiredAt = state.savedCart.restore.expiredAt;
  const updatedAt = moment().utc(false).format();
  const expirationDays = state.savedCart.restore.expirationDays ?? DEFAULT_DAYS;
  const expirationHours = state.savedCart.restore.expirationHours ?? DEFAULT_HOURS;

  return {
    customers,
    town,
    cart,
    delivery,
    stepNumber,
    updatedAt,
    expiredAt,
    expirationDays,
    expirationHours,
    pendingLogs,
  };
};

const createTownState = (state: RootState): SavedCartSavedState['town'] => {
  const {
    products: { postalCodes: town },
  } = state;

  if (!town) {
    throw createError(trans('You must select the postal code in order to save the cart.'));
  }

  const { id, town: name, postcode, countryCode } = town;

  return {
    id,
    town: name,
    postcode,
    countryCode,
  };
};

const createSingleCustomersState = (state: RootState): SavedCartSavedCustomerSingleState => {
  const { customers } = state;
  const { selectedSearchedCustomer, customers: customerList } = customers;

  if (!selectedSearchedCustomer) {
    throw createError(trans('Invalid Action. There are no customers selected.'));
  }

  const { id, userId, firstName, lastName, customerIdentifier, company, email } = selectedSearchedCustomer;

  return {
    isGroup: false,
    selectedCustomer: id,
    selectedSearchedCustomer: {
      id,
      userId,
      firstName,
      lastName,
      customerIdentifier,
      company,
      email,
    },
    billingCustomer: selectCustomer(customerList, id),
  };
};

const createGroupCustomersState = (state: RootState): SavedCartSavedCustomersGroupState => {
  const { customers } = state;
  const { selectedGroupId, selectedGroupCustomers, groupTags } = customers;

  if (
    !selectedGroupId ||
    !selectedGroupCustomers ||
    (selectedGroupCustomers && Object.keys(selectedGroupCustomers).length === 0)
  ) {
    throw createError(trans('Invalid Action. There is no selected group.'));
  }

  const groupLead = Object.values(selectedGroupCustomers).find((c) => c.userId === c.leaderId || c.isCustomerLead);

  if (!groupLead) {
    throw createError(trans('Invalid Action. There is no selected group.'));
  }

  const { id, userId, customerIdentifier, company, email } = groupLead;

  return {
    isGroup: true,
    selectedGroupId,
    selectedSearchedGroupCustomer: {
      id,
      userId,
      customerIdentifier,
      company,
      email,
    },
    groupTags: groupTags[selectedGroupId] || [],
    selectedGroupCustomers: Object.values(selectedGroupCustomers),
  };
};

const createCustomersState = (state: RootState): SavedCartSavedState['customers'] => {
  const {
    customers: { isGroup },
  } = state;

  if (isGroup) {
    return createGroupCustomersState(state);
  }

  return createSingleCustomersState(state);
};

const createCartState = (state: RootState, options: { allowEmptySave?: boolean }): SavedCartSavedState['cart'] => {
  const {
    cart: {
      atcCartNumber,
      isEco,
      products,
      comment,
      deliveryComment,
      invoiceComment,
      cartRequestId,
      sendQuoteByEmail,
      groups,
      logs,
    },
    products: { suppliers },
  } = state;
  const { allowEmptySave } = options;

  if (isEmpty(products) && !allowEmptySave) {
    throw createError(trans('You must have at least one product in cart in order to save it.'));
  }

  const getBuyingPriceFields = (initialPrice?: number, supplierPrice?: SupplierPriceType | null) => {
    if (supplierPrice) {
      const { supplierId: overrideSupplierId, price } = supplierPrice;
      const supplier = (suppliers as Supplier[]).find((item) => item.id === overrideSupplierId);
      return {
        supplier_name: supplier?.name || '',
        ...(initialPrice && {
          buying_price: initialPrice,
        }),
        buying_price_overrided: price,
      };
    }

    return {
      ...(initialPrice && {
        buying_price: initialPrice,
      }),
    };
  };

  return {
    atcCartNumber,
    cartRequestId,
    isEco,
    sendQuoteByEmail,
    groups,
    products: Object.values(products).map((cartProduct: ICartProductItem): SavedCartSateProductItem => {
      return {
        ...sanitizeCartProductItem(cartProduct),
        ...getBuyingPriceFields(cartProduct?.price?.supplierUnitPrice, cartProduct?.supplierPrice),
      };
    }),
    comment,
    deliveryComment,
    invoiceComment,
    logs,
  };
};

const createDeliveryState = (state: RootState): SavedCartSavedState['delivery'] => {
  const {
    delivery: { shippingAddress, categoryPointList, shippingItems, shippingFees, shippingCategories, shippingDetails },
  } = state;

  if (!shippingAddress) {
    return;
  }

  return {
    shippingFees,
    shippingAddress,
    shippingItems: shippingItems.map((item) => {
      const { offerId, shippingMethodId } = item;

      return {
        offerId,
        shippingMethodId,
      };
    }),
    categoryPointList: Object.entries(categoryPointList).map((item) => ({
      shippingMethodId: Number(item[0]),
      pointListId: String(item[1]),
    })),
    shippingCategories: Object.entries(shippingCategories).reduce<INormalizedList<ICategorizedShippingItems>>(
      (acc, [key, category]) => {
        const pointList = category.isPointList
          ? category.pointList?.filter((list) => list.id === categoryPointList[category.shippingMethodId]) ?? null
          : null;

        acc[key] = {
          ...category,
          pointList: pointList && pointList.length < 1 ? null : pointList,
        };

        return acc;
      },
      {},
    ),
    shippingDetails,
  };
};

const createPendingLogsState = (state: RootState): SavedCartSavedState['pendingLogs'] => {
  return state.cart.pendingLogs[ActionType.CREATE]
    .concat(state.cart.pendingLogs[ActionType.DELETE])
    .concat(Object.values(state.cart.pendingLogs[ActionType.UPDATE]).flatMap((idMap) => Object.values(idMap)));
};

export const sanitizeCartProductItem = (cartProduct: ICartProductItem): SavedCartSateProductItem => {
  const keys: SavedCartStateProductItemKey[] = [
    'id',
    'cartRequestItemId',
    'cartProductId',
    'productId',
    'offerId',
    'offerType',
    'variantId',
    'quantity',
    'name',
    'label',
    'conditioning',
    'conditioningQuantity',
    'sku',
    'price',
    'offerPrice',
    'shippingMethods',
    'unit',
    'taxes',
    'quantityIncrement',
    'minimumOrderable',
    'priceTotalWithTaxes',
    'totalPrice',
    'cvo',
    'rpd',
    'stock',
    'weight',
    'days',
    'isTownRequired',
    'isTruck',
    'startDate',
    'endDate',
    'supplierId',
    'departureId',
    'initialPrice',
    'productOptions',
    'availableSelectedQuantity',
    'availableQuantity',
    'customerProductInfo',
    'truckSplitInfo',
    'wiuz',
    'enabled',
    'noStock',
    'replacement',
    'publicComment',
    'discountValue',
    'discountType',
    'discountSource',
    'supplierPrice',
    'removable',
    'qtyEditable',
    'enforcedPrice',
    'attachments',
    'isPlainProduct',
    'supplierReference',
    'overriddenDeliveryStartDate',
    'overriddenDeliveryEndDate',
    'deliveryOverridden',
    'metadata',
    'ignoreStock',
  ];

  const saveCartProduct: Partial<SavedCartSateProductItem> = {};
  for (const key of keys) {
    if (Object.prototype.hasOwnProperty.call(cartProduct, key)) {
      saveCartProduct[key] = cartProduct[key] as never;
    }
  }

  return saveCartProduct as SavedCartSateProductItem;
};

export const isSavedCartSingleCustomer = (
  customersState: SavedCartSavedCustomerState,
): customersState is SavedCartSavedCustomerSingleState => {
  return !customersState.isGroup;
};

export const isSavedCartGroupCustomer = (
  customersState: SavedCartSavedCustomerState,
): customersState is SavedCartSavedCustomersGroupState => {
  return customersState.isGroup;
};

export const getSavedCartProductsOffers = (props: {
  savedCartProducts: Array<SavedCartRestoredProductItem>;
  parsedProductsForTable: Array<IParsedProductItem>;
  sort?: boolean;
}): Array<SavedCartProductAssoc> => {
  const { savedCartProducts, parsedProductsForTable, sort = true } = props;
  const productsOffers = savedCartProducts.reduce<Array<SavedCartProductAssoc>>((acc, item, key) => {
    let hasOffer = true;
    let parseProductIndex = parsedProductsForTable.findIndex(
      (product) => product.offerId && product.offerId === item.offerId,
    );

    if (parseProductIndex === -1) {
      hasOffer = false;
      parseProductIndex = parsedProductsForTable.findIndex((product) => product.variantId === item.variantId);
    }

    if (item.isPlainProduct) {
      acc.push({
        parsedProduct: {
          ...item,
          hasOffer: false,
          cartSortingIndex: key,
          sortingIndex: key,
          substitutionProducts: [],
          substitutionProductIds: [],
          equivalentProducts: [],
          equivalentProductIds: [],
          bu: '',
          purchasableOffline: true,
          online: false,
          disabled: false,
        } as IParsedProductItemSavedCart,
        savedProduct: item,
      });

      return acc;
    }

    const foundParsedProduct = parsedProductsForTable[parseProductIndex];
    if (foundParsedProduct) {
      acc.push({
        parsedProduct: {
          ...foundParsedProduct,
          hasOffer: hasOffer,
          cartSortingIndex: key,
        },
        savedProduct: item,
      });
    }

    return acc;
  }, []);

  if (sort) {
    return [...productsOffers].sort((a, b) => a.parsedProduct.cartSortingIndex - b.parsedProduct.cartSortingIndex);
  }
  return productsOffers;
};

export const productMessage = (
  product: Pick<IParsedProductItem, 'productId' | 'variantId' | 'offerId' | 'name'>,
  message: string,
): CreateProductItemMessage => {
  const { productId, variantId, offerId, name } = product;
  return {
    product: {
      productId,
      variantId,
      offerId,
      name,
    },
    message,
  };
};

const CreateProductItemResponse = makeTaggedUnion({
  Some: (product: ICartProductItem) => product,
  PartialStock: (product: ICartProductItem, message: CreateProductItemMessage) => ({ product, message }),
  PriceChanged: (product: ICartProductItem, message: CreateProductItemMessage) => ({ product, message }),
  OutOfStock: (product: ICartProductItem, message: CreateProductItemMessage) => ({ product, message }),
  NoOfferFound: (product: ICartProductItem, message: CreateProductItemMessage) => ({ product, message }),
  NoProductFound: (product: ICartProductItem, message: CreateProductItemMessage) => ({ product, message }),
  Error: (message: CreateProductItemMessage) => message,
  Message: (message: CreateProductItemMessage) => message,
});

type CreateProductItemResponse = MemberType<typeof CreateProductItemResponse>;

type CreateProductItemOptions = {
  isGroup?: boolean;
  expired?: boolean;
  selectedGroupCustomersIds?: Array<number> | null;
  selectedGroupsCustomerIdentifiers?: Array<{ id: number; customerIdentifier: string }> | null;
};

export const createProductItem = (
  productAssoc: SavedCartProductAssoc,
  {
    products,
    options,
  }: {
    products: ParsedProductsForTable;
    options: CreateProductItemOptions;
  },
  preserveMissingProduct?: boolean,
): CreateProductItemResponse => {
  const REFRESH_PRODUCT_OFFER_TYPES = [PRODUCT_OFFER_TYPE.TYPE_12, PRODUCT_OFFER_TYPE.TYPE_13];
  const { isGroup = false, selectedGroupCustomersIds = null, selectedGroupsCustomerIdentifiers = null } = options;
  const messages: ((cartProduct: ICartProductItem) => CreateProductItemResponse)[] = [];
  const { parsedProduct: product, savedProduct } = productAssoc;
  const offerType = Number(savedProduct.offerType);
  const shouldRefreshPrice = !isNaN(offerType) && REFRESH_PRODUCT_OFFER_TYPES.includes(offerType);
  const keepPrice = !env('enableReloadSavedCart') && !shouldRefreshPrice;
  product.stock = product.stock || 0;
  const { quantity } = savedProduct;
  const { publicComment } = savedProduct;

  if (!keepPrice) {
   // savedProduct.offerPrice = savedProduct.offerPrice ?? product?.price?.initialPrice;
    if (!product.ignoreStock && product.stock - quantity < 0) {
      const cartProductFilled = {
        ...savedProduct,
        price: savedProduct.price || { price: 0 },
        quantityIncrement: savedProduct.quantityIncrement || 1,
        publicComment: publicComment,
      };

      // update the stock
      savedProduct.totalPrice = calculateProductPriceTotal(cartProductFilled);
      savedProduct.priceTotalWithTaxes = calculateProductPriceTotalWithTaxes(cartProductFilled);
      savedProduct.availableSelectedQuantity = quantity;
      savedProduct.availableQuantity = quantity;

      const { customerProductInfo } = savedProduct;

      // reset the split quantity
      if (customerProductInfo) {
        for (const key in customerProductInfo) {
          const item = customerProductInfo[key];
          item.selectedQuantity = 0;
          item.availableCustomerQuantity = quantity;
        }
      }

      messages.push((cartProduct) => {
        return CreateProductItemResponse.PartialStock(
          cartProduct,
          productMessage(product, trans('The product "%s" doesn\'t have enough stock.', product.name)),
        );
      });
    }
  }

  let result: MemberType<typeof CartProductPayload>;

  if (product.hasOffer && product.offerId) {
    const parsedCartProductWithOffer = getParsedCartProductWithOfferId(
      {
        products: products.products,
        offers: products.offers,
      },
      product.offerId,
      quantity,
      savedProduct.replacement,
      savedProduct.name,
      savedProduct.label,
      savedProduct.discountValue ?? 0,
      savedProduct.discountType ?? DiscountType.PERCENTAGE,
      savedProduct.discountSource,
      savedProduct.cartRequestItemId ?? null,
    );
    result = createCartProduct(
      isGroup,
      selectedGroupCustomersIds,
      selectedGroupsCustomerIdentifiers,
      parsedCartProductWithOffer,
      product,
      quantity,
      publicComment,
      {
        id: savedProduct.id,
        ...(savedProduct.qtyEditable && {
          qtyEditable: savedProduct.qtyEditable,
        }),
        ...(savedProduct.removable && {
          removable: savedProduct.removable,
        }),
        ...(savedProduct.discountType && {
          discountType: savedProduct.discountType,
        }),
        ...(savedProduct.discountValue && {
          discountValue: savedProduct.discountValue,
        }),
        ...(savedProduct.discountSource && {
          discountSource: savedProduct.discountSource,
        }),
        offerPrice: savedProduct.offerPrice,
        enforcedPrice: keepPrice || savedProduct.enforcedPrice,
        name: savedProduct.name,
        label: savedProduct.label,
      },
    );
  } else if (preserveMissingProduct || savedProduct.isPlainProduct) {
    result = CartProductPayload.Some(
      {
        ...savedProduct,
        bu: product.bu,
      } as ICartProductItem,
      false,
      false,
    );
  } else {
    result = createNoStockCartProduct(isGroup, { ...product, id: savedProduct.id }, publicComment);
  }

  return result.match({
    Some: ({ cartProduct: cartProductItem }) => {
      const {
        customerProductInfo,
        truckSplitInfo,
        price,
        initialPrice,
        offerPrice: offerPriceRaw,
        totalPrice: totalPriceRaw,
        priceTotalWithTaxes: priceTotalWithTaxesRaw,
        availableSelectedQuantity,
        enabled,
        supplierPrice,
        attachments,
        startDate,
        endDate,
        overriddenDeliveryStartDate,
        overriddenDeliveryEndDate,
        deliveryOverridden,
      } = savedProduct;

      const totalPrice = Math.round(totalPriceRaw),
        priceTotalWithTaxes = Math.round(priceTotalWithTaxesRaw),
        offerPrice = Math.round(offerPriceRaw);

      const override: Partial<ICartProductItem> = {
        supplierPrice: supplierPrice || null,
        attachments: attachments || [],
        startDate,
        endDate,
        overriddenDeliveryStartDate,
        overriddenDeliveryEndDate,
        deliveryOverridden,
        offerPrice,
      };

      Object.assign<Partial<ICartProductItem>, Partial<ICartProductItem>>(override, {
        availableSelectedQuantity,
        enabled,
        keepPrice,
      });
      if (keepPrice) {
        Object.assign<Partial<ICartProductItem>, Partial<ICartProductItem>>(override, {
          price,
          initialPrice,
          totalPrice,
          priceTotalWithTaxes,
          offerPrice,
        });
      }

      if (product?.price?.price !== price?.price) {
        messages.push((iCartProductItem) => {
          return CreateProductItemResponse.PriceChanged(
            iCartProductItem,
            productMessage(
              product,
              trans(
                '"%s": price has changed from %s to %s',
                cartProductItem.name,
                priceParser(price?.price || 0),
                priceParser(product?.price?.price || 0),
              ),
            ),
          );
        });
      }

      if (customerProductInfo) {
        Object.assign<Partial<ICartProductItem>, Partial<ICartProductItem>>(override, {
          customerProductInfo,
        });
      }

      if (truckSplitInfo) {
        Object.assign<Partial<ICartProductItem>, Partial<ICartProductItem>>(override, {
          truckSplitInfo,
        });
      }

      const cartProduct: ICartProductItem = {
        ...cartProductItem,
        ...override,
      };

      if (messages.length > 0) {
        return messages[0](cartProduct);
      }

      return CreateProductItemResponse.Some(cartProduct);
    },
    Error: ({ message }) => {
      return CreateProductItemResponse.Error(productMessage(product, message));
    },
  });
};

export const createCartProducts = (props: {
  parsedProducts: Array<SavedCartProductAssoc>;
  productItemOptions: CreateProductItemOptions;
  products: ParsedProductsForTable;
  preserveMissingProducts?: boolean;
}): {
  productErrors: RestoreSavedCartStateResponse['productErrors'];
  cartProducts: Array<ICartProductItem>;
} => {
  const { parsedProducts, products, productItemOptions, preserveMissingProducts = false } = props;

  const productErrors: RestoreSavedCartStateResponse['productErrors'] = {
    priceChanged: [],
    outOfStock: [],
    partialStock: [],
    noOfferFound: [],
    noProductFound: [],
    message: [],
    error: [],
  };

  const cartProducts = parsedProducts.reduce<Array<ICartProductItem>>((accumulator, item) => {
    try {
      const result = createProductItem(
        item,
        {
          options: productItemOptions,
          products,
        },
        preserveMissingProducts,
      );

      result.match({
        Some: (product) => {
          accumulator = [...accumulator, product];
        },
        PriceChanged: ({ product, message }) => {
          accumulator = [...accumulator, product];
          store.dispatch(addProductError({ type: 'priceChanged', error: message }));
        },
        PartialStock: ({ product, message }) => {
          accumulator = [...accumulator, product];
          store.dispatch(addProductError({ type: 'partialStock', error: message }));
        },
        OutOfStock: ({ product, message }) => {
          accumulator = [...accumulator, product];
          store.dispatch(addProductError({ type: 'outOfStock', error: message }));
        },
        NoOfferFound: ({ product, message }) => {
          accumulator = [...accumulator, product];
          store.dispatch(addProductError({ type: 'noOfferFound', error: message }));
        },
        NoProductFound: ({ product, message }) => {
          accumulator = [...accumulator, product];
          store.dispatch(addProductError({ type: 'noProductFound', error: message }));
        },
        Message: (message) => {
          store.dispatch(addProductError({ type: 'message', error: message }));
        },
        Error: (errorMessage) => {
          store.dispatch(addProductError({ type: 'error', error: errorMessage }));
        },
      });
    } catch (err: unknown) {
      productErrors.error.push(productMessage(item.parsedProduct, (err as unknown as { message: string }).message));
    }

    return accumulator;
  }, []);
  return {
    cartProducts,
    productErrors,
  };
};
