import { IShippingAddresses, ITown } from 'types';
import { selectPostalCode } from 'store/products';
import { sendMessage } from 'store/messages';
import {
  createShippingAddresses,
  getShippingAddresses,
  selectShippingAddresses,
  categorizeShippingItems,
  selectDeliveryShippingItems,
  selectShippingAddress,
  setShippingAddress,
  selectShippingAddressesError,
} from 'store/delivery';
import { selectIsGroup, selectSelectedCustomerObject } from 'store/customers';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { useToken } from 'cookies';
import { Text } from 'components/shared';
import DeliveryCardItem from '../DeliveryMethodsCard';
import HomeAddressesList from './components/HomeAddressesList';
import HomeAddressModal from './components/HomeAddressModal';
import { IHomeAddressFrom } from './components/HomeAddressForm/types';
import HomeAddressFrom from './components/HomeAddressForm';
import AddressWarningModal from './components/AddressWarningModal';
import styles from './ChangeAddressCart.module.scss';

const ChangeAddressCart: FC = () => {
  const selectedCustomerObject = useSelector(selectSelectedCustomerObject);
  const shippingAddress = useSelector(selectShippingAddress);
  const shippingAddresses = useSelector(selectShippingAddresses);
  const deliveryShippingItems = useSelector(selectDeliveryShippingItems);
  const selectedTown = useSelector(selectPostalCode);
  const isError = useSelector(selectShippingAddressesError);
  const isGroup = useSelector(selectIsGroup);
  const [isFormShown, setIsFormShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const dispatch = useDispatch();
  const { token } = useToken();
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const { t } = useTranslation();

  const onCloseModal = () => {
    if (!shippingAddress) {
      dispatch(sendMessage({ message: t('errors.noAddressProvided'), type: 'warning' }));
    } else {
      dispatch(categorizeShippingItems(deliveryShippingItems));
      setIsModalShown(false);
    }
  };

  const onSelectAddress = (address: IShippingAddresses) => {
    if (address.postcodeLabel !== selectedTown?.postcode) {
      setIsModalShown(false);
      setIsWarningModalVisible(true);
    } else {
      dispatch(setShippingAddress(address));
      dispatch(categorizeShippingItems(deliveryShippingItems));
      setIsModalShown(false);
    }
  };

  const updateShippingAddresses = async () => {
    if (token && selectedCustomerObject?.userId) {
      dispatch(getShippingAddresses({ userId: selectedCustomerObject.userId, token }));
    }
  };

  useEffect(() => {
    /**
     * Shipping address is being set in the Postal Code modal
     * so we need to check if the address is set
     * If there is no shipping address preset, show the modal
     * to create a new one
     *
     */
    if (!shippingAddress) {
      setIsModalShown(true);
    } else {
      dispatch(categorizeShippingItems(deliveryShippingItems));
    }
  }, [dispatch, shippingAddress]);

  const onSubmitForm = async (value: IHomeAddressFrom) => {
    const town: ITown = JSON.parse(value.town);
    if (token && selectedCustomerObject?.userId) {
      const addressParams = {
        userId: selectedCustomerObject.userId,
        gender: value.gender,
        firstName: value.firstName,
        lastName: value.lastName,
        street: value.street,
        phone1: value.phone1,
        phone2: value.phone2 || null,
        townId: town.id,
        postcodeLabel: town.postcode,
        townLabel: town.town,
        countryCode: value.countryCode,
        comment: value.comment || null,
        company: value.company || null,
      };
      dispatch(createShippingAddresses({ token, addressParams }));
      if (town.id !== selectedTown?.id) {
        dispatch(
          sendMessage({
            message: "La ville que vous avez indiquée dans l'adresse ne coïncide pas avec celle indiquée à l'étape 2",
            type: 'warning',
          }),
        );
      }
      if (!isError) {
        dispatch(sendMessage({ message: 'Adresse créée avec succès', type: 'success' }));
        setTimeout(updateShippingAddresses, 300);
      }
    }
    setIsFormShown(false);
  };

  const onOpenModal = () => {
    updateShippingAddresses();
    setIsModalShown(true);
  };

  return (
    <>
      <DeliveryCardItem
        title={isGroup ? t('modals.yourGroupDeliveryAddressTitle') : t('modals.yourDeliveryAddressTitle')}
        buttonText={t('modals.changeShippingAddress') as string}
        onClick={onOpenModal}
      >
        {shippingAddress && (
          <div className={styles.container}>
            <Text type="subtitle-gray8" className={styles.title}>
              {shippingAddress.firstName} {shippingAddress.lastName}
            </Text>
            <Text type="subtitle-gray1">
              {shippingAddress.street} {shippingAddress.postcodeLabel} {shippingAddress.townLabel}
            </Text>
            <Text type="subtitle-gray1">{shippingAddress.phone1 || shippingAddress.phone2}</Text>
          </div>
        )}
      </DeliveryCardItem>
      <HomeAddressModal
        closeModal={onCloseModal}
        onOpenForm={() => setIsFormShown(true)}
        isModalVisible={isModalShown}
        isFormVisible={isFormShown}
        isGroup={isGroup}
        formComponent={<HomeAddressFrom onSubmit={onSubmitForm} onCloseForm={() => setIsFormShown(false)} />}
        contentComponent={
          <HomeAddressesList selectedId={shippingAddress?.id} list={shippingAddresses} onSubmit={onSelectAddress} />
        }
      />
      <AddressWarningModal
        isModalVisible={isWarningModalVisible}
        handleCancel={() => setIsWarningModalVisible(false)}
        openAddressModal={() => {
          setIsWarningModalVisible(false);
          onOpenModal();
        }}
      />
    </>
  );
};

export default ChangeAddressCart;
