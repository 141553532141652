import { filterColumns } from 'utils/table.utils';
import { percentage } from 'utils/styles.utils';
import { IDeparture, IParsedProductItem, IParsedProductItemRowKey, IProductIdName } from 'store/products/types';
import {
  selectDepartures,
  selectIsLoading,
  selectOfferGroupBy,
  selectParsedProductsForTable,
  setWarehouseStockModalSku,
  setWarehouseStockModalVisibility,
} from 'store/products';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ProductDeliveryColumn,
  ProductInfoColumn,
  ProductNullColumn,
  ProductPriceColumn,
  ProductQuantityColumn,
  ProductReferenceColumn,
  ProductStockColumn,
  ProductWarehouseStockColumn,
} from 'components/product/ProductTable/ProductTableColumns';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from '../../shared';
import { DisplayOfferGroup } from '../../../types/offer';
import styles from './ProductTable.module.scss';

const ProductTable: FC = () => {
  const isLoading = useSelector(selectIsLoading);
  const products = useSelector(selectParsedProductsForTable());
  const offerGroupBy = useSelector(selectOfferGroupBy);
  const departures = useSelector(selectDepartures);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);

  const productsGroupBySku = useMemo(() => {
    const productIdName: Record<string, { name: string; sortIndex: number }> = {};
    products.forEach((product) => {
      const productName = product.name + (product.conditioning ? ' - ' + product.conditioning : '');
      productIdName[product.sku] = {
        name: productName,
        sortIndex: product.sortingIndex,
      };
    });
    return Object.entries(productIdName)
      .map(([key, value]) => ({
        id: Number(key),
        name: value.name,
        sortIndex: Number(value.sortIndex),
      }))
      .sort((a, b) => a.sortIndex - b.sortIndex);
  }, [products]);

  useEffect(() => {
    if (offerGroupBy === DisplayOfferGroup.SKU) {
      setExpanded(true);
      setExpandedKeys(productsGroupBySku.map((p) => p.id));
    }
  }, [offerGroupBy]);

  const showStockModal = (sku: string) => {
    dispatch(setWarehouseStockModalSku(sku));
    dispatch(setWarehouseStockModalVisibility(true));
  };

  const departuresLength = useMemo(() => {
    return Object.values(departures).length;
  }, [departures]);

  const departureList = useCallback(() => {
    const foundDepartures: Array<IDeparture> = [];
    const foundDeparturesId = products.reduce((acc, product) => {
      if (product.departureId && !acc.includes(product.departureId)) {
        acc.push(product.departureId);
      }
      return acc;
    }, [] as Array<number>);
    foundDeparturesId.forEach((id) => {
      const foundDeparture = Object.values(departures).find((departure) => departure.id === id);
      if (foundDeparture) {
        foundDepartures.push(foundDeparture);
      }
    });
    foundDepartures.push({
      id: -1,
      countryCode: '',
      name: t('common.undefined'),
    });
    return foundDepartures;
  }, [departures, products, t]);

  const handleToggleExpandAllRows = useCallback(() => {
    setExpanded((value) => !value);
    let expandKeys: number[] = [];
    if (offerGroupBy === DisplayOfferGroup.DEPARTURE) {
      expandKeys = departureList().map((departure) => departure.id);
    }
    if (offerGroupBy === DisplayOfferGroup.SKU) {
      expandKeys = Object.values(productsGroupBySku).map((product) => product.id);
    }
    setExpandedKeys(expanded ? [] : expandKeys);
  }, [expanded, products]);

  const handleToggleExpandRow = (isExpanded: boolean, key: number) => {
    setExpandedKeys((state) => {
      if (isExpanded) {
        return state.filter((item) => item !== key);
      } else {
        if (!state.includes(key)) {
          return [...state, key];
        }
      }
      return state;
    });
  };

  const getRowKey = useCallback(
    (record: IDeparture) => {
      return departureList().find((departure: IDeparture) => departure.id === record.id)?.id || -1;
    },
    [departureList],
  );
  const getRowKeyProduct = useCallback(
    (record) => {
      const groupedBySku = productsGroupBySku.find((productIdName) => {
        return productIdName.id === record.id;
      });

      return groupedBySku?.id || -1;
    },
    [productsGroupBySku],
  );

  const tableColumnsOffers = filterColumns<TableColumnDefinition<IParsedProductItem>>([
    ProductInfoColumn({ width: percentage(8, 20), title: t('common.productInfo') }),
    ProductReferenceColumn({ width: percentage(1, 20), title: t('common.code') }),
    ProductStockColumn({ width: percentage(1, 10), title: t('common.stock') }),
    ProductWarehouseStockColumn(showStockModal, { width: percentage(1, 20), title: t('common.stock') }),
    ProductDeliveryColumn({ width: percentage(2, 20), title: t('common.delivery') }),
    ProductPriceColumn({ width: percentage(1, 20), title: t('common.netPrice'), align: 'center' }),
    ProductQuantityColumn({ width: percentage(2, 20), title: t('common.quantity'), align: 'center' }),
  ]);

  const ProductNullColumnsAndExpand = [
    ProductNullColumn({
      title: t('common.code'),
      align: 'center',
      width: percentage(1, 20),
    }),
    ProductNullColumn({
      title: t('common.stock'),
      width: percentage(1, 20),
      align: 'center',
    }),
    ProductNullColumn({
      width: percentage(1, 20),
      title: t('common.stock'),
      align: 'center',
    }),
    ProductNullColumn({
      width: percentage(2, 20),
      title: t('common.delivery'),
      align: 'center',
    }),
    ProductNullColumn({
      width: percentage(1, 20),
      title: t('common.netPrice'),
      align: 'right',
    }),
    ProductNullColumn({
      width: percentage(2, 20),
      title: t('common.quantity'),
      align: 'right',
    }),
    Table.EXPAND_COLUMN,
  ];

  const tableColumnsDepartures = filterColumns<TableColumnDefinition<IDeparture> | typeof Table.EXPAND_COLUMN>([
    {
      title: t('common.productInfo'),
      dataIndex: undefined,
      width: percentage(10, 20),
      render: (record: IDeparture) => {
        const { name } = record;
        return name;
      },
    },
    ...ProductNullColumnsAndExpand,
  ]);
  const tableColumnsProductIdName = filterColumns<TableColumnDefinition<IProductIdName> | typeof Table.EXPAND_COLUMN>([
    {
      title: t('common.productInfo'),
      dataIndex: undefined,
      width: percentage(10, 20),
      render: ({ name }) => {
        return name;
      },
    },
    ...ProductNullColumnsAndExpand,
  ]);

  const columnTitleGroupByDeparture = useMemo(() => {
    const Icon = expanded ? <UpOutlined /> : <DownOutlined />;
    return (
      <>
        <Button className={styles.expand__button} onClick={handleToggleExpandAllRows} icon={Icon} />
      </>
    );
  }, []);

  const columnTitleGroupBySku = useMemo(() => {
    const Icon = expanded ? <UpOutlined /> : <DownOutlined />;
    return (
      <>
        <Button className={styles.expand__button} onClick={handleToggleExpandAllRows} icon={Icon} />
      </>
    );
  }, []);

  const expandedRowRenderer = useCallback(
    (record: IProductIdName | IParsedProductItemRowKey, _row: number) => {
      const filteredProducts: Array<IParsedProductItemRowKey> = [];
      if (offerGroupBy === DisplayOfferGroup.SKU && 'id' in record) {
        products.forEach((product) => {
          if (Number(product.sku) === record.id) {
            filteredProducts.push(product);
          }
        });
      }
      if (offerGroupBy === DisplayOfferGroup.DEPARTURE && 'id' in record) {
        products.forEach((product) => {
          if (record.id === -1 && (departuresLength === 0 || !product.departureId)) {
            filteredProducts.push(product);
          }
          if (product.departureId === record.id) {
            filteredProducts.push(product);
          }
        });
      }
      return (
        <Table
          rowKey={(product) => product.rowKey}
          showHeader={false}
          className={styles.container__child_table}
          rowClassName={styles.child_row}
          columns={tableColumnsOffers as ColumnsType<IParsedProductItemRowKey>}
          pagination={false}
          dataSource={filteredProducts}
        />
      );
    },
    [offerGroupBy, tableColumnsOffers, products, departuresLength],
  );

  const offerGroupByDefaultTable = useMemo(() => {
      return (
        <Table
          columns={tableColumnsOffers as Array<TableColumnDefinition<IParsedProductItemRowKey>>}
          size="small"
          className={styles.container__table}
          rowClassName={styles.row}
          rowKey={(product) => product.rowKey}
          loading={isLoading}
          pagination={false}
          dataSource={products}
        />
      );
    },
    [products, tableColumnsOffers],
  );

  const offerGroupByDepartureTable = (
    <Table
      columns={tableColumnsDepartures as ColumnsType<IDeparture>}
      size="small"
      className={styles.container__table}
      rowClassName={styles.row}
      rowKey={(departure: IDeparture) => departure.id}
      loading={isLoading}
      pagination={false}
      dataSource={departureList()}
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
        expandedRowKeys: expandedKeys,
        expandedRowClassName: () => styles.expandableRow,
        columnTitle: columnTitleGroupByDeparture,
        expandRowByClick: true,
        expandedRowRender: expandedRowRenderer,
        rowExpandable: () => true,
        // eslint-disable-next-line react/prop-types
        expandIcon: ({ expanded: isExpanded, onExpand: _onExpand, record }) => {
          const Icon = isExpanded ? <UpOutlined /> : <DownOutlined />;
          return (
            <Button
              className={styles.expand__button}
              onClick={() => handleToggleExpandRow(isExpanded, getRowKey(record))}
              icon={Icon}
            />
          );
        },
      }}
    />
  );

  const offerGroupBySkuTable = (
    <Table
      columns={tableColumnsProductIdName as ColumnsType<IProductIdName>}
      size="small"
      className={styles.container__table}
      rowClassName={styles.row}
      rowKey={({ id }) => id}
      loading={isLoading}
      pagination={false}
      dataSource={productsGroupBySku}
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
        expandedRowKeys: expandedKeys,
        expandedRowClassName: () => styles.expandableRow,
        columnTitle: columnTitleGroupBySku,
        expandRowByClick: true,
        expandedRowRender: expandedRowRenderer,
        rowExpandable: () => true,

        // eslint-disable-next-line react/prop-types
        expandIcon: ({ expanded: isExpanded, onExpand: _onExpand, record }) => {
          const Icon = isExpanded ? <UpOutlined /> : <DownOutlined />;
          return (
            <Button
              className={styles.expand__button}
              onClick={() => handleToggleExpandRow(isExpanded, getRowKeyProduct(record))}
              icon={Icon}
            />
          );
        },
      }}
    />
  );

  const renderedTable = useMemo(() => {
    switch (offerGroupBy) {
      case DisplayOfferGroup.DEPARTURE:
        return offerGroupByDepartureTable;
      case DisplayOfferGroup.SKU:
        return offerGroupBySkuTable;
      default:
        return offerGroupByDefaultTable;
    }
  }, [offerGroupBy, offerGroupByDefaultTable, offerGroupByDepartureTable, offerGroupBySkuTable]);

  return <div className={styles.container}>{renderedTable}</div>;
};

export default ProductTable;
