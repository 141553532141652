export const common_fr = {
  or: 'ou',
  requiredField: 'Ce champ est obligatoire!',
  noData: 'Pas de données',
  minimumFourCharacters: 'Please enter at least {{count}} characters',
  noFarmerSelected: 'Aucun client séléctionné',
  noFarmerSelected_perret: 'Aucun client séléctionné',
  noFarmerSelected_izi: 'Aucun agriculteur séléctionné',
  next: 'Suivant',
  type: 'Type',
  number: 'Numéro',
  expiryDate: "Date d'expiration",
  verified: 'Vérifié',
  uploaded: 'Téléchargé',
  refused: 'Refusé',
  group: 'Groupement',
  billing: 'Facturation',
  billingAddress: 'Adresse de facturation',
  delivery: 'Livraison',
  shippingAddress_one: 'Adresse de livraison',
  shippingAddress_other: 'Adresses de livraison',
  searchProducts: 'Recherche par produit',
  searchProductsCartRequest: 'Recherche par devis client',
  searchProductsMulti: 'Recherche par produit multiple',
  certificateTitle: 'Certificat Phytosanitaire',
  availableTags: 'Tags',
  customerComment: 'Commentaire :',
  addFarmer: 'Ajouter un Client',
  addFarmer_perret: 'Ajouter un Client',
  addFarmer_izi: 'Ajouter un Agriculteur',
  continue: 'Continuer',
  cancel: 'Annuler',
  farmer_one: 'Client',
  farmer_other: 'Clients',
  farmer_izi_one: 'Agriculteur',
  farmer_izi_other: 'Agriculteurs',
  multiBilling: 'Multi-Facturation',
  multiBilling_izi: 'Livraison groupée',
  multiBilling_ax: 'Livraison groupée',
  catalog: 'Catalogue',
  shipping: 'Livraison',
  validation: 'Validation',
  cart: 'Panier',
  returnToStep: 'Retour étape',
  saveCart: 'Sauvegarder le panier',
  productInfo: 'Produit',
  reference: 'Référence',
  stock: 'Stock',
  netPrice: 'Prix HT',
  quantity: 'Quantité',
  includedArticle: 'Article inclus',
  excludedArticle: 'Article exclu',
  initialNetPrice: 'Prix initial HT',
  forcedPrice: 'Prix forcé',
  forcedNetPrice: 'Prix HT forcé',
  buyingPrice: "Prix d'achat HT",
  netTotal: 'Total HT',
  totalPrice: 'Total TTC',
  rpdNotIncluded: 'RPD non incluse',
  rpdTax: 'Taxe RPD',
  totalRpdTax: 'Total RPD',
  cvoTax: 'Taxe CVO',
  totalRpd: 'Total RPD',
  totalCvo: 'Total CVO',
  ofWhichCvo: 'Dont CVO',
  vat: 'TVA',
  cgeProductLine: 'Gamme CGE',
  selectShippingMethods: 'Choisir les livraisons',
  emptyCartTitle: 'Souhaitez-vous vider le panier ?',
  confirm: 'Confirmer',
  shippingMethods: 'Modes de livraisons',
  comment: 'Commentaire',
  addCommentPlaceholder: 'Commentaire produit',
  globalCommentPlaceholder: 'Commentaire global',
  deliveryCommentPlaceholder: 'Commentaire de livraison',
  invoiceCommentPlaceholder: 'Commentaire de facturation',
  totalProductHt: 'Total produits HT',
  totalProductTtc: 'Total produits TTC',
  totalShippingCostWithoutVat: 'Total frais de port HT',
  chooseDeliveryMethod: 'Veuillez choisir une ou plusieurs méthodes de livraison',
  apply: 'Appliquer',
  summary: 'Récapitulatif',
  orderSummary: 'Accéder au récapitulatif',
  selectAnotherAddress: 'Sélectionnez une autre adresse',
  selectAnotherPostalCode: 'Sélectionnez un autre code postal',
  unitPrice: 'Prix unitaire',
  conditioning: 'Conditionnement',
  total: 'Total',
  volume: 'Volume',
  truck: 'Camion',
  validateOrder: 'Valider la commande',
  orderCompletedSuccessfully: 'Commande validée avec succès',
  uploadedFilesTitle: 'Fichiers chargés',
  filename: 'Nom du fichier',
  uploadDate: 'Date de chargement',
  lastModification: 'Dernière modification',
  fileImported: 'Votre fichier est importé',
  products: 'Produits',
  view: 'Visualiser',
  orderValidated: 'Commande validée',
  quoteValidated: 'Devis envoyé',
  createNewOrder: 'Effectuer une nouvelle commande',
  createNewQuote: 'Créer un nouveau devis',
  gotoNewFrontCart: 'Aller au panier front',
  gotoNewBOCart: 'Aller au panier Back Office',
  viewQuote: 'Voir le devis',
  online: 'Online',
  offline: 'Offline',
  closeWindow: 'Fermer la fenêtre',
  multiSearchImportButton: 'Importer un fichier',
  multiSearchText: 'Recherche par produit multiple',
  margin: 'Marge',
  notAvailable: 'Non disponible',
  discount: 'dont {{value}} {{type}} de remise',
  customerMode: 'Mode client',
  displayAllOffers: 'Voir toutes les offres',
  excludeNoStockOffers: 'Exclure les offres avec 0 de stock',
  discountApplied: 'Remise appliquée',
  lastPrice: 'Dernier prix',
  supplierPurchasePrice: "Prix d'achat fournisseur",
  supplierPurchasePriceSubtitle: 'Ces informations ne sont pas envoyées en Gestion Co.',
  commercialConditions: 'Conditions commerciales',
  supplierPriceQuantity: 'Quantité',
  supplierPrice: 'Prix',
  supplierPriceNote: "Notes et commentaires Prix d'achat fournisseur",
  supplierName: 'Fournisseur',
  supplierPriceSubmitButton: 'Ok',
  supplierPriceCancelButton: 'Annuler',
  supplierPriceSupplierRule: 'Le fournisseur est requis.',
  supplierPriceQuantityRule: 'La quantité est requise.',
  supplierPricePriceRule: 'Le prix est requis.',
  supplierPriceNone: '- none -',
  lastInvoicedPrice: 'Dernier prix facturé',
  lastQuotedPrice: 'Dernier prix proposé',
  tooltipCartRequestColumnHeader: 'N° de demande de devis',
  tooltipProductsOffered: 'Produit proposé au client',
  tooltipProductsRequested: 'Produit demandé par le client',
  supplierPricePriceValidationNumeric: "La valeur n'est pas numérique",
  supplierPricePriceValidationMin: 'La valuer doit être plus grande que {{value}}.',
  supplierPricePriceValidationMax: ' "La valeur doit être moins grande que le prix de vente ({{price}})."',
  invoiceNumber: 'Numéro de facture',
  purchaseOrderNumber: 'N° de devis',
  invoiceDate: 'Date de facturation',
  purchaseDate: 'Date du devis',
  inputPriceInvalid: 'Le prix forcé doit être supérieur au prix d’achat.',
  blockedPrice: 'Pour modifier le prix forcé, veuillez supprimer les remises.',
  sku: 'SKU',
  volumePerItem: 'Volume facturé par produit',
  volumePerItemPerCustomer: 'Volume facturé par client',
  groupByOffer: 'Afficher par offres',
  groupByDeparture: 'Afficher par départ',
  groupBySku: 'Afficher par produit',
  undefined: 'Non défini',
  underConstruction: 'Cette section est en cours de développement',
  emptyCustomerList: 'Rechercher un client par nom, prénom ou n°client à l’aide de la barre de recherche',
  searchTc: 'Rechercher un TC',
  emptyCustomerListNoSearchResults: 'Aucun résultat pour votre recherche',
  code: 'Code',
  customerServicePhone: 'Nº service client',
  contact: 'Contact',
  codeProduct: 'Code produit',
  sendCustomerQuote: 'Publier le devis au client',
  notifyCustomerQuestion: 'Notifier le client par email ?',
  selectDeliveryDate: 'Définir la période de livraison',
  selectDeliveryDateTo: 'à',
  infoUser: 'Infos Utilisateur',
  account: 'Compte',
  active: 'Activé',
  inactive: 'Inactif',
  lastConnection: 'Dernière connexion',
  email: 'Email',
  productName: 'Nom du produit',
  UP: 'P.U.',
  quatationSubtitle: 'Le devis a été envoyé au client sur son espace et vous a été transmis en format PDF par mail.',
  quatationWarning: 'Le client a besoin d’avoir un compte pour visualiser le devis sur la plateforme.',
  navMenuError: 'Unable to load the navigation menu.',
  copy: 'Copier',
  copied: 'Copié',
  offer: 'Offer',
  cartRequestExpirationDays: 'Jours',
  cartRequestExpirationHours: 'Heures',
  cartRequestExpirationQuote: 'Expiration devis',
  createdOn: 'Crée le',
  updatedAt: 'Mise à jour le',
  expiresOn: 'Expire le',
  expiredAt: 'Expiré le',
  convertedAt: 'Converti le',
  convertedStatus: 'Converti',
  refusedAt: 'Refusé le',
  refusedStatus: 'Refusé',
  unableToViewTheQuote: 'Unable to view the quote',
  warehouseStockName: 'Dépôt',
  hideOutOfStock: 'Cacher les ruptures de stock',
  rememberWarehouse: 'Sauvegarder le dépôt',
  actions: 'Actions',
  sentToCustomerAt: 'Envoyé au client le',
  payment: 'Paiement',
  userNotFound: 'Utilisateur non trouvé',
  certAuth: "Autorise l'achat de phyto",
  regulatedPrecursor: "Précurseur d'explosif réglementé",
  restrictedPrecursor: "Précurseur d'explosif restreint",
  otherInfo: 'Autres infos',
  noCertificateMessage: 'Vous ajoutez un produit soumis au certiphyto. Ce client n’a pas de certiphyto valide!',
  quantitySmallerThanMinimum:
    'La quantité que vous définissez est inférieure à la valeur minimale. La valeur minimale a été fixée.',
  quantityBigerThanMaximum:
    'La quantité que vous définissez est supérieure à la valeur maximale. La valeur maximale a été fixée.',
  noStockMessage: 'Stock insuffisant pour l’offre choisie',
  unit: 'Unité de vente',
  conditioningQuantity: 'Quantité conditionnement',
  supplierReference: 'Reference fournisseur',
  uploadsTooltip: 'Ajouter une pièce jointe (non transmise au client).',
  campaign: 'Campagne',
};
