import { getDeliveryDate, isPriceEditPreventedByOfferType } from 'utils';
import { useAppSelector } from 'store/store';
import {
  deleteProductCartAttachments,
  removeProduct,
  selectCartHasAttachments,
  selectIsSavedCart,
  updateProductField,
  updateProductReplacementName,
} from 'store/cart';
import { SortableHandle } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useMemo, useState } from 'react';
import { CartAttachment, ProductComment, ProductDiscount, ProductEdit, TagsList, Text } from 'components/shared';
import ADButton from 'antd/lib/button';
import { Tooltip, Space } from 'antd';
import {
  CheckCircleOutlined,
  CodeSandboxOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  BankOutlined,
} from '@ant-design/icons';
import FlagList from '../../../../shared/FlagList';
import { setWarehouseStockModalSku, setWarehouseStockModalVisibility } from '../../../../../store/products';
import { selectDeliveryShippingItems, setShippingItems } from '../../../../../store/delivery';
import { ICartRowHeaderProps } from './types';
import styles from './styles.module.scss';

const CartRowHeader: FC<ICartRowHeaderProps> = (props: ICartRowHeaderProps) => {
  const { product, productIndex } = props;
  const {
    sku,
    offerId,
    conditioning,
    name,
    shippingMethods,
    productOptions,
    label,
    flags,
    cartProductId,
    tags,
    productTags,
    metadata,
    offerType,
  } = product;

  const [productName, setProductName] = useState(name);
  const [productLabel, setProductLabel] = useState(label ?? name);
  const [replacementName, toggleReplacementName] = useState(false);
  const [editingLabel, toggleEditingLabel] = useState(false);
  const [editName, toggleName] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSavedCart = useSelector(selectIsSavedCart);
  const cartHasAttachments = useAppSelector(selectCartHasAttachments);
  let shippingItems = useSelector(selectDeliveryShippingItems);

  const onChangeProductReplacementName = (inputText: string, identifier: number) => {
    dispatch(
      updateProductReplacementName({
        identifier,
        replacementName: inputText,
      }),
    );
  };
  const changeProductName = () => {
    dispatch(
      updateProductField({
        field: 'name',
        identifier: productIndex,
        value: productName,
      }),
    );
  };
  const changeProductLabel = () => {
    dispatch(
      updateProductField({
        field: 'label',
        identifier: productIndex,
        value: productLabel,
      }),
    );
  };

  const showStockModal = () => {
    dispatch(setWarehouseStockModalSku(sku));
    dispatch(setWarehouseStockModalVisibility(true));
  };

  const handleRemoveProduct = async () => {
    dispatch(removeProduct(offerId));

    shippingItems = [];
    await dispatch(setShippingItems(shippingItems));

    if (!isSavedCart && cartHasAttachments) {
      await dispatch(deleteProductCartAttachments({ cartProductId }));
    }
  };

  const renderProductDiscount = useMemo(() => {
    if (isPriceEditPreventedByOfferType(offerType, flags)) {
      return null;
    }
    return <ProductDiscount productIndex={productIndex} />;
  }, [flags, offerType, productIndex]);

  const renderShippingMethods = useCallback(() => {
    if (!shippingMethods.length) {
      return <div>{t('cart.noShippingMethods')}</div>;
    }
    return (
      <div>
        {shippingMethods.map((item) => (
          <div key={item.id}>{item.label}</div>
        ))}
      </div>
    );
  }, [shippingMethods, t]);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <div className={styles.header__title}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (product.flags?.includes('locked-label')) {
                return;
              }
              toggleName(true);
            }}
          >
            {!product.flags?.includes('locked-label') && (
              <Tooltip title={t('common.tooltipProductsOffered')}>
                <span className={styles.productTitleInfo}>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            )}
            {!editName && (
              <span className={styles.productName}>
                {metadata?.business_note && <Tooltip overlay={metadata.business_note}>{productName}</Tooltip>}
                {!metadata?.business_note && productName}
                {!product.flags?.includes('locked-label') && <EditOutlined />}
              </span>
            )}
            {editName && (
              <>
                <input
                  size={48}
                  onChange={(e) => setProductName(e.target.value)}
                  onBlurCapture={(e) => {
                    changeProductName();
                    toggleName(false);
                    e.stopPropagation();
                  }}
                  onBlur={(e) => {
                    changeProductName();
                    toggleName(false);
                    e.stopPropagation();
                  }}
                  value={productName}
                />
                <CheckCircleOutlined
                  className={styles.actionButton}
                  onClick={(e) => {
                    changeProductName();
                    toggleName(false);
                    e.stopPropagation();
                  }}
                />
              </>
            )}
            {conditioning}{' '}
          </div>
          <div className={'option'}>
            <span>{productOptions}</span>
            {!!productOptions && ' / '}
            <Text className={styles.text}>
              {product.isPlainProduct && (
                <>
                  {t('common.code')}: {product.sku}
                </>
              )}
              {!product.isPlainProduct && (
                <>
                  {t('common.code')}: {product.sku} / {t('common.offer')}: {offerId}
                </>
              )}
              {product.amm && (
                <>
                  {' / '} AMM: {product.amm}
                </>
              )}
            </Text>
          </div>
          <div>
            {!product.replacement && (
              <div
                className={styles.subHeader}
                onClick={(e) => {
                  e.stopPropagation();
                  if (product.flags?.includes('locked-label')) {
                    return;
                  }
                  toggleEditingLabel(true);
                }}
              >
                {!product.flags?.includes('locked-label') && (
                  <Tooltip title={t('common.tooltipProductsRequested')}>
                    <span className={styles.productTitleInfo}>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                )}
                {!editingLabel && (
                  <span className={styles.productName}>
                    {productLabel}
                    {!product.flags?.includes('locked-label') && <EditOutlined />}
                  </span>
                )}
                {editingLabel && (
                  <>
                    <input
                      size={48}
                      onChange={(e) => setProductLabel(e.target.value)}
                      onBlurCapture={(e) => {
                        changeProductLabel();
                        toggleEditingLabel(false);
                        e.stopPropagation();
                      }}
                      onBlur={(e) => {
                        changeProductLabel();
                        toggleEditingLabel(false);
                        e.stopPropagation();
                      }}
                      value={productLabel}
                    />
                    <CheckCircleOutlined
                      className={styles.actionButton}
                      onClick={(e) => {
                        changeProductLabel();
                        toggleEditingLabel(false);
                        e.stopPropagation();
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {product.replacement && (
              <div
                className={styles.subHeader}
                onClick={(e) => {
                  toggleReplacementName(true);
                  e.stopPropagation();
                }}
              >
                {!replacementName && (
                  <span>
                    {product.replacement.name} <EditOutlined className={styles.actionButton} />
                  </span>
                )}
                {replacementName && (
                  <>
                    <input
                      size={32}
                      onChange={(e) => onChangeProductReplacementName(e.target.value, product.offerId)}
                      onBlurCapture={(e) => {
                        toggleReplacementName(false);
                        e.stopPropagation();
                      }}
                      onBlur={(e) => {
                        toggleReplacementName(false);
                        e.stopPropagation();
                      }}
                      value={product.replacement.name}
                    />
                    <CheckCircleOutlined
                      className={styles.actionButton}
                      onClick={(e) => {
                        toggleReplacementName(false);
                        e.stopPropagation();
                      }}
                    />
                  </>
                )}
                <TagsList tags={flags} />
              </div>
            )}
          </div>
        </div>

        <div className={styles.cartHeaderRightSide} onClick={(e) => e.stopPropagation()}>
          {product?.endDate && product?.startDate && !product.days && (
            <div className={styles.header__period}>
              {getDeliveryDate({
                endDate: product.endDate,
                startDate: product.startDate,
              })}
            </div>
          )}

          <div className={styles.cartHeaderRightSide__rightContainer}>
            <Space size="small" className={styles.cartHeaderRightSide__actions}>
              {renderProductDiscount}
              <Tooltip title={t('cart.warehouseStockTooltip')}>
                <ADButton onClick={showStockModal} icon={<BankOutlined />} type="text" shape="circle" />
              </Tooltip>
              <CartAttachment productIndex={productIndex} />
              <ProductComment productIndex={productIndex} />
              <ProductEdit productIndex={productIndex} />
              <div className={styles.header__delivery} onClick={(e) => e.stopPropagation()}>
                <Tooltip title={renderShippingMethods()}>
                  <CodeSandboxOutlined className={styles.icon} />
                </Tooltip>
              </div>
              <Tooltip title={t('cart.deleteTooltip')}>
                <ADButton
                  onClick={handleRemoveProduct}
                  icon={<DeleteOutlined />}
                  type="text"
                  color="red"
                  shape="circle"
                  danger
                />
              </Tooltip>
            </Space>
            <div className={styles.tagsContainer}>
              <TagsList tags={tags} />
              <FlagList flags={flags} />
              <FlagList productTags={productTags} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortableHandle(CartRowHeader);
